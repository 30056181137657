import { useState, useEffect, useContext } from "react";
import { ButtonGroup, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import {
  IoAddOutline,
  IoCheckmark,
  IoCloseOutline,
  IoPencilOutline,
  IoTrashBinOutline,
} from "react-icons/io5";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Table,
  Th,
  ThFixed,
  Td,
  TdFixed,
  SelectSearch,
  Input,
  DataStatus,
  ActionButton,
} from "components";
import { RupiahConvert } from "utilities";
import { AnalisaBarangJadiRABApi } from "api";

const TablePrelim = ({ dataKelompokPrelim, dataPrelim, setDataPrelim, dataPeluang }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataDropdown, setDataDropdown] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(""); //Menyimpan urutan data yang sedang di olah
  const [updateData, setUpdateData] = useState({}); //Menyimpan data yang sedang diubahh
  const ID_KELOMPOK_PRELIM = dataKelompokPrelim?.id_kelompok;
  const NAMA_KELOMPOK_PRELIM = dataKelompokPrelim?.nama_kelompok;
  const DATA_PRELIM_FILTER = dataPrelim?.filter((val) => val.id_kelompok === ID_KELOMPOK_PRELIM);

  // Konversi qty agar tidak dapat kurang dari 0 dan menghilangkan unique character
  const qtyConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, "");
    const convert = newValue.substring(0, 10);

    return convert;
  };

  // Mendapatkan subtotal pada tabel atau form
  const getSubTotal = (qty, harga) => {
    const newQty = qty ? parseFloat(qty) : 0;
    const newHarga = harga ? parseFloat(harga) : 0;

    return newQty * newHarga;
  };

  // Mendapatkan total pada tabel atau form
  const getTotal = (qtyItem, harga, qtyDurasi, konst) => {
    const newQtyItem = qtyItem ? parseFloat(qtyItem) : 0;
    const newHarga = harga ? parseFloat(harga) : 0;
    const newQtyDurasi = qtyDurasi ? parseFloat(qtyDurasi) : 0;
    const newKonts = konst ? parseFloat(konst) : 0;
    const subTotal = getSubTotal(newQtyItem, newHarga);

    return subTotal * newQtyDurasi * newKonts;
  };

  // Menangani tambah data pada tabel
  const createDataHandler = (data) => {
    const generateOrder = () => {
      const find = dataPrelim.find((val, index) => index === dataPrelim.length - 1);
      const lastOrder = find?.order ? find.order : 0;

      return parseInt(lastOrder) + parseInt(1);
    };

    setDataPrelim([
      ...dataPrelim,
      {
        ...data,
        order: generateOrder(),
      },
    ]);
  };

  // Menangani ubah data pada tabel
  const updateDataHandler = (data) => {
    const updateData = dataPrelim.map((val) => {
      if (val.order === selectedOrder) {
        return data;
      }

      return val;
    });

    setDataPrelim(updateData);
    setSelectedOrder("");
  };

  // Menangani hapus data pada tabel
  const deleteDataHandler = (deleted) => {
    const newData = dataPrelim.filter((val) => val.order !== deleted);

    setDataPrelim(newData);
    setSelectedOrder("");
  };

  // Mapping Data
  const mappingDataDropdown = (data) =>
    data.map((val) => {
      return {
        value: val.id_item_buaso_prelim ?? null,
        label: val.nama_item ? `${val.kode_item} - ${val.nama_item}` : "-",
        kode: val.kode_item ?? "-",
        nama: val.nama_item ?? "-",
        satuan: val.nama_satuan ?? "-",
        harga: val.harga_satuan_prelim ?? 0,
        id: val.id_item_buaso_prelim ?? null,
        id_satuan: val.id_satuan,
      };
    });

  // Fetch data pada saat halaman dibuka
  const getInitialData = () => {
    // setIsLoading(true);
    // AnalisaBarangJadiRABApi.getItemPrelim({ id_kelompok: ID_KELOMPOK_PRELIM })
    //   .then((res) => {
    //     const data = res.data.data;
    //     const mapData = mappingDataDropdown(data ?? []);
    //     setDataDropdown(mapData);
    //   })
    //   .catch(() => {
    //     window.alert(`Data dropdown item prelim ${NAMA_KELOMPOK_PRELIM} gagal dimuat!`);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  // Tampilah form pada saat tambah / ubah
  const FormTable = ({ type }) => {
    const formInitialValues = {
      id_kelompok: ID_KELOMPOK_PRELIM,
      id_item: type === "update" ? updateData?.id_item : null,
      kode_item: type === "update" ? updateData?.kode_item : null,
      nama_item: type === "update" ? updateData?.nama_item : null,
      qty_item: type === "update" ? updateData?.qty_item : 0,
      id_unit: type === "update" ? updateData?.id_unit : null,
      nama_unit: type === "update" ? updateData?.nama_unit : "-",
      harga: type === "update" ? updateData?.harga : 0,
      qty_durasi:
        type === "create" ? dataPeluang?.schedule_kebutuhan_proyek : updateData?.qty_durasi,
      konstanta: type === "update" ? updateData?.konstanta : 0,
      order: type === "update" ? updateData?.order : 0,
    };
    const formValidationSchema = Yup.object().shape({
      id_item: Yup.string().required(),
      qty_item: Yup.string().required(),
      harga: Yup.string().required(),
      qty_durasi: Yup.string().required(),
      konstanta: Yup.string().required(),
    });
    const formSubmitHandler = (values, { setSubmitting }) => {
      setTimeout(() => {
        type === "update" ? updateDataHandler(values) : createDataHandler(values);
        setSubmitting(false);
      }, 300);
    };

    return (
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({ values, errors, touched, setFieldValue, setValues, handleSubmit, isSubmitting }) => (
          <tr>
            <Td></Td>
            {/* Item */}
            <Td colSpan={2}>
              <SelectSearch
                noMargin
                placeholder="Pilih item prelim"
                option={dataDropdown}
                loading={isLoading}
                defaultValue={
                  values.id_item ? dataDropdown.find((fil) => fil.id === values.id_item) : ""
                }
                menuShouldBlockScroll={true}
                menuPosition="fixed"
                onChange={(val) => {
                  setValues({
                    ...values,
                    id_item: val.id,
                    id_unit: val.id_satuan,
                    kode_item: val.kode,
                    nama_item: val.nama,
                    harga: parseInt(val.harga),
                    nama_unit: val.satuan,
                  });
                }}
                error={Boolean(errors.id_item && touched.id_item)}
              />
            </Td>

            {/* Qty. item */}
            <Td>
              <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.qty_item}</Tooltip>}>
                <Input
                  noMargin
                  value={values.qty_item}
                  onChange={(e) => {
                    const value = e.target.value;
                    const newValue = qtyConvert(value);
                    setFieldValue("qty_item", newValue);
                  }}
                  error={Boolean(errors.qty_item && touched.qty_item)}
                />
              </OverlayTrigger>
            </Td>

            {/* Unit */}
            <Td>{values.nama_unit}</Td>

            {/* Unit Price */}
            <Td>
              <OverlayTrigger
                trigger="focus"
                overlay={<Tooltip>{RupiahConvert(String(values.harga)).detail}</Tooltip>}
              >
                <Input
                  noMargin
                  value={RupiahConvert(String(values.harga ?? 0)).detail}
                  onChange={(e) => {
                    const value = e.target.value;
                    const check = RupiahConvert(String(value)).default > 0 ? value : 0;
                    const newValue = RupiahConvert(String(check ?? value)).default;

                    setFieldValue("harga", newValue);
                  }}
                  error={Boolean(errors.harga && touched.harga)}
                />
              </OverlayTrigger>
            </Td>

            {/* Sub Total */}
            <Td textRight>
              {RupiahConvert(String(getSubTotal(values.qty_item, values.harga))).detail}
            </Td>

            {/* Qty. Durasi */}

            <Td>
              <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.qty_durasi}</Tooltip>}>
                <Input
                  noMargin
                  value={values.qty_durasi}
                  onChange={(e) => {
                    const value = e.target.value;
                    const newValue = qtyConvert(value);
                    setFieldValue("qty_durasi", newValue);
                  }}
                  error={Boolean(errors.qty_durasi && touched.qty_durasi)}
                />
              </OverlayTrigger>
            </Td>

            {/* Konstanta */}
            <Td>
              <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.konstanta}</Tooltip>}>
                <Input
                  noMargin
                  value={values.konstanta}
                  onChange={(e) => {
                    const value = e.target.value;
                    const newValue = qtyConvert(value);
                    setFieldValue("konstanta", newValue);
                  }}
                  error={Boolean(errors.konstanta && touched.konstanta)}
                />
              </OverlayTrigger>
            </Td>

            {/* Total */}
            <Td textRight>
              {
                RupiahConvert(
                  String(
                    getTotal(values.qty_item, values.harga, values.qty_durasi, values.konstanta)
                  )
                ).detail
              }
            </Td>
          </tr>
        )}
      </Formik>
    );
  };

  return (
    <div>
      <div className="p-1">
        <b>List Item Prelim Kelompok {NAMA_KELOMPOK_PRELIM}</b>
      </div>
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed>No</ThFixed>
            <Th noPadding width={80}>
              Kode Item Prelim
            </Th>
            <Th>Item Prelim</Th>
            <Th noPadding width={60}>
              Qty. Item
            </Th>
            <Th noPadding width={150}>
              Unit
            </Th>
            <Th noPadding width={150}>
              Unit Price
            </Th>
            <Th noPadding width={100}>
              Qty. Durasi (Hari)
            </Th>
            <Th noPadding width={150}>
              Sub Total
            </Th>

            {/* <Th noPadding width={60}>
              Konst.
            </Th> */}
            {/* <Th noPadding width={150}>
              Total
            </Th> */}
          </tr>
        </thead>
        <tbody>
          {/* Looping data tabel */}
          {DATA_PRELIM_FILTER && DATA_PRELIM_FILTER.length > 0 ? (
            DATA_PRELIM_FILTER.map((val, index) =>
              selectedOrder === val.order ? (
                <FormTable type="update" />
              ) : (
                <tr>
                  <Td textCenter>{index + 1}</Td>
                  <Td>{val.kode_item}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td textRight>{parseFloat(val.qty_item).toPrecision()}</Td>
                  <Td>{val.nama_unit}</Td>
                  <Td>{RupiahConvert(String(val.harga ? parseFloat(val.harga) : 0)).detail}</Td>
                  <Td textRight>{parseFloat(val.qty_durasi).toPrecision()}</Td>
                  <Td textRight>
                    {RupiahConvert(String(getSubTotal(val.qty_item, val.harga))).detail}
                  </Td>

                  {/* <Td textRight>{parseFloat(val.konstanta).toPrecision()}</Td> */}
                  {/* <Td textRight>
                    {
                      RupiahConvert(
                        String(getTotal(val.qty_item, val.harga, val.qty_durasi, val.konstanta))
                      ).detail
                    }
                  </Td> */}
                </tr>
              )
            )
          ) : (
            <tr>
              <Td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>
      <hr />
    </div>
  );
};

export default TablePrelim;
