import React, { useState, useEffect } from "react";
import { RupiahConvert } from "utilities";
import {
  Table,
  Th,
  Td
} from 'components'

const TableListSummary = ({ getGrandTotal, analisaVariable, dataAlatBantuOnSite,  dataLabourCostOnSite, dataAnalisaBOK}) => {
  const [dataSummary, setDataSummary] = useState([]);

  const total = dataSummary.reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

  const dataListSummary = () => {
    const data = getGrandTotal();

    const totalBahanBaku = data
      .filter(item => item.kode === "hw" || item.kode === "pw")
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

    const totalPenunjangProduksi = data
      .filter(item => item.kode === "fs" || item.kode === "lc" || item.kode === "mp" || item.kode === "bop")
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

    const totalFinishing = data
      .filter(item => item.kode === "fin")
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

    const totalAlatBantu = () => {
      const getQty = dataAlatBantuOnSite[0]?.qty ? parseFloat(dataAlatBantuOnSite[0]?.qty) : 0
      const getKonst = dataAlatBantuOnSite[0]?.konstanta ? parseFloat(dataAlatBantuOnSite[0]?.konstanta) : 0
      const getPrice = dataAlatBantuOnSite[0]?.unit_price ? parseFloat(dataAlatBantuOnSite[0]?.unit_price) : 0
  
      return parseInt(getQty * getKonst * getPrice)
    }

    const totalLabourCost = () => {
      const subTotal = dataLabourCostOnSite.reduce((prev, current) => {
        const checkQty = current.qty ? parseFloat(current.qty) : 0
        const checkPrice = current.unit_price ? parseFloat(current.unit_price) : 0
        const checkKonst = current.konstanta ? parseFloat(current.konstanta) : 0
        const total = parseInt(checkQty * checkPrice * checkKonst)
  
        return prev + total
      }, 0)
  
      return subTotal
    }

    const totalBOK = () => {
      const getQty = dataAnalisaBOK[0]?.qty ? parseFloat(dataAnalisaBOK[0]?.qty) : 0
      const getKonst = dataAnalisaBOK[0]?.konstanta ? parseFloat(dataAnalisaBOK[0]?.konstanta) : 0
      const getPrice = dataAnalisaBOK[0]?.unit_price ? parseFloat(dataAnalisaBOK[0]?.unit_price) : 0
  
      return parseInt(getQty * getKonst * getPrice)
    }

    setDataSummary([
      { nama_biaya: 'Analisa Bahan Baku', subtotal: totalBahanBaku },
      { nama_biaya: 'Analisa Penunjang Produksi', subtotal: totalPenunjangProduksi },
      { nama_biaya: 'Analisa Finishing', subtotal: totalFinishing },
      { nama_biaya: 'Analisa Alat Bantu On-Site', subtotal: totalAlatBantu() },
      { nama_biaya: 'Analisa Labour Cost On-Site', subtotal: totalLabourCost() },
      { nama_biaya: 'Analisa Biaya Overhead Kantor', subtotal: totalBOK() },
    ])
  }

  useEffect(() => {
    dataListSummary()

  }, [analisaVariable, dataAlatBantuOnSite, dataAnalisaBOK, getGrandTotal]);

  return (
    <div className="table-responsive">
      <Table>
        <thead className="text-center bg-light">
          <tr>
            <Th>No.</Th>
            <Th>Nama Biaya</Th>
            <Th width={300}> Total Price (Rp)</Th>
          </tr>
        </thead>
        <tbody>
          {dataSummary?.map((val, index) => (
            <tr key={index}>
              <Td textCenter width={20}>{index + 1}</Td>
              <Td>{val.nama_biaya}</Td>
              <Td textRight>{RupiahConvert(String(parseInt(val.subtotal) ?? 0)).detail}</Td>
            </tr>
          ))
          }
          <tr className='bg-light'>
            <Td colSpan={2} className="p-2" textRight><b style={{fontSize: 14}}>Grand Total</b></Td>
            <Td textRight className="p-2"><b style={{fontSize: 14}}>{RupiahConvert(String(parseInt(total) ?? 0)).detail}</b></Td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TableListSummary;
