import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Card, Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Input,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  DeleteButton,
  CreateCustomerButton,
  Alert,
  SelectSearch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  BackButton,
  TextArea,
  CreateModalLarge,
} from "../../../components";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { RegisterSalesOrderApi, TransaksiSalesOrderApi } from "../../../api";
import { RupiahConvert } from "../../../utilities";

const CreateSalesOrder = ({ setNavbarTitle }) => {
  //  USE HISTORY
  const history = useHistory();

  // STATE LOADING
  // const [isLoading, setIsLoading] = useState(true);

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // STATE MODAL TAMBAH CUSTOMER
  const [isSearchingItemModal, setIsSearchingItemModal] = useState(false); // STATE MODAL PENCARIAN ITEM BARANG

  // STATE UNTUK MENYIMPAN ID ITEM BUASO YANG AKAN DICARI STOK BARANGNYA
  const [idItemBuaso, setIdItemBuaso] = useState();
  // STATE UNTUK MENYIMPAN ITEM BARANG YANG DI ORDER
  const [orderItems, setOrderItems] = useState([]);

  // STATE MENAMPUNG DATA CUSTOMER
  const [dataCustomer, setDataCustomer] = useState([]);
  // STATE MENAMPUNG DATA KARYAWAN / SALES
  const [dataKaryawan, setDataKaryawan] = useState([]);
  // STATE MENAMPUNG NO SALES ORDER
  const [noSalesOrder, setNoSalesOrder] = useState("");
  // MENYIMPAN DATA DROPDOWN ITEM BARANG
  const [dataItemBarang, setDataItemBarang] = useState([]);
  const [dataProyek, setDataProyek] = useState([]);
  const [kelompok, setKelompok] = useState([]);

  // MENYIMPAN DATA DETAIL CUSTOMER
  const [customerDetail, setCustomerDetail] = useState("");
  // MENYIMPAN DATA INPUT DISKON
  const [inputDiskon, setDiskon] = useState(0);
  // MENYIMPAN DATA INPUT PPN
  const [inputPpn, setPpn] = useState(0);
  // MENYIMPAN DETAIL DATA ORDER
  const [totalAkhir, setTotalAkhir] = useState({
    total: 0,
    jmlDiskon: 0,
    jmlPpn: 0,
    totalSetelahDiskon: 0,
    totalSetelahPpn: 0,
  });

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const [selectItemConfig, setSelectItemConfig] = useState({ isDisabled: true, isLoading: false });

  // GENERATE CUSTOMER DATA DETAIL
  const getCustomerDetail = (id_customer) => {
    if (id_customer) {
      const detail = dataCustomer.find((e) => e.id_customer === id_customer);
      const text = `Nama : ${detail.nama_customer},\nTelepon : ${detail.telepon},\nEmail : ${detail.email}`;
      setCustomerDetail(text);
    }
  };

  // GET CUSTOMER ALAMAT
  const getCustomerAlamat = (id_customer) => {
    if (id_customer) {
      const detail = dataCustomer.find((e) => e.id_customer === id_customer);
      return detail?.alamat ?? "";
    }
  };

  // FUNCTION GET DATA LIST CUSTOMER
  const getDataCustomers = () => {
    RegisterSalesOrderApi.getCustomer()
      .then((customers) => {
        setDataCustomer(customers?.data?.data ?? []);
      })
      .catch((err) => alert(err?.response?.data?.message ?? "Tidak dapat memuat data customer"));
  };

  // FUNCTION GET DATA NO SALES ORDER
  const getNoSales = (date = new Date().toISOString().split("T")[0].split("-").join("/")) => {
    TransaksiSalesOrderApi.getNomorSalesOrder(date)
      .then((no_sales) => {
        setNoSalesOrder(no_sales?.data?.data ?? null);
      })
      .catch((err) => alert(err.response.data.data));
  };

  const getDataItem = (id) => {
    setSelectItemConfig({ ...selectItemConfig, isLoading: true });
    RegisterSalesOrderApi.getItemBarang({ id_kelompok: id })
      .then((barang) => {
        setDataItemBarang(barang.data.data);
      })
      .catch(() => alert("Item Barang Gagal Dimuat !!"))
      .finally(() => {
        setSelectItemConfig({ isDisabled: false, isLoading: false });
      });
  };

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    getNoSales(); // GET NOMOR SALES ORDER
    getDataCustomers(); // GET DATA CUSTOMERS LIST

    Axios.all([
      RegisterSalesOrderApi.getSales(),
      RegisterSalesOrderApi.getKelompok(),
      RegisterSalesOrderApi.getProyek(),
    ])
      .then(
        Axios.spread((sales, kelompok, pry) => {
          setDataKaryawan(sales.data.data);
          setKelompok(kelompok.data.data);
          setDataProyek(pry.data.data);
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        // setIsLoading(false)
      });
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle("Sales Order"); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
  }, []);

  // HITUNG TOTAL KETIKA UPDATE STATE ORDER, DISKON, PPN
  useEffect(() => {
    hitungTotal();
  }, [orderItems, inputDiskon, inputPpn]);

  // MODAL TAMBAH COMPONENT

  // const TambahCustomerModal = () => {
  //   const [dataKabupaten, setDataKabupaten] = useState([]);
  //   const [dataKecamatan, setDataKecamatan] = useState([]);
  //   const [dataDesa, setDataDesa] = useState([]);
  //   const [jenisCustomer, setJenisCustomer] = useState([]);
  //   const [dataProvinsi, setDataProvinsi] = useState([]);
  //   const [dataBank, setDataBank] = useState([]);
  //   const [isLoading, setIsLoading] = useState({
  //     kabupaten: false,
  //     kecamatan: false,
  //     desa: false,
  //   });

  //   // FUNCTION GET DATA LIST BANK
  //   const getDataBank = () => {
  //     RegisterSalesOrderApi.getBank()
  //       .then((bank) => {
  //         setDataBank(bank.data.data);
  //       })
  //       .catch((err) => alert(err.response.data.message));
  //   };

  //   // FUNCTION GET DATA LIST PROVINSI
  //   const getDataProvinsi = () => {
  //     console.log("getData");
  //     RegisterSalesOrderApi.getProvinsi()
  //       .then((provinsi) => {
  //         setDataProvinsi(provinsi.data.data);
  //       })
  //       .catch((err) => alert(err.response.data.message));
  //   };

  //   // FUNCTION GET DATA LIST CUSTOMER
  //   const getDataJenisCustomer = () => {
  //     RegisterSalesOrderApi.getJenisCustomer()
  //       .then((jenisCustomer) => {
  //         setJenisCustomer(jenisCustomer.data.data);
  //       })
  //       .catch((err) => alert(err));
  //   };

  //   const getDataKabupaten = (id_provinsi) => {
  //     setIsLoading({ kabupaten: true, kecamatan: true, desa: true });
  //     RegisterSalesOrderApi.getKabupaten(id_provinsi)
  //       .then((kabupaten) => setDataKabupaten(kabupaten.data.data))
  //       .catch((err) => alert(err.response.data.message))
  //       .finally(() => setIsLoading({ kabupaten: false, kecamatan: false, desa: false }));
  //   };

  //   const getDataKecamatan = (id_kabupaten) => {
  //     setIsLoading((prevState) => ({ ...prevState, kecamatan: true, desa: true }));
  //     RegisterSalesOrderApi.getKecamatan(id_kabupaten)
  //       .then((kecamatan) => setDataKecamatan(kecamatan.data.data))
  //       .catch((err) => alert(err.response.data.message))
  //       .finally(() =>
  //         setIsLoading((prevState) => ({ ...prevState, kecamatan: false, desa: false }))
  //       );
  //   };

  //   const getDataDesa = (id_kecamatan) => {
  //     setIsLoading((prevState) => ({ ...prevState, desa: true }));
  //     RegisterSalesOrderApi.getDesa(id_kecamatan)
  //       .then((desa) => setDataDesa(desa.data.data))
  //       .catch((err) => alert(err.response.data.message))
  //       .finally(() => setIsLoading((prevState) => ({ ...prevState, desa: false })));
  //   };

  //   // GET JENIS CUSTOMER ON LOAD
  //   useEffect(() => {
  //     getDataJenisCustomer();
  //   }, []);

  //   // FORMIK CUSTOMER INITIAL VALUES
  //   const customerFormInitialValues = {
  //     nama_customer: "",
  //     alamat: "",
  //     keterangan: "",
  //     telepon: "",
  //     email: "",
  //     media_sosial: "",
  //     id_jenis_customer: "",

  //     no_rekening: "",
  //     kode_bank: "",
  //     atas_nama: "",

  //     id_provinsi: "",
  //     id_kabupaten: "",
  //     id_kecamatan: "",
  //     id_desa: "",
  //     kode_pos: "",
  //     id_proyel: "",
  //   };

  //   const customerValidationSchema = Yup.object().shape({
  //     nama_customer: Yup.string().required("Masukkan Nama Customer"),
  //     alamat: Yup.string().required("Masukkan Alamat Customer"),
  //     telepon: Yup.number()
  //       .required("Masukkan Nomor Telepon Customer")
  //       .typeError("Nomor telepon tidak valid"),
  //     email: Yup.string().email("Alamat email tidak valid").required("Masukkan Alamat E-Mail"),
  //     id_jenis_customer: Yup.string().required("Pilih Jenis Customer"),

  //     no_rekening: Yup.number()
  //       .required("Masukkan Nomor Rekening")
  //       .typeError("Nomor rekening tidak valid"),
  //     kode_bank: Yup.string().required("Pilih Bank"),
  //     atas_nama: Yup.string()
  //       .required("Masukkan Atas Nama nomor rekening")
  //       .typeError("Atas Nama tidak valid"),

  //     id_provinsi: Yup.string().required("Pilih Provinsi"),
  //     id_kecamatan: Yup.string().required("Pilih Kecamatan"),
  //     id_kabupaten: Yup.string().required("Pilih Kabupaten"),
  //     id_desa: Yup.string().required("Pilih Desa"),
  //     kode_pos: Yup.number().required("Masukkan Kode Pos").typeError("Kode pos tidak valid"),
  //     id_proyek: Yup.string().required("Pilih Proyek"),
  //   });

  //   // KIRIM DATA BARU KE SERVER
  //   const formSubmitHandler = (values) => {
  //     RegisterSalesOrderApi.createCustomer(values)
  //       .then((res) => {
  //         // SUCCESS ALERT
  //         setAlertConfig({
  //           variant: "primary",
  //           text: "Tambah data berhasil!",
  //         });
  //       })
  //       .catch((err) => {
  //         // ERROR ALERT
  //         setAlertConfig({
  //           variant: "danger",
  //           text: `Tambah data gagal! (${err.response.data.message})`,
  //         });
  //       })
  //       .finally(() => {
  //         // CLOSE MODAL
  //         setIsCreateForm(false);
  //         // TAMPILKAN ALERT
  //         setShowAlert(true);
  //         // FETCH DATA DARI SERVER
  //         // getDataCustomers();
  //       });
  //   };

  //   return (
  //     <CreateModal
  //       size="xl"
  //       show={isCreateForm}
  //       onHide={() => setIsCreateForm(false)}
  //       title="Customer"
  //     >
  //       <Formik
  //         initialValues={customerFormInitialValues}
  //         validationSchema={customerValidationSchema}
  //         onSubmit={formSubmitHandler}
  //       >
  //         {({
  //           values,
  //           errors,
  //           touched,
  //           isSubmitting,
  //           handleChange,
  //           handleSubmit,
  //           setFieldValue,
  //         }) => (
  //           <form onSubmit={handleSubmit}>
  //             <Modal.Body>
  //               <div className="row">
  //                 <div className="col-md-4 col-sm-12">
  //                   <Input
  //                     label="Nama Customer"
  //                     type="text"
  //                     name="nama_customer"
  //                     placeholder="Nama Customer"
  //                     value={values.nama_customer}
  //                     onChange={handleChange}
  //                     error={errors.nama_customer && touched.nama_customer && true}
  //                     errorText={errors.nama_customer}
  //                   />

  //                   <SelectSearch
  //                     label="Jenis Customer"
  //                     name="id_jenis_customer"
  //                     placeholder="Pilih Jenis Customer"
  //                     onChange={(val) => setFieldValue("id_jenis_customer", val.value)}
  //                     option={jenisCustomer.map((val) => {
  //                       return {
  //                         value: val.id_jenis_customer,
  //                         label: val.nama_jenis_customer,
  //                       };
  //                     })} //option harus berupa value dan label
  //                     defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
  //                     error={errors.id_jenis_customer && touched.id_jenis_customer && true}
  //                     errorText={
  //                       errors.id_jenis_customer &&
  //                       touched.id_jenis_customer &&
  //                       errors.id_jenis_customer
  //                     }
  //                   />

  //                   <TextArea
  //                     label="Keterangan"
  //                     placeholder="Keterangan"
  //                     name="keterangan"
  //                     value={values.keterangan}
  //                     onChange={handleChange}
  //                     error={errors.keterangan && touched.keterangan && true}
  //                     errorText={errors.keterangan}
  //                     rows={1}
  //                   />

  //                   <TextArea
  //                     label="Media Sosial"
  //                     placeholder="Facebook / Twitter"
  //                     name="media_sosial"
  //                     value={values.media_sosial}
  //                     onChange={handleChange}
  //                     error={errors.media_sosial && touched.media_sosial && true}
  //                     errorText={errors.media_sosial}
  //                     rows={1}
  //                   />
  //                 </div>

  //                 <div className="col-md-4 col-sm-12">
  //                   <Input
  //                     label="Telepon"
  //                     type="number"
  //                     name="telepon"
  //                     placeholder="Nomor Telepon Customer"
  //                     value={values.telepon}
  //                     onChange={handleChange}
  //                     error={errors.telepon && touched.telepon && true}
  //                     errorText={errors.telepon}
  //                   />

  //                   <Input
  //                     label="Email"
  //                     type="text"
  //                     name="email"
  //                     placeholder="Email"
  //                     value={values.email}
  //                     onChange={handleChange}
  //                     error={errors.email && touched.email && true}
  //                     errorText={errors.email}
  //                   />

  //                   <Input
  //                     label="Nomor Rekening"
  //                     type="number"
  //                     name="no_rekening"
  //                     placeholder="Nomor Rekening"
  //                     value={values.no_rekening}
  //                     onChange={handleChange}
  //                     error={errors.no_rekening && touched.no_rekening && true}
  //                     errorText={errors.no_rekening}
  //                   />
  //                   <SelectSearch
  //                     label="Bank"
  //                     name="kode_bank"
  //                     placeholder="Pilih Bank"
  //                     onFocus={getDataBank}
  //                     onChange={(val) => setFieldValue("kode_bank", val.value)}
  //                     option={dataBank.map((val) => {
  //                       return {
  //                         value: val.kode_bank,
  //                         label: val.nama_bank,
  //                       };
  //                     })} //option harus berupa value dan label
  //                     defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
  //                     error={errors.kode_bank && touched.kode_bank && true}
  //                     errorText={errors.kode_bank && touched.kode_bank && errors.kode_bank}
  //                   />
  //                   <Input
  //                     label="Atas Nama"
  //                     type="text"
  //                     name="atas_nama"
  //                     placeholder="Atas Nama"
  //                     value={values.atas_nama}
  //                     onChange={handleChange}
  //                     error={errors.atas_nama && touched.atas_nama && true}
  //                     errorText={errors.atas_nama}
  //                   />
  //                 </div>

  //                 <div className="col-md-4 col-sm-12">
  //                   <Input
  //                     label="Alamat Customer"
  //                     type="text"
  //                     name="alamat"
  //                     placeholder="Alamat Customer"
  //                     value={values.alamat}
  //                     onChange={handleChange}
  //                     error={errors.alamat && touched.alamat && true}
  //                     errorText={errors.alamat}
  //                   />

  //                   <SelectSearch
  //                     label="Provinsi"
  //                     name="id_provinsi"
  //                     placeholder="Pilih Provinsi"
  //                     onChange={(val) => {
  //                       setFieldValue("id_provinsi", val.value);
  //                       setFieldValue("id_kabupaten", "");
  //                       setFieldValue("id_kecamatan", "");
  //                       setFieldValue("id_desa", "");
  //                       getDataKabupaten(val.value);
  //                     }}
  //                     onFocus={getDataProvinsi}
  //                     option={dataProvinsi.map((val) => {
  //                       return {
  //                         value: val.id_provinsi,
  //                         label: val.nama_provinsi,
  //                       };
  //                     })} //option harus berupa value dan label
  //                     defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
  //                     error={errors.id_provinsi && touched.id_provinsi && true}
  //                     errorText={errors.id_provinsi && touched.id_provinsi && errors.id_provinsi}
  //                   />

  //                   <SelectSearch
  //                     label="Kabupaten"
  //                     name="id_kabupaten"
  //                     placeholder="Pilih Kabupaten"
  //                     loading={isLoading.kabupaten}
  //                     isDisabled={dataKabupaten.length <= 0}
  //                     onChange={(val) => {
  //                       setFieldValue("id_kabupaten", val.value);
  //                       setFieldValue("id_kecamatan", "");
  //                       setFieldValue("id_desa", "");
  //                       getDataKecamatan(val.value);
  //                     }}
  //                     option={dataKabupaten.map((val) => {
  //                       return {
  //                         value: val.id_kabupaten,
  //                         label: val.nama_kabupaten,
  //                       };
  //                     })} //option harus berupa value dan label
  //                     defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
  //                     error={errors.id_kabupaten && touched.id_kabupaten && true}
  //                     errorText={errors.id_kabupaten && touched.id_kabupaten && errors.id_kabupaten}
  //                   />

  //                   <SelectSearch
  //                     label="Kecamatan"
  //                     name="id_kecamatan"
  //                     placeholder="Pilih Kecamatan"
  //                     loading={isLoading.kecamatan}
  //                     isDisabled={dataKecamatan.length <= 0}
  //                     onChange={(val) => {
  //                       setFieldValue("id_kecamatan", val.value);
  //                       setFieldValue("id_desa", "");
  //                       getDataDesa(val.value);
  //                     }}
  //                     option={dataKecamatan.map((val) => {
  //                       return {
  //                         value: val.id_kecamatan,
  //                         label: val.nama_kecamatan,
  //                       };
  //                     })} //option harus berupa value dan label
  //                     defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
  //                     error={errors.id_kecamatan && touched.id_kecamatan && true}
  //                     errorText={errors.id_kecamatan && touched.id_kecamatan && errors.id_kecamatan}
  //                   />

  //                   <SelectSearch
  //                     label="Desa"
  //                     name="id_desa"
  //                     placeholder="Pilih Desa"
  //                     loading={isLoading.desa}
  //                     isDisabled={dataDesa.length <= 0}
  //                     onChange={(val) => setFieldValue("id_desa", val.value)}
  //                     option={dataDesa.map((val) => {
  //                       return {
  //                         value: val.id_desa,
  //                         label: val.nama_desa,
  //                       };
  //                     })} //option harus berupa value dan label
  //                     defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
  //                     error={errors.id_desa && touched.id_desa && true}
  //                     errorText={errors.id_desa && touched.id_desa && errors.id_desa}
  //                   />

  //                   <Input
  //                     label="Kode Pos"
  //                     type="phone"
  //                     name="kode_pos"
  //                     placeholder="Kode Pos"
  //                     value={values.kode_pos}
  //                     onChange={handleChange}
  //                     error={errors.kode_pos && touched.kode_pos && true}
  //                     errorText={errors.kode_pos}
  //                   />
  //                 </div>
  //               </div>
  //             </Modal.Body>

  //             <Modal.Footer>
  //               <div className="d-flex justify-content-end">
  //                 <ActionButton
  //                   type="submit"
  //                   variant="primary"
  //                   text="Tambah"
  //                   className="mt-2 px-4"
  //                   loading={isSubmitting}
  //                 />
  //               </div>
  //             </Modal.Footer>
  //           </form>
  //         )}
  //       </Formik>
  //     </CreateModal>
  //   );
  // };

  // MODAL MENAMPILKAN STOK BARANG DARI ITEM YANG DIPILIH
  const SearchItemsModal = () => {
    // LOADING STATE KETIKA FETCH DATA ITEM YANG DI SEARCH
    const [isLoadingSearchData, setIsLoadingSearchData] = useState(true);

    // MENYIMPAN HASIL FETCH DATA ITEM YANG DIPILIH
    const [searchItems, setSearchItems] = useState([]);

    // FUNCTION UNTUK MENCARI SELURUH DATA STOK BERDASARKAN ID ITEM BUASO
    const searchSelectedItem = () => {
      RegisterSalesOrderApi.getStokItemBarang(idItemBuaso)
        .then((res) => {
          setSearchItems(res.data.data);
        })
        .finally(() => setIsLoadingSearchData(false));
    };

    useEffect(() => {
      setIsLoadingSearchData(true);
      searchSelectedItem();
    }, [idItemBuaso]);

    // KOMPONEN LIST TABEL ITEM BARANG YANG DICARI
    const TableSearchedItems = () => {
      return (
        <>
          <Modal.Body>
            <CRUDLayout.Table>
              <THead>
                <Tr>
                  <ThFixed>No</ThFixed>
                  <ThFixed>Kode Item</ThFixed>
                  <Th>Nama Item</Th>
                  <Th>Gudang</Th>
                  <Th>Stok</Th>
                  <Th>Satuan</Th>
                  <Th>Harga Satuan</Th>
                  <ThFixed>Quantity Order</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {searchItems?.map((val, index) => {
                  return (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.kode_barang}</TdFixed>
                      <Td>{val.nama_barang}</Td>
                      <Td>{val.nama_gudang}</Td>
                      <Td textRight>{val.stok}</Td>
                      <Td>{val.nama_satuan}</Td>
                      <Td textRight>
                        {val.harga_terbaru
                          ? RupiahConvert(parseInt(val.harga_terbaru).toString()).detail
                          : "Rp. 0"}
                      </Td>
                      <Td>
                        <input
                          className="form-control"
                          style={{ minWidth: "8rem" }}
                          placeholder="0"
                          type="number"
                          name={`jml_${index}`}
                          max={val.stok}
                          min={0}
                          onChange={(e) => {
                            if (e.target.value > 0 && e.target.value <= parseInt(val.stok)) {
                              searchItems[index].qty = e.target.value;
                              searchItems[index].sub_total = String(
                                val.harga_terbaru * e.target.value
                              );
                            } else if (e.target.value > parseInt(val.stok)) {
                              e.target.value = parseInt(val.stok);
                              searchItems[index].qty = val.stok;
                              searchItems[index].sub_total = String(
                                val.harga_terbaru * e.target.value
                              );
                            } else {
                              searchItems[index].qty = 0;
                            }
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </TBody>
            </CRUDLayout.Table>
          </Modal.Body>
          <Modal.Footer>
            <CreateButton
              onClick={() => {
                const order = searchItems.filter((e) => e.hasOwnProperty("qty") && e.qty > 0);
                let temp = [...orderItems];

                // FUNCTION CEK JIKA ITEM ORDER BARU ADA PADA ORDER ITEM YANG LAMA
                const upsert = (obj) => {
                  temp.forEach((or, orIndex) => {
                    if (or.id_barang === obj.id_barang && or.id_gudang === obj.id_gudang) {
                      // HAPUS ITEM ORDER YANG LAMA
                      temp.splice(orIndex, 1);
                    }
                  });
                  setOrderItems([...temp]);
                };
                order.forEach((obj) => upsert(obj));
                setOrderItems([...temp, ...order]);
                setIsSearchingItemModal(false);
              }}
            />
          </Modal.Footer>
        </>
      );
    };

    return (
      <CreateModalLarge
        title="Pencarian Item Barang"
        show={isSearchingItemModal}
        onHide={() => setIsSearchingItemModal(false)}
      >
        {isLoadingSearchData ? (
          <DataStatus loading={isLoadingSearchData} text="Memuat Data" />
        ) : !Array.isArray(searchItems) ? (
          <DataStatus text="Tidak dapat memuat data" />
        ) : searchItems.length > 0 ? (
          <TableSearchedItems />
        ) : (
          <DataStatus text="Data stok barang tidak ditemukan" />
        )}
      </CreateModalLarge>
    );
  };

  const hitungTotal = () => {
    let total = 0;
    let jmlDiskon = 0;
    let jmlPpn = 0;
    let totalSetelahDiskon = 0;
    let totalSetelahPpn = 0;

    if (orderItems.length > 0) {
      total = orderItems.reduce((sum, { sub_total }) => sum + parseInt(sub_total), 0);
      jmlDiskon = (total * inputDiskon ?? 0) / 100;
      totalSetelahDiskon = total - jmlDiskon;
      jmlPpn = (totalSetelahDiskon * inputPpn ?? 0) / 100;
      totalSetelahPpn = totalSetelahDiskon + jmlPpn;

      setTotalAkhir({
        total,
        jmlDiskon,
        jmlPpn,
        totalSetelahDiskon,
        totalSetelahPpn,
      });
    }
  };

  // INITIAL VALUES FORM TAMBAH DATA
  const formInitialValues = {
    tgl_purchase_order: new Date().toISOString().split("T")[0],
    tgl_sales_order: new Date().toISOString().split("T")[0],
    batas_waktu: new Date().toISOString().split("T")[0],
    no_sales_order: noSalesOrder,
    no_purchase_order: "",
    id_karyawan: "",
    id_customer: customerDetail !== "" ? customerDetail : "",
    data_customer: "",
    alamat_pengiriman: "",
    catatan: "",
    id_proyek: "",
  };

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    id_karyawan: Yup.string().required("Pilih Sales"),
    id_customer: Yup.string().required("Pilih Customer"),
    alamat_pengiriman: Yup.string().required("Masukkan Alamat Customer"),
    // no_purchase_order: Yup.string().required("Masukkan Nomor Purchase Order"),
    catatan: Yup.string().required("Tambahkan Catatan"),
    id_proyek: Yup.string().required("Pilih Proyek"),
  });

  // KIRIM UPDATE DATA KE SERVER
  const formSubmitHandler = (values, { setSubmitting }) => {
    if (orderItems.length > 0) {
      let finalOrder = [];

      orderItems.map((obj) =>
        finalOrder.push({
          id_barang: obj.id_barang,
          id_gudang: obj.id_gudang,
          qty_item: obj.qty,
          harga_satuan_jual: obj.harga_terbaru ? parseInt(obj.harga_terbaru) : 0,
        })
      );

      const finalValues = {
        ...values,
        diskon: inputDiskon,
        ppn: inputPpn,
        detail: finalOrder,
      };

      // SEND UPDATED DATA TO SERVER
      TransaksiSalesOrderApi.createSalesOrder(finalValues)
        .then((res) => {
          history.push("/sls/transaksi/sales-order", {
            registerAlertConfig: {
              variant: "primary",
              text: "Tambah data berhasil!",
            },
          });
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
          });
          setSubmitting(false);
        })
        .finally(() => {
          // SHOW ALERT
          setShowAlert(true);
        });
    } else {
      setSubmitting(false);
      setAlertConfig({
        variant: "danger",
        text: `Detail Kosong (Pilih item barang)`,
      });
      setShowAlert(true);
    }
  };

  return (
    // MAIN CRUD LAYOUT
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Card className="pb-3 mt-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Tambah Data Sales Order</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldValue,
              setSubmitting,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      {/* <Input
                        label="Tanggal Purchase Order"
                        type="date"
                        name="tgl_purchase_order"
                        value={values.tgl_purchase_order}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("tgl_purchase_order", value);
                        }}
                        error={errors.tgl_purchase_order && touched.tgl_purchase_order && true}
                        errorText={errors.tgl_purchase_order}
                      /> */}

                      <Input
                        label="Tanggal Sales Order"
                        type="date"
                        name="tgl_sales_order"
                        value={values.tgl_sales_order}
                        onChange={(e) => {
                          const value = e.target.value;
                          const date = value.split("-");
                          getNoSales(date.join("/"));
                          setFieldValue("tgl_sales_order", value);
                        }}
                        error={errors.tgl_sales_order && touched.tgl_sales_order && true}
                        errorText={errors.tgl_sales_order}
                      />

                      <Input
                        label="Batas Waktu Pengiriman"
                        type="date"
                        name="batas_waktu"
                        value={values.batas_waktu}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("batas_waktu", value);
                        }}
                        error={errors.batas_waktu && touched.batas_waktu && true}
                        errorText={errors.batas_waktu}
                      />
                      <SelectSearch
                        label="Proyek"
                        name="id_proyek"
                        placeholder="Pilih Proyek"
                        onChange={(val) => setFieldValue("id_proyek", val.value)}
                        option={dataProyek.map((val) => {
                          return {
                            value: val.id_proyek,
                            label: val.nama_proyek,
                          };
                        })} //option harus berupa value dan label
                        defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        error={errors.id_proyek && touched.id_proyek && true}
                        errorText={errors.id_proyek && touched.id_proyek && errors.id_proyek}
                      />
                    </div>

                    <div className="col-md-4 col-sm-12">
                      {/* <Input
                        label="No. Purchase Order"
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        name="no_purchase_order"
                        placeholder="Masukkan No. Purchase Order"
                        onChange={handleChange}
                        error={errors.no_purchase_order && touched.no_purchase_order && true}
                        errorText={errors.no_purchase_order}
                      /> */}

                      <Input
                        label="No. Sales Order"
                        type="text"
                        name="no_sales_order"
                        // placeholder="No. Sales Order"
                        readOnly={true}
                        value={noSalesOrder}
                        onChange={handleChange}
                        error={errors.no_sales_order && touched.no_sales_order && true}
                        errorText={errors.no_sales_order}
                      />

                      <SelectSearch
                        label="Sales"
                        name="id_karyawan"
                        placeholder="Pilih Sales"
                        onChange={(val) => setFieldValue("id_karyawan", val.value)}
                        option={dataKaryawan.map((val) => {
                          return {
                            value: val.id_karyawan,
                            label: val.nama_karyawan,
                          };
                        })} //option harus berupa value dan label
                        defaultValue=""
                        error={errors.id_karyawan && touched.id_karyawan && true}
                        errorText={errors.id_karyawan && touched.id_karyawan && errors.id_karyawan}
                      />
                      <div className="row">
                        <div className="col-md-12">
                          <SelectSearch
                            label="Customer"
                            name="id_customer"
                            placeholder="Pilih Customer"
                            onChange={(val) => {
                              setFieldValue("id_customer", val.value);
                              getCustomerDetail(val.value);
                              setFieldValue("alamat_pengiriman", getCustomerAlamat(val.value));
                            }}
                            option={dataCustomer.map((val) => {
                              return {
                                value: val.id_customer,
                                label: val.nama_customer,
                              };
                            })}
                            defaultValue=""
                            error={errors.id_customer && touched.id_customer && true}
                            errorText={
                              errors.id_customer && touched.id_customer && errors.id_customer
                            }
                          />
                        </div>
                        <div className="d-inline-flex align-items-center col-md-2 pt-4 pl-0">
                          {/* <UpdateButton /> */}
                          {/* <CreateCustomerButton onClick={() => setIsCreateForm(true)} /> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5 col-sm-12">
                      <TextArea
                        label="Data Customer"
                        placeholder="Data Customer"
                        name="data_customer"
                        value={customerDetail}
                        isDisabled={true}
                        rows={3}
                      />

                      <TextArea
                        label="Alamat Pengiriman"
                        placeholder="Masukkan Alamat Pengiriman"
                        name="alamat_pengiriman"
                        value={values.alamat_pengiriman}
                        onChange={handleChange}
                        // value={getCustomerAlamat(values.id_customer)}
                        // onChange={(e) => {
                        //     let { value } = e.target
                        //     setFieldValue("alamat_pengiriman", value);
                        //     e.target.value = value;
                        // }}
                        error={errors.alamat_pengiriman && touched.alamat_pengiriman && true}
                        errorText={errors.alamat_pengiriman}
                        rows={3}
                      />

                      <TextArea
                        label="Catatan"
                        placeholder="Masukkan Catatan"
                        name="catatan"
                        value={values.catatan}
                        onChange={handleChange}
                        error={errors.catatan && touched.catatan && true}
                        errorText={errors.catatan}
                        rows={3}
                      />
                    </div>
                  </div>

                  <hr className="mb-3" />

                  {/* CARI ITEM BARANG */}
                  <div className=" row">
                    <div className="col-md-4">
                      <SelectSearch
                        label="Pilih Kelompok"
                        name="id_kelompok"
                        placeholder="Pilih kelompok"
                        onChange={(val) => {
                          getDataItem(val.value);
                        }}
                        option={kelompok.map((val) => {
                          return {
                            value: val.id_kelompok,
                            label: val.nama_kelompok,
                          };
                        })} //option harus berupa value dan label
                        defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                      />
                    </div>
                    <div className="col-md-4">
                      <SelectSearch
                        label="Cari Item Barang Jadi"
                        name="id_barang"
                        placeholder={
                          selectItemConfig.isDisabled
                            ? "Pilih Kelompok Terlebih Dahulu"
                            : "Pilih Item Barang Jadi"
                        }
                        onChange={(val) => {
                          setIdItemBuaso(val.value);
                          setIsSearchingItemModal(true);
                        }}
                        option={dataItemBarang.map((val) => {
                          return {
                            value: val.id_item_buaso,
                            label: val.nama_item,
                          };
                        })} //option harus berupa value dan label
                        defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        isDisabled={selectItemConfig.isDisabled}
                        loading={selectItemConfig.isLoading}
                      />
                    </div>
                  </div>
                  {/* END SECTION CARI ITEM BARANG */}

                  {!Array.isArray(orderItems) ? (
                    <DataStatus text="Tidak dapat memuat data" />
                  ) : orderItems.length > 0 ? (
                    <>
                      {/* <Table /> */}
                      <CRUDLayout.Table>
                        <THead>
                          <Tr className="text-center">
                            <ThFixed>No</ThFixed>
                            <ThFixed>Aksi</ThFixed>
                            <ThFixed>Kode Barang</ThFixed>
                            <Th>Nama Barang</Th>
                            <Th>Gudang</Th>
                            <Th>Qty SO</Th>
                            <Th>Satuan</Th>
                            <Th>Harga Satuan</Th>
                            <Th>Jumlah</Th>
                          </Tr>
                        </THead>
                        <TBody>
                          {orderItems?.map((order, index) => {
                            return (
                              <Tr key={index}>
                                <TdFixed>{index + 1}</TdFixed>
                                <TdFixed>
                                  <div className="d-flex justify-content-center">
                                    <DeleteButton
                                      onClick={() => {
                                        let tempOrder = orderItems.filter(
                                          (e, e_index) => e_index !== index
                                        );
                                        setOrderItems(tempOrder);
                                      }}
                                    />
                                  </div>
                                </TdFixed>
                                <TdFixed>{order.kode_barang}</TdFixed>
                                <Td>{order.nama_barang}</Td>
                                <Td>{order.nama_gudang}</Td>
                                <Td textRight>{order.qty}</Td>
                                <Td>{order.nama_satuan}</Td>
                                <Td textRight>
                                  {order.harga_terbaru
                                    ? RupiahConvert(parseInt(order.harga_terbaru).toString()).detail
                                    : "Rp. 0"}
                                </Td>
                                <Td textRight>
                                  {order.sub_total
                                    ? RupiahConvert(parseInt(order.sub_total).toString()).detail
                                    : "Rp. 0"}
                                </Td>
                              </Tr>
                            );
                          })}
                          <Tr>
                            <Td colSpan={7} textRight className="align-middle py-2">
                              <strong>Total</strong>
                            </Td>
                            <Td />
                            <Td textRight className="align-middle py-2">
                              <strong>
                                {totalAkhir.total
                                  ? RupiahConvert(String(parseInt(totalAkhir.total))).detail
                                  : "Rp. 0"}
                              </strong>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td colSpan={7} textRight className="align-middle">
                              <strong>Diskon</strong>
                            </Td>
                            <Td>
                              <div className="d-flex justify-content-end">
                                <input
                                  className="form-control form-control-sm"
                                  style={{ maxWidth: "6rem" }}
                                  placeholder="0 %"
                                  type="number"
                                  name="diskon"
                                  max={99}
                                  min={0}
                                  onChange={(e) => {
                                    let { value } = e.target;
                                    if (e.target.value > 0 && e.target.value <= 99) {
                                      setDiskon(value);
                                    } else if (e.target.value > 99) {
                                      setDiskon(99);
                                      e.target.value = 99;
                                    } else {
                                      setDiskon(0);
                                    }
                                  }}
                                />
                              </div>
                            </Td>
                            <Td textRight className="align-middle">
                              <strong className="text-nowrap">
                                {totalAkhir.jmlDiskon
                                  ? RupiahConvert(String(parseInt(totalAkhir.jmlDiskon))).detail
                                  : "Rp. 0"}
                              </strong>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td colSpan={7} textRight className="align-middle py-2">
                              <strong className="text-nowrap">Total Setelah Diskon</strong>
                            </Td>
                            <Td />
                            <Td textRight className="align-middle py-2">
                              <strong className="text-nowrap">
                                {totalAkhir.totalSetelahDiskon
                                  ? RupiahConvert(String(parseInt(totalAkhir.totalSetelahDiskon)))
                                      .detail
                                  : "Rp. 0"}
                              </strong>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td colSpan={7} textRight className="align-middle">
                              <strong>PPN</strong>
                            </Td>
                            <Td>
                              <div className="d-flex justify-content-end">
                                <input
                                  className="form-control form-control-sm"
                                  style={{ maxWidth: "6rem" }}
                                  placeholder="0 %"
                                  type="number"
                                  name="ppn"
                                  max={99}
                                  min={0}
                                  onChange={(e) => {
                                    let { value } = e.target;
                                    if (e.target.value > 0 && e.target.value <= 99) {
                                      setPpn(value);
                                    } else if (e.target.value > 99) {
                                      setPpn(99);
                                      e.target.value = 99;
                                    } else {
                                      setPpn(0);
                                    }
                                  }}
                                />
                              </div>
                            </Td>
                            <Td textRight className="align-middle">
                              <strong className="text-nowrap">
                                {totalAkhir.jmlPpn
                                  ? RupiahConvert(String(parseInt(totalAkhir.jmlPpn))).detail
                                  : "Rp. 0"}
                              </strong>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td colSpan={7} textRight className="align-middle py-2">
                              <strong>Total Setelah PPn</strong>
                            </Td>
                            <Td />
                            <Td textRight className="align-middle py-2">
                              <strong className="text-nowrap">
                                {totalAkhir.totalSetelahPpn
                                  ? RupiahConvert(String(parseInt(totalAkhir.totalSetelahPpn)))
                                      .detail
                                  : "Rp. 0"}
                              </strong>
                            </Td>
                          </Tr>
                        </TBody>
                      </CRUDLayout.Table>
                      {/* TABLE TOTAL */}
                    </>
                  ) : (
                    <DataStatus text="Pilih item barang" />
                  )}

                  <div className="d-flex justify-content-end">
                    <ActionButton
                      type="submit"
                      variant="primary"
                      text="Tambah"
                      className="mt-2 px-4"
                      loading={isSubmitting}
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>

      {/* MODAL */}
      {/* {isCreateForm && <TambahCustomerModal />} */}
      {isSearchingItemModal && <SearchItemsModal />}
    </CRUDLayout>
  );
};

export default CreateSalesOrder;
