import React, { PureComponent } from "react"
import { Row, Col, Card } from "react-bootstrap"
import { DataStatus, Table, THead, TBody, Tr, ThFixed, TdFixed, Th, Td } from "components"
import { DateConvert, RupiahConvert } from "utilities"
import Logo from "config"
import { TableSectionDetail } from "../Section"

const PrintContent = ({ dataInfo, dataTable, dataPrelim, setDataTable }) => {
  const KopSuratSection = () => (
    <div className="d-flex mx-2 mb-4">
      <div>
        <img src={Logo.LOGO} width={110} />
      </div>
      <div style={{ marginLeft: 30 }}>
        <p>
          <b>PT. MARDIKA GRIYA PRASTA</b>
          <br />
          Jl. Sekarsari No.30, Kesambi, Denpasar, Bali, Indonesia <br />
          Phone +62(0)361 467-342 <br />
          Fax (0361) 46730 <br />
          Email sales@mgpwoodworks.com <br />
        </p>
      </div>
    </div>
  )

  const InfoSection = ({ dataInfo }) => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td width={125}>{title}</td>
        <td className="pr-2">:</td>
        <td>{value}</td>
      </tr>
    )

    const InfoItemVertical = ({ title, value }) => (
      <div>
        <small style={{ fontSize: 12 }} className="text-secondary">
          {title}
        </small>
        <div style={{ fontSize: 14 }}>{value}</div>
      </div>
    )

    return (
      <>
        <Row>
          <Col>
            <InfoItem
              title="Tgl. Sales Order"
              value={
                dataInfo.tgl_sales_order
                  ? DateConvert(new Date(dataInfo.tgl_sales_order)).detail
                  : "-"
              }
            />
            <InfoItem title="No. Sales Order" value={dataInfo.no_sales_order} />
          </Col>
          <Col>
            <InfoItem title="Customer" value={dataInfo.customer} />
            <InfoItem title="Sales" value={dataInfo.sales} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <InfoItemVertical
              title="Tgl. Faktur Penjualan"
              value={
                dataInfo.tgl_faktur_penjualan
                  ? DateConvert(new Date(dataInfo.tgl_faktur_penjualan)).detail
                  : "-"
              }
            />
          </Col>
          <Col>
            <InfoItemVertical title="No. Faktur Penjualan" value={dataInfo.no_faktur_penjualan} />
          </Col>
        </Row>
      </>
    )
  }

  const TTDSection = () => {
    const TTDItems = ({ title, value, jabatan, src }) => (
      <Col className="text-center">
        <div>{title}</div>
        <div style={{ height: 80 }}></div>
        {/* <div>
            <img src={src} height="80" />
          </div> */}
        <div>
          <u>{value}</u>
        </div>
        <div>{jabatan}</div>
      </Col>
    )

    return (
      <Row className="mt-5 pt-3">
        <>
          <TTDItems title="Petugas," jabatan="Bag. Delivery" />
          <TTDItems title="Petugas," jabatan="Sat-Pam" />
          <TTDItems title="Petugas," jabatan="Penerima" />
        </>
      </Row>
    )
  }

  return (
    <>
      <div className="p-4" style={{ backgroundColor: "white", height: "100%" }}>
        <KopSuratSection />
        <InfoSection dataInfo={dataInfo} />
        <hr />
        <TableSectionDetail dataInfo={dataInfo} dataTable={dataTable} dataPrelim={dataPrelim} setDataTable={setDataTable} />
        <TTDSection />
      </div>
    </>
  )
}

export default class PrintFakturPenjualan extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <PrintContent
        dataInfo={this.props.dataInfo}
        dataTable={this.props.dataTable}
        dataPrelim={this.props.dataPrelim}
        setDataTable={this.props.setDataTable}
      />
    )
  }
}
