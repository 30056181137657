import {
  useState,
  useEffect,
  useMemo,
  memo
} from 'react'
import {
  Row,
  Col,
  ButtonGroup,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import {
  IoAddOutline,
  IoArrowRedoOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
  IoPencilOutline,
  IoTrashBinOutline
} from 'react-icons/io5'
import {
  Formik,
  useFormik
} from 'formik'
import * as Yup from 'yup'
import Axios from 'axios'
import {
  Table,
  Th,
  Td,
  DataStatus,
  Input,
  Select,
  SelectSearch,
  ActionButton,
  DeleteModal
} from 'components'
import {
  RupiahConvert
} from 'utilities'
import {
  AnalisaBarangJadiRABApi
} from 'api'

const TableListAnalisaLabourCostOnSite = ({ idBarangJadi, dataHardwood, dataLabourCostOnSite, dataDropdownLabourCostOnSite, setDataLabourCostOnSite, setDataDropdownLabourCostOnSite, checkAndGetTemplateLabourCost }) => {
  const [dataItem, setDataItem] = useState([])
  const [dataTipeProduksi, setDataTipeProduksi] = useState([])
  const [dataBentuk, setDataBentuk] = useState([])
  const [editIndex, setEditIndex] = useState('')
  const [loading, setLoading] = useState({
    tipe: true,
    bentuk: true,
    item: true
  })
  const [deleteModalConfig, setDeleteModalConfig] = useState({
    index: '',
    show: false
  })

  const formInitialValues = {
    variabel1: dataDropdownLabourCostOnSite.variabel1 ?? 1,
    variabel2: dataDropdownLabourCostOnSite.variabel2 ?? 1,
    totalVariabel: dataDropdownLabourCostOnSite.totalVariabel ?? 1,
    id_tipe_produksi: dataDropdownLabourCostOnSite.id_tipe_produksi ?? null,
    id_bentuk: dataDropdownLabourCostOnSite.id_bentuk ?? null
  }

  const generateTotalTableHW = index => {
    const data = dataHardwood[index]

    const countTotalUnit = parseFloat((
      parseFloat(data.qty_raw).toFixed(6) *
      parseFloat(data.t_raw).toFixed(6) *
      parseFloat(data.w_raw).toFixed(6) *
      parseFloat(data.l_raw).toFixed(6)) /
      1000000
    ).toFixed(6)

    const totalUnit = parseFloat(countTotalUnit) < 0.000001 ? "0.000001" : parseFloat(countTotalUnit)
    const totalPrice = Math.round(parseFloat(totalUnit) * parseInt(data.unit_price) * parseFloat(data.konstanta))
    const checkPrice = data.is_header ? 0 : totalPrice
    const totalHardwood = parseInt(checkPrice)

    return totalHardwood
  }

  const generateSubTotalHW = useMemo(() => {
    const totalLuas = dataHardwood.reduce((prev, current) => parseFloat(parseFloat(current.total_luas ?? 0) + parseFloat(prev)).toFixed(6), 0)
    const totalVolume = dataHardwood.reduce((prev, current) => parseFloat(parseFloat(current.total_unit ?? 0) + parseFloat(prev)).toFixed(6), 0)
    const grandTotal = dataHardwood.reduce((prev, val) => {
      const countTotalUnit = parseFloat(
        (parseFloat(val.qty_raw).toFixed(6) *
          parseFloat(val.t_raw).toFixed(6) *
          parseFloat(val.w_raw).toFixed(6) *
          parseFloat(val.l_raw).toFixed(6)) /
        1000000
      ).toFixed(6)

      const totalUnit = parseFloat(countTotalUnit) < 0.000001 ? "0.000001" : parseFloat(countTotalUnit)
      const totalPrice = Math.round(parseFloat(totalUnit) * parseInt(val.unit_price) * parseFloat(val.konstanta))
      const checkPrice = val.is_header ? 0 : totalPrice
      const totalHardwood = parseInt(checkPrice)

      return prev + totalHardwood
    }, 0)

    return {
      luas: parseFloat(totalLuas).toPrecision(),
      volume: parseFloat(totalVolume).toPrecision(),
      grandTotal: grandTotal
    }
  }, [dataHardwood])

  const generateSubTotalLC = () => {
    const subTotal = dataLabourCostOnSite.reduce((prev, current) => {
      const checkQty = current.qty ? parseFloat(current.qty) : 0
      const checkPrice = current.unit_price ? parseFloat(current.unit_price) : 0
      const checkKonst = current.konstanta ? parseFloat(current.konstanta) : 0
      const total = parseInt(checkQty * checkPrice * checkKonst)

      return prev + total
    }, 0)

    return subTotal
  }

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, "")
    const convert = newValue.substring(0, 10)

    return convert
  }

  const getInitialData = async () => {
    setLoading({
      tipe: true,
      bentuk: true,
      item: true
    })

    return await Axios.all([
      AnalisaBarangJadiRABApi.getDropdownTipeProduksi(),
      AnalisaBarangJadiRABApi.getDropdownBentuk(),
      AnalisaBarangJadiRABApi.getDropdownItemLabourCost()
    ])
      .then(Axios.spread((tipe, bentuk, item) => {
        const dataTipe = tipe?.data?.data ?? []
        const dataBentuk = bentuk?.data?.data ?? []
        const dataItem = item?.data?.data ?? []
        const mapDataTipe = dataTipe.map(val => ({ value: val.id_barang_jadi_tipe_produksi ?? null, label: val.nama_barang_jadi_tipe_produksi ?? '-' }))
        const mapDataBentuk = dataBentuk.map(val => ({ value: val.id_barang_jadi_bentuk ?? null, label: val.nama_barang_jadi_bentuk ?? '-' }))
        const mapDataItem = dataItem.map(val => ({
          value: val.id_item_buaso ?? null,
          label: `${val.kode_item} - ${val.nama_item}`,
          nama: val.nama_item ?? '-',
          harga: val.harga_satuan ? parseFloat(val.harga_satuan) : 0,
          kode: val.kode_item ?? null,
          satuan: val.nama_satuan ?? null
        }))

        setDataTipeProduksi(mapDataTipe)
        setDataBentuk(mapDataBentuk)
        setDataItem(mapDataItem)
      }))
      .catch(() => {
        setDataTipeProduksi([])
        setDataBentuk([])
        setDataItem([])
      })
      .finally(() => {
        setLoading({
          tipe: false,
          bentuk: false,
          item: false
        })
      })
  }

  const getSubTotalTable = (qty, price, konst) => {
    const checkQty = qty ? parseFloat(qty) : 0
    const checkPrice = price ? parseFloat(price) : 0
    const checkKonst = konst ? parseFloat(konst) : 0

    return parseInt(checkQty * checkPrice * checkKonst)
  }

  useEffect(() => {
    // getInitialData()
  }, [])

  const FormSection = ({ formik }) => {
    const { values, setFieldValue, setValues } = formik

    const onVariable1Change = e => {
      const var1Val = decimalConvert(e.target.value)
      const var2Val = values.variabel2 ?? 0
      const total = parseFloat(parseFloat(var1Val * var2Val).toFixed(6)).toPrecision()

      setValues({
        ...values,
        variabel1: var1Val,
        totalVariabel: total
      })
    }

    const onVariable2Change = e => {
      const var1Val = values.variabel1 ?? 0
      const var2Val = decimalConvert(e.target.value)
      const total = parseFloat(parseFloat(var1Val * var2Val).toFixed(6)).toPrecision()

      setValues({
        ...values,
        variabel2: var2Val,
        totalVariabel: total
      })
    }

    const onTotalVariableChange = e => {
      const value = decimalConvert(e.target.value)

      setFieldValue('totalVariabel', value)
    }

    const onImplementationClick = () => {
      const updateKonst = dataLabourCostOnSite.map(val => ({ ...val, konstanta: values.totalVariabel }))
      setDataLabourCostOnSite(updateKonst)
      setDataDropdownLabourCostOnSite(values)
    }

    const checkGetTemplate = async (name, value) => {
      let newValues = values

      if (name === 'id_tipe_produksi') {
        newValues.id_tipe_produksi = value
      }

      if (name === 'id_bentuk') {
        newValues.id_bentuk = value
      }

      if (Boolean(newValues.id_tipe_produksi && newValues.id_bentuk)) {
        checkAndGetTemplateLabourCost(newValues)
      }
    }

    return (
      <Row>
        <Col lg={3} className="d-flex align-items-end mb-2">
          <b>Labour Cost On-Site</b>
        </Col>
        <Col lg>
          <Row>
            <Col lg className="d-flex justify-content-lg-end align-items-end">
              <div style={{ width: 90 }}>
                <Input
                  label="Variabel Hitung"
                  value={values.variabel1}
                  onChange={onVariable1Change}
                />
              </div>
              <div className="px-2" style={{ paddingBottom: 12 }}>X</div>
              <div style={{ width: 90 }}>
                <Input
                  value={values.variabel2}
                  onChange={onVariable2Change}
                />
              </div>
              <div className="px-2" style={{ paddingBottom: 12 }}>=</div>
              <div style={{ width: 90 }}>
                <Input
                  value={values.totalVariabel}
                  onChange={onTotalVariableChange}
                />
              </div>
              <ActionButton
                tooltip
                tooltipText="Implementasi variabel konstanta"
                size="sm"
                style={{ marginBottom: 10 }}
                className="ml-2"
                onClick={onImplementationClick}
              >
                <IoArrowRedoOutline style={{ transform: 'rotate(90deg)' }} />
              </ActionButton>
            </Col>
            <Col lg>
              <Row>
                <Col lg>
                  <SelectSearch
                    label="Tipe produksi"
                    placeholder="Pilih salah satu"
                    loading={loading.tipe}
                    option={dataTipeProduksi}
                    defaultValue={values.id_tipe_produksi ? dataTipeProduksi.find(val => val.value === values.id_tipe_produksi) : ''}
                    onChange={val => {
                      checkGetTemplate('id_tipe_produksi', val.value)
                    }}
                  />
                </Col>
                <Col lg>
                  <SelectSearch
                    label="Bentuk"
                    placeholder="Pilih salah satu"
                    loading={loading.bentuk}
                    option={dataBentuk}
                    defaultValue={values.id_bentuk ? dataBentuk.find(val => val.value === values.id_bentuk) : ''}
                    onChange={val => {
                      checkGetTemplate('id_bentuk', val.value)
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const TableFormSection = memo(({ type: TYPE, parentFormik }) => {
    const formInitialValues = {
      id_barang_jadi: idBarangJadi,
      id_item_buaso: TYPE === 'update' ? dataLabourCostOnSite[editIndex].id_item_buaso ?? null : '',
      kode_item: TYPE === 'update' ? dataLabourCostOnSite[editIndex].kode_item ?? null : '',
      nama_item: TYPE === 'update' ? dataLabourCostOnSite[editIndex].nama_item ?? null : '',
      qty: TYPE === 'update' ? dataLabourCostOnSite[editIndex].qty ?? 0 : 0,
      unit_price: TYPE === 'update' ? dataLabourCostOnSite[editIndex].unit_price ? parseFloat(dataLabourCostOnSite[editIndex].unit_price) : 0 : 0,
      nama_satuan: TYPE === 'update' ? dataLabourCostOnSite[editIndex].nama_satuan ?? null : '',
      konstanta: TYPE === 'update'
        ? dataLabourCostOnSite[editIndex].konstanta
          ? dataLabourCostOnSite[editIndex].konstanta
          : parentFormik.values.totalVariabel
        : parentFormik.values.totalVariabel
    }

    const formValidationSchema = Yup.object().shape({
      id_item_buaso: Yup.string().required(),
      qty: Yup.string().required(),
      unit_price: Yup.string().required(),
      konstanta: Yup.string().required()
    })

    const formSubmitHandler = values => {
      if (TYPE === 'update') {
        let newData = dataLabourCostOnSite
        newData[editIndex] = values

        setDataLabourCostOnSite(newData)
        setEditIndex(null)
      } else {
        setDataLabourCostOnSite([...dataLabourCostOnSite, values])
      }
    }

    const { values, errors, touched, setFieldValue, setValues, handleSubmit, isSubmitting } = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler
    })

    return (
      <tr>
        <Td textCenter>{TYPE === 'update' ? editIndex + 1 : ''}</Td>
        <Td colSpan={2}>
          <SelectSearch
            noMargin
            placeholder="Pilih item"
            menuShouldBlockScroll={true}
            menuPosition="fixed"
            option={dataItem}
            defaultValue={values.id_item_buaso ? dataItem.find(val => val.value === values.id_item_buaso) : ''}
            onChange={val => {
              setValues({
                ...values,
                id_item_buaso: val.value,
                nama_item: val.nama,
                kode_item: val.kode,
                unit_price: val.harga,
                nama_satuan: val.satuan
              })
            }}
            loading={loading.item}
            error={Boolean(errors.id_item_buaso && touched.id_item_buaso)}
          />
        </Td>
        <Td>
          <Input
            noMargin
            value={values.qty}
            onChange={e => {
              const value = decimalConvert(e.target.value) ?? 0
              setFieldValue('qty', value)
            }}
            error={Boolean(errors.qty && touched.qty)}
          />
        </Td>
        <Td>{values.nama_satuan ?? '-'}</Td>
        <Td>
          <Input
            noMargin
            value={RupiahConvert(String(values.unit_price)).withoutLabel}
            onChange={e => {
              const value = RupiahConvert(String(e.target.value ? e.target.value : 0)).default
              setFieldValue('unit_price', value)
            }}
            error={Boolean(errors.unit_price && touched.unit_price)}
          />
        </Td>
        <Td>
          <Input
            noMargin
            value={values.konstanta}
            onChange={e => {
              const value = decimalConvert(e.target.value) ?? 0
              setFieldValue('konstanta', value)
            }}
            error={Boolean(errors.konstanta && touched.konstanta)}
          />
        </Td>
        <Td textRight>{RupiahConvert(String(getSubTotalTable(values.qty, values.unit_price, values.konstanta))).withoutLabel}</Td>
        <Td>
          {TYPE === 'update'
            ? <ButtonGroup>
              <ActionButton
                type="submit"
                size="sm"
                variant="outline-success"
                onClick={handleSubmit}
              >
                <IoCheckmarkOutline />
              </ActionButton>
              <ActionButton
                size="sm"
                variant="outline-danger"
                onClick={() => setEditIndex('')}
              >
                <IoCloseOutline />
              </ActionButton>
            </ButtonGroup>
            : <ActionButton
              type="submit"
              size="sm"
              variant="primary"
              className="col"
              onClick={handleSubmit}
            >
              <IoAddOutline />
            </ActionButton>
          }
        </Td>
      </tr>
    )
  })

  return (
    <div>
      {/* Table Referensi Hardwood */}
      <div className="p-1">
        <b>Refrensi Hardwood</b>
      </div>
      <Table
        responsive
      >
        <thead className="bg-light">
          <tr>
            <Th rowSpan={2} width={20}>No</Th>
            <Th rowSpan={2} width={300}>Deskripsi</Th>
            <Th rowSpan={2} width={300}>Spesifikasi Kayu</Th>
            <Th rowSpan={2} width={300}>Part Kayu</Th>
            <Th rowSpan={2} width={300}>Tipe Finishing</Th>
            <Th rowSpan={2} width={300}>Tipe Sisi</Th>
            <Th rowSpan={2}>Qty. Final</Th>
            <Th noPadding colSpan={3} className="text-nowrap px-3">Final (cm)</Th>
            <Th rowSpan={2}>Qty. Raw</Th>
            <Th noPadding colSpan={3} className="text-nowrap px-3">Raw (cm)</Th>
            <Th rowSpan={3}>Luas (m2)</Th>
            <Th rowSpan={2}>Volume (m3)</Th>
            <Th rowSpan={2} width={250}>Unit Price (Rp)</Th>
            <Th rowSpan={2}>Konst.</Th>
            <Th rowSpan={2} noPadding width={250}>Sub Total Price (Rp)</Th>
          </tr>
          <tr>
            <Th noPadding>T</Th>
            <Th noPadding>W</Th>
            <Th noPadding>L</Th>
            <Th noPadding>T</Th>
            <Th noPadding>W</Th>
            <Th noPadding>L</Th>
          </tr>
        </thead>
        <tbody>
          {dataHardwood && dataHardwood.length > 0
            ? dataHardwood.map((val, index) => (
              <tr key={index}>
                <Td>{index + 1}</Td>
                <Td>{val.deskripsi}</Td>
                <Td>{val.nama_jenis_kayu}</Td>
                <Td>{val.nama_part_kayu}</Td>
                <Td>{val.nama_finishing_barang_jadi}</Td>
                <Td>{val.nama_tipe_sisi}</Td>
                <Td textRight>{val.qty_final ? parseFloat(val.qty_final).toPrecision() : 0}</Td>
                <Td textRight>{val.t_final ? parseFloat(val.t_final).toPrecision() : 0}</Td>
                <Td textRight>{val.w_final ? parseFloat(val.w_final).toPrecision() : 0}</Td>
                <Td textRight>{val.l_final ? parseFloat(val.l_final).toPrecision() : 0}</Td>
                <Td textRight>{val.qty_raw ? parseFloat(val.qty_raw).toPrecision() : 0}</Td>
                <Td textRight>{val.t_raw ? parseFloat(val.t_raw).toPrecision() : 0}</Td>
                <Td textRight>{val.w_raw ? parseFloat(val.w_raw).toPrecision() : 0}</Td>
                <Td textRight>{val.l_raw ? parseFloat(val.l_raw).toPrecision() : 0}</Td>
                <Td textRight>{val.total_luas ? parseFloat(val.total_luas).toPrecision() : 0}</Td>
                <Td textRight>{val.total_unit ? parseFloat(val.total_unit).toPrecision() : 0}</Td>
                <Td textRight>{RupiahConvert(String(val.unit_price ?? 0)).withoutLabel}</Td>
                <Td textRight>{val.konstanta ? parseFloat(val.konstanta).toPrecision() : 0}</Td>
                <Td textRight>{RupiahConvert(String(generateTotalTableHW(index))).withoutLabel}</Td>
              </tr>
            ))
            : <tr>
              <Td colSpan={19}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          }
        </tbody>
        <tfoot className="bg-light">
          <tr>
            <td className="text-right p-2" style={{ fontSize: 15 }} colSpan={14}><b>Total Luas & Volume : </b></td>
            <td className="text-right p-2" style={{ fontSize: 15 }}><b>{generateSubTotalHW.luas}</b></td>
            <td className="text-right p-2" style={{ fontSize: 15 }}><b>{generateSubTotalHW.volume}</b></td>
            <td className="text-right p-2" style={{ fontSize: 15 }} colSpan={2}><b>Total Price</b></td>
            <td className="text-right p-2" style={{ fontSize: 15 }}><b>{RupiahConvert(String(generateSubTotalHW.grandTotal)).detail}</b></td>
          </tr>
        </tfoot>
      </Table>

      <hr />

      {/* Table Labour Cost On-Site */}
      <Formik
        initialValues={formInitialValues}
      >
        {formik => (
          <>
            <Table className="mt-2">
              <thead className="bg-light">
                <tr>
                  <Th width={20}>No</Th>
                  <Th>Kode Item</Th>
                  <Th>Item Labour Cost On-Site</Th>
                  <Th width={70}>Qty</Th>
                  <Th>Unit</Th>
                  <Th width={200}>Unit Price (Rp)</Th>
                  <Th width={70}>Konst.</Th>
                  <Th width={200}>Sub Total Price (Rp)</Th>
                </tr>
              </thead>
              <tbody>
                {dataLabourCostOnSite && dataLabourCostOnSite.length > 0
                  ? dataLabourCostOnSite.map((val, index) => editIndex === index
                    ? <TableFormSection
                      key={index}
                      type="update"
                      parentFormik={formik}
                    />
                    : <tr key={index}>
                      <Td textCenter>{index + 1}</Td>
                      <Td>{val.kode_item ?? '-'}</Td>
                      <Td>{val.nama_item ?? '-'}</Td>
                      <Td textRight>{val.qty ? parseFloat(val.qty) : 0}</Td>
                      <Td>{val.nama_satuan}</Td>
                      <Td textRight>{val.unit_price ? RupiahConvert(String(val.unit_price)).detail : 0}</Td>
                      <Td textRight>{val.konstanta}</Td>
                      <Td textRight>{RupiahConvert(String(getSubTotalTable(val.qty, val.unit_price, val.konstanta))).withoutLabel}</Td>
                    </tr>
                  )
                  : <tr>
                    <Td colSpan={8}>
                      <DataStatus text="Tidak ada data" />
                    </Td>
                  </tr>
                }
              </tbody>
              <tfoot className="bg-light">
                <tr>
                  <td className="text-right p-2" style={{ fontSize: 15 }} colSpan={7}><b>Total : </b></td>
                  <td className="text-right p-2" style={{ fontSize: 15 }}><b>{RupiahConvert(String(generateSubTotalLC())).detail}</b></td>
                </tr>
              </tfoot>
            </Table>
          </>
        )}
      </Formik>
      <DeleteModal
        show={deleteModalConfig.show}
        onHide={(() => setDeleteModalConfig({ show: false, index: '' }))}
        onConfirm={() => {
          const newData = dataLabourCostOnSite.filter((val, index) => index !== deleteModalConfig.index)
          setDataLabourCostOnSite(newData)
          setDeleteModalConfig({ show: false, index: '' })
        }}
      />
    </div>
  )
}

export default TableListAnalisaLabourCostOnSite
