import React, { useState, useEffect } from 'react'
import { Card, Modal } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Axios from 'axios'
import {
  Alert,
  BackButton,
  DataStatus,
  Approval
} from 'components'
import { Form, InfoSection, TableBarangJadi, ModalPrelim } from '../Section'
import { SalesOrderSPKApi } from 'api'
import AnalisaBarangJadiSOSPK from '../AnalisaBarangJadi'

const DetailSOSPK = ({ setNavbarTitle }) => {
  const history = useHistory()
  const { id_sales_order_spk } = useParams()
  const [dataSOSPK, setDataSOSPK] = useState({})
  const [dataKaryawan, setDataKaryawan] = useState({})
  const [dataBarangJadi, setDataBarangJadi] = useState([])
  const [dataPrelim, setDataPrelim] = useState([])
  const [processedData, setProcessedData] = useState({})
  const [modalPrelim, setModalPrelim] = useState({ show: false })
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: ''
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    Axios.all([
      SalesOrderSPKApi.getSingleSOSPK({ id_sales_order_spk }),
      SalesOrderSPKApi.getKaryawan()
    ])
      .then(
        Axios.spread((resSingle, resKaryawan) => {
          const data = resSingle?.data?.data ?? {}
          const karyawan = resKaryawan?.data?.data?.map((item) =>
            Object({
              ...item,
              value: item.id_karyawan,
              label: item.nama_karyawan
            })
          )
          const barangJadi = data?.detail?.map((item) =>
            Object({
              ...item,
              kode_item: item.kode_barang,
              nama_item: item.nama_barang,
              satuan: item.kode_satuan,
              qty: parseFloat(item.qty_sales_order_spk),
              harga_satuan: item.harga_rate
            })
          )
          const prelim = data?.prelim?.map((item, index) =>
            Object({
              ...item,
              id_item: item.id_item_prelim,
              kode_item: item.kode_item_prelim,
              nama_item: item.nama_item_prelim,
              qty_item: item.qty_analisa,
              id_unit: item.id_satuan_prelim,
              nama_unit: item.nama_satuan_prelim,
              harga: parseFloat(item.unit_price ?? 0),
              qty_durasi: parseFloat(item.qty_durasi ?? 0),
              konstanta: parseFloat(item.konstanta ?? 0),
              order: index + 1
            })
          )

          setDataSOSPK(data)
          setDataKaryawan(karyawan)
          setDataPrelim(prelim)
          setDataBarangJadi(barangJadi)
        })
      )
      .catch((err) => {
        setIsFetchingFailed(true)
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!'
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const formInitialValues = {
    id_sales_order_spk: id_sales_order_spk,
    tgl_sales_order_spk: dataSOSPK?.tgl_sales_order_spk ?? '',
    no_sales_order_spk: dataSOSPK?.no_sales_order_spk ?? '',
    tgl_batas_waktu: dataSOSPK?.tgl_batas_waktu ?? '',
    id_sales: dataSOSPK?.id_sales ?? '',
    diskon: parseFloat(dataSOSPK?.diskon ?? 0),
    ppn: parseFloat(dataSOSPK?.ppn ?? 0),
    uang_muka: parseFloat(dataSOSPK?.uang_muka ?? 0)
  }
  const formValidationSchema = Yup.object().shape({
    tgl_sales_order_spk: Yup.string().required('Pilih tanggal Sales Order SPK'),
    no_sales_order_spk: Yup.string().required(
      'Pilih tanggal untuk menentukan nomor Sales Order SPK'
    ),
    tgl_batas_waktu: Yup.string().required('Pilih tanggal Batas Waktu'),
    id_sales: Yup.string().required('Pilih Sales')
  })
  const formSubmitHandler = (values) => {
    const finalValues = {
      ...values,
      barang_jadi: dataBarangJadi
    }

    SalesOrderSPKApi.save(finalValues)
      .then(() =>
        history.push('/transaksi/penawaran', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Data berhasil ditambah!'
          }
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          type: 'danger',
          text: 'Data gagal ditambah!'
        })
      })
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler
  })

  useEffect(() => {
    setNavbarTitle('Sales Order SPK')
    getInitialData()
  }, [])

  const ModalSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiSOSPK
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      )
    }

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false
          })
        }
      >
        <Modal.Header closeButton className='py-2'>
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    )
  }

  return (
    <>
      <Card>
        <Card.Header className='d-flex justify-content-between align-items-center'>
          <b>Detail Data Sales Order SPK</b>
          <div className='d-flex align-items-center justify-content-top'>
            <BackButton onClick={() => history.goBack()} />
          </div>
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text='Memuat data . . .' />
          ) : isFetchingFailed ? (
            <DataStatus text='Data gagal dimuat, tidak dapat menambah data!' />
          ) : (
            <>
              <InfoSection data={dataSOSPK} />
              <hr />
              <Form type='DETAIL' formik={formik} dataKaryawan={dataKaryawan} />
              <TableBarangJadi
                type='DETAIL'
                dataPrelim={dataPrelim}
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                setModalPrelim={setModalPrelim}
                setProcessedData={setProcessedData}
                setModalConfig={setModalConfig}
                diskon={formik.values.diskon}
                ppn={formik.values.ppn}
                uangMuka={formik.values.uang_muka}
                setFieldValue={formik.setFieldValue}
              />

              <ModalSection
                processedData={processedData}
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                alertConfig={alertConfig}
                setAlertConfig={setAlertConfig}
                modalConfig={modalConfig}
                setModalConfig={setModalConfig}
              />

              {modalPrelim.show && (
                <ModalPrelim
                  show={modalPrelim.show}
                  onHide={() => setModalPrelim({ show: false })}
                  dataPrelim={dataPrelim}
                  onSubmit={(val) => {
                    setDataPrelim(val)
                    setModalPrelim({ show: false })
                  }}
                />
              )}
            </>
          )}
        </Card.Body>
      </Card>

      {Boolean(!isPageLoading && !isFetchingFailed) && (
        <Approval title='Sales Order SPK' data={dataSOSPK} />
      )}
    </>
  )
}

export default DetailSOSPK
