import React, { useState } from "react";
import { IoCheckboxOutline } from "react-icons/io5";
import { Table, Th, Td, ThFixed, DataStatus, TdFixed } from "components";
import { RupiahConvert, DateConvert } from "utilities";
import ModalPrelim from "./ModalPrelim";

const TableSection = ({ dataTable, dataInfo, dataPrelim, setDataPrelim, isPrelim }) => {
  const [modalPrelim, setModalPrelim] = useState({ show: false });
  const values = {
    akumulasi_prelim: dataInfo.akumulasi_prelim ?? 0,
    persentase_prelim: dataInfo.persentase_prelim,
    diskon: dataInfo.diskon,
    ppn: dataInfo.ppn,
    uang_muka: dataInfo.uang_muka,
  };

  const getTotalAfterProgress = (qty, harga, akumulasi, persentase) => {
    const getSubTotal = parseInt(qty * harga);
    const sum = parseInt(akumulasi) < 100 ? parseInt(akumulasi) + parseInt(persentase) : 100;
    const totalAkumulasi = (getSubTotal / 100) * parseInt(sum);
    return parseInt(totalAkumulasi);
  };

  const getTotalFooter = () => {
    const getGrandTotalPrelim = dataPrelim?.reduce((prev, current) => {
      const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
      const newHarga = current.harga ? parseFloat(current.harga) : 0;
      const newQtyDurasi = current.qty_durasi ? parseFloat(current.qty_durasi) : 0;
      const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
      const subTotal = newQtyItem * newHarga;
      // const total = subTotal * newQtyDurasi * newKonts;
      const total = subTotal * newKonts;

      return prev + total;
    }, 0);

    // get prelim disertakan dalam faktur
    const getPrelimFaktur = () => {
      const total = parseInt(getGrandTotalPrelim || 0);
      const floatPrelimFaktur = parseFloat(values?.persentase_prelim || 0);
      const totalPrelimFaktur = total * (floatPrelimFaktur / 100);

      return totalPrelimFaktur;
    };

    const getTotal = () => {
      const totalPrelimFaktur = parseInt(getPrelimFaktur() || 0);
      const totalSelected = dataTable.reduce((prev, current) => {
        const total = getTotalAfterProgress(
          current.qty,
          current.harga,
          current.akumulasi_progress,
          current.persentase_progress
        );

        return prev + total;
      }, 0);

      return totalPrelimFaktur + totalSelected;
    };

    const getDiskon = () => {
      const total = getTotal();
      const diskon = values.diskon;
      const totalDiskon = (total / 100) * diskon;

      return totalDiskon;
    };

    const getTotalAfterDiskon = () => {
      const total = getTotal();
      const totalDiskon = getDiskon();

      return total - totalDiskon;
    };

    const getPPN = () => {
      const totalAfterDiskon = getTotalAfterDiskon();
      const ppn = values.ppn;
      const totalPPN = (totalAfterDiskon / 100) * ppn;

      return totalPPN;
    };

    const getTotalAfterPPN = () => {
      const ppn = getPPN();
      const totalAfterDiskon = getTotalAfterDiskon();

      return totalAfterDiskon + ppn;
    };
    const getDP = () => {
      const totalAfterPPN = getTotalAfterPPN();
      const dp = values.uang_muka;
      const totalDP = (totalAfterPPN / 100) * dp;

      return totalDP;
    };

    const getTotalAfterDP = () => {
      const dp = getDP();
      const totalAfterPPN = getTotalAfterPPN();

      return totalAfterPPN - dp;
    };

    return {
      total: RupiahConvert(String(parseInt(getTotal()))).detail,
      totalPrelim: RupiahConvert(String(parseInt(getGrandTotalPrelim))).detail,
      totalPrelimFaktur: RupiahConvert(String(parseInt(getPrelimFaktur()))).detail,
      diskon: RupiahConvert(String(parseInt(getDiskon()))).detail,
      ppn: RupiahConvert(String(parseInt(getPPN()))).detail,
      dp: RupiahConvert(String(parseInt(getDP()))).detail,
      totalAfterDiskon: RupiahConvert(String(parseInt(getTotalAfterDiskon()))).detail,
      totalAfterPPN: RupiahConvert(String(parseInt(getTotalAfterPPN()))).detail,
      totalAfterDP: RupiahConvert(String(parseInt(getTotalAfterDP()))).detail,
    };
  };

  return (
    <>
      <div className="p-1">
        <b>List Surat Jalan</b>
      </div>
      <Table>
        <thead className="bg-light">
          <Th>No</Th>
          <ThFixed>Informasi Penerimaan Surat Jalan</ThFixed>
          <ThFixed>Informasi Surat Jalan</ThFixed>
          <ThFixed>Informasi Delivery Order</ThFixed>
          <Th width={300}>Item Barang</Th>
          <Th>Akumulasi Progress</Th>
          <Th>Qty. Surat Jalan</Th>
          <Th>Harga Satuan</Th>
          <Th>Sub Total</Th>
          <Th>Progress (%)</Th>
          <Th>Total</Th>
        </thead>
        <tbody>
          {dataTable.length > 0 ? (
            <>
              {dataTable.map((val, index) => (
                <tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td>
                    <div>
                      {val.tgl_penerimaan_surat_jalan
                        ? DateConvert(new Date(val.tgl_penerimaan_surat_jalan)).defaultDMY
                        : "-"}
                    </div>
                    <div> {val.no_penerimaan_surat_jalan} </div>
                  </Td>
                  <Td>
                    <div>
                      {val.tgl_surat_jalan
                        ? DateConvert(new Date(val.tgl_surat_jalan)).defaultDMY
                        : "-"}
                    </div>
                    <div> {val.no_surat_jalan} </div>
                  </Td>
                  <Td>
                    <div>
                      {val.tgl_delivery_order
                        ? DateConvert(new Date(val.tgl_delivery_order)).defaultDMY
                        : "-"}
                    </div>
                    <div> {val.no_delivery_order} </div>
                  </Td>
                  <Td>{val.nama_item}</Td>
                  <Td textRight>{parseInt(val.akumulasi_progress ?? 0)} %</Td>
                  <Td textRight>
                    {val.qty} {val.satuan}
                  </Td>
                  <Td textRight>{RupiahConvert(val.harga.toString()).detail}</Td>
                  <Td textRight>{RupiahConvert((val.qty * val.harga).toString()).detail}</Td>
                  <Td textRight>{parseInt(val.persentase_progress)} %</Td>
                  <Td textRight width="200">
                    {
                      RupiahConvert(
                        parseInt(
                          getTotalAfterProgress(
                            val.qty,
                            val.harga,
                            val.akumulasi_progress,
                            val.persentase_progress
                          )
                        ).toString()
                      ).detail
                    }
                  </Td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
          {/* Total Prelim */}
          <tr>
            <Td colspan={5} className="p-2 text-right font-weight-bold align-middle">
              Prelim
            </Td>
            <Td className="p-2 text-right align-middle">{parseFloat(values.akumulasi_prelim)} %</Td>
            <Td>-</Td>
            <Td>-</Td>
            <Td className="py-2 px-1 text-right align-middle">{getTotalFooter().totalPrelim} </Td>
            <Td className="p-2 text-right align-middle">
              {parseFloat(values.persentase_prelim)} %
            </Td>
            <Td className="py-2 px-1 text-right align-middle">
              {getTotalFooter().totalPrelimFaktur}
            </Td>
          </tr>
        </tbody>
        <tfoot style={{ fontSize: 13 }}>
          {/* Total */}
          <tr className="bg-light">
            <td colSpan={10} className="p-2 text-right font-weight-bold align-middle">
              Total
            </td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().total}
            </td>
          </tr>
          {/* Diskon */}
          <tr>
            <td colSpan={10} className="py-2 px-1 font-weight-bold text-right">
              Diskon ({values.diskon}%)
            </td>
            <td className="py-0 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().diskon}
            </td>
          </tr>
          {/* Total Setelah Diskon */}
          <tr className="bg-light">
            <td colSpan={10} className="p-2 text-right font-weight-bold align-middle">
              Total Setelah Diskon
            </td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().totalAfterDiskon}
            </td>
          </tr>
          {/* PPN */}
          <tr>
            <td colSpan={10} className="py-2 px-1 font-weight-bold text-right">
              PPN ({values.ppn}%)
            </td>
            <td className="py-0 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().ppn}
            </td>
          </tr>
          {/* Total Setelah PPN */}
          <tr className="bg-light">
            <td colSpan={10} className="py-2 px-1 text-right font-weight-bold align-middle">
              Total Setelah PPN
            </td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().totalAfterPPN}
            </td>
          </tr>

          {/* DP */}
          <tr>
            <td colSpan={10} className="py-2 px-1 font-weight-bold text-right">
              Uang Muka ({values.uang_muka}%)
            </td>
            <td className="py-0 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().dp}
            </td>
          </tr>
          {/* Total Setelah DP */}
          <tr className="bg-light">
            <td colSpan={10} className="py-2 px-1 text-right font-weight-bold align-middle">
              Sisa
            </td>
            <td className="py-2 px-1 text-right font-weight-bold align-middle">
              {getTotalFooter().totalAfterDP}
            </td>
          </tr>
        </tfoot>
      </Table>
      {modalPrelim.show && (
        <ModalPrelim
          show={modalPrelim.show}
          onHide={() => setModalPrelim({ show: false })}
          dataPrelim={dataPrelim}
          onSubmit={(val) => {
            setDataPrelim(val);
            setModalPrelim({ show: false });
          }}
        />
      )}
    </>
  );
};

export default TableSection;
