import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";

const ModalFilterFakturPenjualan = ({
  dropdown,
  modalConfig,
  setModalConfig,
  filterConfig,
  setFilterConfig,
  handleResetFilter,
}) => {
  const [dataFilter, setDataFilter] = useState({ ...filterConfig });
  const {
    tgl_mulai_faktur_penjualan,
    tgl_selesai_faktur_penjualan,
    tgl_mulai_sales_order,
    tgl_selesai_sales_order,
    tgl_mulai_batas_waktu,
    tgl_selesai_batas_waktu,
    id_customer,
    id_sales,
    id_proyek,
    status_approval,
  } = dataFilter;

  const onChangeTglFP = (dates) => {
    const [start, end] = dates;

    const tgl_mulai_faktur_penjualan = start
      ? DateConvert(start).default
      : null;
    const tgl_selesai_faktur_penjualan = end ? DateConvert(end).default : null;

    setDataFilter((prev) => ({
      ...prev,
      tgl_mulai_faktur_penjualan,
      tgl_selesai_faktur_penjualan,
    }));
  };

  const onChangeTglSO = (dates) => {
    const [start, end] = dates;

    const tgl_mulai_sales_order = start ? DateConvert(start).default : null;
    const tgl_selesai_sales_order = end ? DateConvert(end).default : null;

    setDataFilter((prev) => ({
      ...prev,
      tgl_mulai_sales_order,
      tgl_selesai_sales_order,
    }));
  };

  const onChangeTglSelesai = (dates) => {
    const [start, end] = dates;

    const tgl_mulai_batas_waktu = start ? DateConvert(start).default : null;
    const tgl_selesai_batas_waktu = end ? DateConvert(end).default : null;

    setDataFilter((prev) => ({
      ...prev,
      tgl_mulai_batas_waktu,
      tgl_selesai_batas_waktu,
    }));
  };

  console.log("status", status_approval);

  return (
    <Modal show={modalConfig.show} onHide={() => setModalConfig(false)}>
      <Modal.Header closeButton>
        <b>Filter Data</b>
      </Modal.Header>
      <Modal.Body>
        <DatePicker
          selectsRange
          label="Tgl. Faktur Penjualan"
          placeholderText="Pilih tanggal Faktur Penjualan"
          startDate={
            tgl_mulai_faktur_penjualan
              ? new Date(tgl_mulai_faktur_penjualan)
              : ""
          }
          endDate={
            tgl_selesai_faktur_penjualan
              ? new Date(tgl_selesai_faktur_penjualan)
              : ""
          }
          onChange={(dates) => onChangeTglFP(dates)}
          monthsShown={2}
        />
        <DatePicker
          selectsRange
          label="Tgl. Sales Order"
          placeholderText="Pilih tanggal Sales Order"
          startDate={
            tgl_mulai_sales_order ? new Date(tgl_mulai_sales_order) : ""
          }
          endDate={
            tgl_selesai_sales_order ? new Date(tgl_selesai_sales_order) : ""
          }
          onChange={(dates) => onChangeTglSO(dates)}
          monthsShown={2}
        />
        <SelectSearch
          label="Customer"
          placeholder="Pilih customer"
          defaultValue={dropdown?.customer.find(
            (item) => item.value === id_customer
          )}
          option={dropdown?.customer}
          onChange={(e) =>
            setDataFilter((prev) => ({ ...prev, id_customer: e.value }))
          }
        />
        <SelectSearch
          label="Sales"
          placeholder="Pilih sales"
          defaultValue={dropdown?.sales?.find(
            (item) => item.value === id_sales
          )}
          option={dropdown?.sales}
          onChange={(e) =>
            setDataFilter((prev) => ({ ...prev, id_sales: e.value }))
          }
        />
        <SelectSearch
          label="Proyek"
          placeholder="Pilih proyek"
          defaultValue={dropdown?.proyek?.find(
            (item) => item.value === id_proyek
          )}
          option={dropdown?.proyek}
          onChange={(e) =>
            setDataFilter((prev) => ({ ...prev, id_proyek: e.value }))
          }
        />
        <DatePicker
          selectsRange
          label="Tgl. Selesai"
          placeholderText="Pilih tanggal Selesai"
          startDate={
            tgl_mulai_batas_waktu ? new Date(tgl_mulai_batas_waktu) : ""
          }
          endDate={
            tgl_selesai_batas_waktu ? new Date(tgl_selesai_batas_waktu) : ""
          }
          onChange={(dates) => onChangeTglSelesai(dates)}
          monthsShown={2}
        />
        <SelectSearch
          label="Status Approval"
          placeholder="Pilih status approval"
          defaultValue={dropdown?.approval?.find(
            (item) => item?.value === status_approval
          )}
          option={dropdown?.approval}
          onChange={(e) =>
            setDataFilter((prev) => ({ ...prev, status_approval: e.value }))
          }
        />

        {console.log("dd", dropdown)}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleResetFilter}>
          RESET
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setFilterConfig({ ...dataFilter, isFilter: true });
            setModalConfig(false);
          }}
        >
          FILTER
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalFilterFakturPenjualan;
