import { Fragment, useState } from 'react'
import { Table, Th, Td, ThFixed, DataStatus, TdFixed } from 'components'
import { RupiahConvert, DateConvert } from 'utilities'
import ModalPrelim from './ModalPrelim'

const TableSection = ({
  formik,
  dataTable,
  setDataTable,
  dataPrelim,
  setDataPrelim,
  dataHistory,
  isPrelim,
  statusApproval = 'PEN'
}) => {
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [modalPrelim, setModalPrelim] = useState({ show: false })
  const { values, setFieldValue } = formik

  const getAkumulasiPersentasePrelimFaktur = dataHistory?.reduce(
    (persentase, { persentase_prelim }) =>
      persentase + parseFloat(persentase_prelim || 0),
    0
  )

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '')
    const convert = newValue.substring(0, 3)

    if (parseInt(convert) > 100) {
      return 100
    }

    return convert
  }

  const onCheckedHandler = (index) => {
    const newData = dataTable.map((val, i) => {
      return i === index ? { ...val, checked: !val.checked } : { ...val }
    })

    setDataTable(newData)
  }

  const onCheckedAllHandler = () => {
    const newData = dataTable.map((val) => {
      return { ...val, checked: !isAllChecked }
    })

    setDataTable(newData)
    setIsAllChecked(!isAllChecked)
  }

  const onChangePersentasePrelim = (value) => {
    // cek jika akumulasi persentase faktur dibawah 100
    const isMaxAllowed = Boolean(
      parseFloat(value) + parseFloat(getAkumulasiPersentasePrelimFaktur) <= 100
    )

    if (isMaxAllowed) {
      setFieldValue('persentase_prelim', value)
    } else {
      const maxValue = 100 - getAkumulasiPersentasePrelimFaktur
      window.alert(
        `Akumulasi persentase prelim dalam faktur tidak dapat lebih dari 100%. Nilai maximal input : ${maxValue}%`
      )
      setFieldValue('persentase_prelim', maxValue)
    }
  }

  const getTotalAfterProgress = (qty, harga, akumulasi, persentase) => {
    const getSubTotal = parseInt(qty * harga)
    const sum =
      parseInt(akumulasi) < 100
        ? parseInt(akumulasi) + parseInt(persentase)
        : 100
    const totalAkumulasi = (getSubTotal / 100) * parseInt(sum)
    return parseInt(totalAkumulasi)
  }

  const getTotalFooter = () => {
    const getGrandTotalPrelim = dataPrelim?.reduce((prev, current) => {
      const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0
      const newHarga = current.harga ? parseFloat(current.harga) : 0
      const newQtyDurasi = current.qty_durasi
        ? parseFloat(current.qty_durasi)
        : 0
      const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0
      const subTotal = newQtyItem * newHarga
      // const total = subTotal * newQtyDurasi * newKonts;
      const total = subTotal * newKonts

      return prev + total
    }, 0)

    // get prelim disertakan dalam faktur
    const getPrelimFaktur = () => {
      const total = parseInt(getGrandTotalPrelim || 0)
      const floatPrelimFaktur = parseFloat(values?.persentase_prelim || 0)
      const totalPrelimFaktur = total * (floatPrelimFaktur / 100)

      return totalPrelimFaktur
    }

    const getTotal = () => {
      const totalPrelimFaktur = parseInt(getPrelimFaktur() || 0)
      const totalSelected = dataTable.reduce((prev, current) => {
        let total = 0

        if (current.checked) {
          total = getTotalAfterProgress(
            current.qty,
            current.harga,
            current.akumulasi_progress,
            current.persentase_progress
          )
        }

        return prev + total
      }, 0)

      return totalPrelimFaktur + totalSelected
    }

    const getDiskon = () => {
      const total = getTotal()
      const diskon = values.diskon
      const totalDiskon = (total / 100) * diskon

      return totalDiskon
    }

    const getTotalAfterDiskon = () => {
      const total = getTotal()
      const totalDiskon = getDiskon()

      return total - totalDiskon
    }

    const getPPN = () => {
      const totalAfterDiskon = getTotalAfterDiskon()
      const ppn = values.ppn
      const totalPPN = (totalAfterDiskon / 100) * ppn

      return totalPPN
    }

    const getTotalAfterPPN = () => {
      const ppn = getPPN()
      const totalAfterDiskon = getTotalAfterDiskon()

      return totalAfterDiskon + ppn
    }

    const getDP = () => {
      const totalAfterPPN = getTotalAfterPPN()
      const dp = values.uang_muka
      const totalDP = (totalAfterPPN / 100) * dp

      return totalDP
    }

    const getTotalAfterDP = () => {
      const dp = getDP()
      const totalAfterPPN = getTotalAfterPPN()

      return totalAfterPPN - dp
    }

    return {
      total: RupiahConvert(String(parseInt(getTotal()))).detail,
      totalPrelim: RupiahConvert(String(parseInt(getGrandTotalPrelim))).detail,
      totalPrelimFaktur: RupiahConvert(String(parseInt(getPrelimFaktur())))
        .detail,
      diskon: RupiahConvert(String(parseInt(getDiskon()))).detail,
      ppn: RupiahConvert(String(parseInt(getPPN()))).detail,
      dp: RupiahConvert(String(parseInt(getDP()))).detail,
      totalAfterDiskon: RupiahConvert(String(parseInt(getTotalAfterDiskon())))
        .detail,
      totalAfterPPN: RupiahConvert(String(parseInt(getTotalAfterPPN()))).detail,
      totalAfterDP: RupiahConvert(String(parseInt(getTotalAfterDP()))).detail
    }
  }

  const Checkbox = ({ checked, onClick, disabled }) => (
    <div className='form-check' onClick={onClick}>
      <input
        type='checkbox'
        className='form-check-input mt-0'
        style={{ width: 20, height: 20 }}
        defaultChecked={checked}
        disabled={disabled}
      />
    </div>
  )

  return (
    <>
      <div className='p-1'>
        <b>List Surat Jalan</b>
      </div>
      <Table>
        <thead className='bg-light'>
          <tr>
            <Th>No</Th>
            <ThFixed>Informasi Penerimaan Surat Jalan</ThFixed>
            <ThFixed>Informasi Surat Jalan</ThFixed>
            <ThFixed>Informasi Delivery Order</ThFixed>
            <Th width={300}>Item Barang</Th>
            <Th>Akumulasi Progress</Th>
            <Th>Qty. Surat Jalan</Th>
            <Th>Harga Satuan</Th>
            <Th>Sub Total</Th>
            <Th>Progress (%)</Th>
            <Th>Total</Th>
            <Th className='pt-0'>
              <div className='mb-3 d-flex justify-content-center align-items-center'>
                <Checkbox
                  checked={isAllChecked}
                  onClick={() => onCheckedAllHandler()}
                />
              </div>
            </Th>
          </tr>
        </thead>
        <tbody>
          {dataTable.length > 0 ? (
            <>
              {dataTable.map((val, index) => (
                <Fragment key={index}>
                  <tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <Td>
                      <div>
                        {val.tgl_penerimaan_surat_jalan
                          ? DateConvert(
                              new Date(val.tgl_penerimaan_surat_jalan)
                            ).defaultDMY
                          : '-'}
                      </div>
                      <div> {val.no_penerimaan_surat_jalan} </div>
                    </Td>
                    <Td>
                      <div>
                        {val.tgl_surat_jalan
                          ? DateConvert(new Date(val.tgl_surat_jalan))
                              .defaultDMY
                          : '-'}
                      </div>
                      <div> {val.no_surat_jalan} </div>
                    </Td>
                    <Td>
                      <div>
                        {val.tgl_delivery_order
                          ? DateConvert(new Date(val.tgl_delivery_order))
                              .defaultDMY
                          : '-'}
                      </div>
                      <div> {val.no_delivery_order} </div>
                    </Td>
                    <Td>{val.nama_item}</Td>
                    <Td textRight>{val.akumulasi_progress + ' %'}</Td>
                    <Td textRight>
                      {val.qty} {val.satuan}
                    </Td>
                    <Td textRight>
                      {RupiahConvert(val.harga.toString()).detail}
                    </Td>
                    <Td textRight>
                      {RupiahConvert((val.qty * val.harga).toString()).detail}
                    </Td>
                    <Td className='p-0 font-weight-bold'>
                      <input
                        className='form-control form-control-sm text-right'
                        value={val.persentase_progress}
                        onChange={(e) => {
                          const value = e.target.value
                          const convert = parseFloat(decimalConvert(value) || 0)

                          setDataTable(
                            dataTable.map((item, idx) =>
                              idx === index
                                ? {
                                    ...item,
                                    persentase_progress: convert
                                  }
                                : item
                            )
                          )
                        }}
                      />
                    </Td>
                    <Td textRight width='200'>
                      {
                        RupiahConvert(
                          parseInt(
                            getTotalAfterProgress(
                              val.qty,
                              val.harga,
                              val.akumulasi_progress,
                              val.persentase_progress
                            )
                          ).toString()
                        ).detail
                      }
                    </Td>
                    <Td className='p-0'>
                      <div className='d-flex justify-content-center align-items-center'>
                        <Checkbox
                          checked={val.checked}
                          onClick={() => onCheckedHandler(index)}
                          disabled={
                            parseInt(val.akumulasi_progress) >= 100
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Td>
                  </tr>
                </Fragment>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={11}>
                <DataStatus text='Tidak ada data' />
              </td>
            </tr>
          )}
          {/* Total Prelim*/}
          {isPrelim && (
            <tr>
              <Td
                colSpan={5}
                className='p-2 text-right font-weight-bold align-middle'
              >
                Prelim
              </Td>

              <Td className='p-2 text-right align-middle'>
                {parseFloat(values.akumulasi_prelim ?? 0)} %
              </Td>
              <Td>-</Td>
              <Td>-</Td>
              <Td className='py-2 px-1 text-right align-middle'>
                {getTotalFooter().totalPrelim}
              </Td>
              <Td className='p-0'>
                <input
                  className='form-control form-control-sm text-right'
                  value={values.persentase_prelim}
                  onChange={(e) => {
                    const value = e.target.value
                    const convert = parseFloat(decimalConvert(value) || 0)

                    onChangePersentasePrelim(convert)
                  }}
                />
              </Td>
              <Td className='py-0 px-1 text-right align-middle'>
                {getTotalFooter().totalPrelimFaktur}
              </Td>
              <Td></Td>
            </tr>
          )}
        </tbody>
        <tfoot style={{ fontSize: 13 }}>
          {/* Total */}
          <tr className='bg-light'>
            <td
              colSpan={10}
              className='p-2 text-right font-weight-bold align-middle'
            >
              Total
            </td>
            <td className='py-2 px-1 text-right font-weight-bold align-middle'>
              {getTotalFooter().total}
            </td>
            <td></td>
          </tr>

          {/* Diskon */}
          <tr>
            <td colSpan={10} className='p-0 font-weight-bold'>
              <div className='d-flex justify-content-end align-items-center'>
                <div className='mx-2'>Diskon</div>
                <input
                  disabled
                  className='form-control form-control-sm text-right'
                  style={{ width: 60 }}
                  value={values.diskon + ' %'}
                  onChange={(e) => {
                    const value = e.target.value
                    const convert = decimalConvert(value)
                    setFieldValue('diskon', convert)
                  }}
                />
              </div>
            </td>
            <td className='py-0 px-1 text-right font-weight-bold align-middle'>
              {getTotalFooter().diskon}
            </td>
            <td></td>
          </tr>

          {/* Total Setelah Diskon */}
          <tr className='bg-light'>
            <td
              colSpan={10}
              className='p-2 text-right font-weight-bold align-middle'
            >
              Total Setelah Diskon
            </td>
            <td className='py-2 px-1 text-right font-weight-bold align-middle'>
              {getTotalFooter().totalAfterDiskon}
            </td>
            <td></td>
          </tr>

          {/* PPN */}
          <tr>
            <td colSpan={10} className='p-0 font-weight-bold'>
              <div className='d-flex justify-content-end align-items-center'>
                <div className='mx-2'>PPN</div>
                <input
                  disabled
                  className='form-control form-control-sm text-right'
                  style={{ width: 60 }}
                  value={values.ppn + ' %'}
                  onChange={(e) => {
                    const value = e.target.value
                    const convert = decimalConvert(value)
                    setFieldValue('ppn', convert)
                  }}
                />
              </div>
            </td>
            <td className='py-0 px-1 text-right font-weight-bold align-middle'>
              {getTotalFooter().ppn}
            </td>
            <td></td>
          </tr>

          {/* Total Setelah PPN */}
          <tr className='bg-light'>
            <td
              colSpan={10}
              className='py-2 px-1 text-right font-weight-bold align-middle'
            >
              Total Setelah PPN
            </td>
            <td className='py-2 px-1 text-right font-weight-bold align-middle'>
              {getTotalFooter().totalAfterPPN}
            </td>
            <td></td>
          </tr>

          {/* DP */}
          <tr>
            <td colSpan={10} className='p-0 font-weight-bold'>
              <div className='d-flex justify-content-end align-items-center'>
                <div className='mx-2'>Uang Muka</div>
                <input
                  disabled
                  className='form-control form-control-sm text-right'
                  style={{ width: 60 }}
                  value={values.uang_muka + ' %'}
                  onChange={(e) => {
                    const value = e.target.value
                    const convert = decimalConvert(value)
                    setFieldValue('uang_muka', convert)
                  }}
                />
              </div>
            </td>
            <td className='py-0 px-1 text-right font-weight-bold align-middle'>
              {getTotalFooter().dp}
            </td>
            <td></td>
          </tr>

          {/* Total Setelah DP */}
          <tr className='bg-light'>
            <td
              colSpan={10}
              className='py-2 px-1 text-right font-weight-bold align-middle'
            >
              Sisa
            </td>
            <td className='py-2 px-1 text-right font-weight-bold align-middle'>
              {getTotalFooter().totalAfterDP}
            </td>
            <td></td>
          </tr>
        </tfoot>
      </Table>
      {modalPrelim.show && (
        <ModalPrelim
          show={modalPrelim.show}
          onHide={() => setModalPrelim({ show: false })}
          dataPrelim={dataPrelim}
          onSubmit={(val) => {
            setDataPrelim(val)
            setModalPrelim({ show: false })
          }}
        />
      )}
    </>
  )
}

export default TableSection
