import React, { useState, useEffect, useRef } from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DataStatus,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ReadButton,
  StatusModal,
} from "components"
import PrintSalesOrder from "./PrintSalesOrder"
import Axios from "axios"
import { useReactToPrint } from "react-to-print"
import { TransaksiSalesOrderApi } from "api"
import { DateConvert, PageNumber } from "utilities"

// PRINT LAYOUT COMPONENT
const TransaksiSalesOrder = ({ setNavbarTitle }) => {
  const title = "Sales Order"
  const history = useHistory()
  const location = useLocation()

  let registerAlertConfig = location?.state?.registerAlertConfig

  const [isLoading, setIsLoading] = useState(true)
  const [isSearching, setIsSearching] = useState(false)
  const [searchKey, setSearchKey] = useState("")

  const [isUpdateForm, setIsUpdateForm] = useState(false) // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false) // MODAL HAPUS STATE
  const [isViewData, setIsViewData] = useState(false) //MODAL VIEW STATE
  const [isStatusModal, setIsStatusModal] = useState({ show: false, title: " " }) // STATUS MODAL STATE

  const [data, setData] = useState([])
  const [dataCount, setDataCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dataLength, setDataLength] = useState(10)
  const [totalPage, setTotalPage] = useState(1)

  const [updateData, setUpdateData] = useState([])
  const [deleteData, setDeleteData] = useState("")
  const [viewData, setViewData] = useState([])
  const [dataSales, setDataSales] = useState([])
  const [dataCustomer, setDataCustomer] = useState([])
  const [printSO, setPrintSO] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })
  const refPrint = useRef()
  const [dataPrint, setDataPrint] = useState({})
  const reactToPrint = useReactToPrint({ content: () => refPrint.current })
  const handlePrint = (id_sales_order) => {
    TransaksiSalesOrderApi.getSingle(id_sales_order)
      .then((res) => {
        setDataPrint(res.data.data)
      })
      .finally(() => {
        setPrintSO(id_sales_order)
        reactToPrint()
      })
  }

  const getData = () => {
    setIsLoading(true)

    Axios.all([TransaksiSalesOrderApi.getPage({ page, per_page: dataLength, q: searchKey })])
      .then(
        Axios.spread((res, sales, customer) => {
          sortedByDateList(res.data.data)
          setTotalPage(res.data.total_page)
          setDataCount(res.data.data_count)
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false))
  }

  // FUNCTION KONVERSI TANGGAL INPUT
  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const getDate = inputDate.split(" ")
      const date = new Date(getDate[0])
      return `${DateConvert(date).defaultDay}/${DateConvert(date).defaultMonth}/${DateConvert(date).defaultYear}`
    }
    return "-"
  }

  // FUNCTION SORTIR LIST ITEM BAHAN BERDASARKAN TANGGAL TERBARU
  const sortedByDateList = (listItemBahan) => {
    const sorted = listItemBahan.sort(function (a, b) {
      return new Date(b.tgl_input) - new Date(a.tgl_input)
    })
    setData(sorted)
  }

  // FUNCTION CARI DATA DARI SERVER
  const searchData = (e) => {
    e.preventDefault()
    const key = e.target.value // SearchKey Value as key

    setSearchKey(key)
    setPage(1)
    setAlertConfig({ variant: 'primary', text: "Hasil dari pencarian: " + key })
    setShowAlert(key?.length > 0 ? true : false)
  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title)
    getData()

    // SHOW ALERT, IF REGISTER ALERT IS EXIST
    if (registerAlertConfig !== undefined) {
      setAlertConfig(registerAlertConfig)
      setShowAlert(true)
    }

    return () => {
      setIsLoading(false)
      setIsSearching(false)
    }
  }, [page, dataLength, searchKey])

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    console.log(deleteData)
    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false)

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true)

      TransaksiSalesOrderApi.deleteSalesOrder(deleteData.id_sales_order)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false)
          // TAMPIL ALERT
          setShowAlert(true)
          // FETCH DATA DARI SERVER
          getData()
        })
    }

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Nomor Sales Order : {deleteData.no_sales_order}</div>
      </DeleteModal>
    )
  }

  // STATUS MODAL COMPONENT
  const Status = () => {
    const [isLoadingStatusData, setIsLoadingStatusData] = useState(false) //TODO : SET TRUE JIKA SUDAH FIX API
    const [statusData, setStatusData] = useState({})

    useEffect(() => {
      setIsLoadingStatusData(true)
      TransaksiSalesOrderApi.getStatusSalesOrder(isStatusModal.no_sales_order)
        .then((res) => {
          setStatusData(res.data.data)
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message ?? ""})`,
          })
        })
        .finally(() => {
          setIsLoadingStatusData(false)
        })
    }, [])

    // KOMPONEN TABEL STATUS
    const TableStatus = () => {
      return (
        <>
          <CRUDLayout.Table>
            <THead>
              <Tr>
                <ThFixed>No</ThFixed>
                <Th>Nama Proses</Th>
                <Th>Tgl. Proses</Th>
                <ThFixed>No. Dokumen</ThFixed>
              </Tr>
            </THead>
            <TBody>
              <Tr>
                <TdFixed>1</TdFixed>
                <Td>No. Sales Order</Td>
                <TdFixed>{getConvertedDate(statusData.tgl_sales_order)}</TdFixed>
                <TdFixed>{statusData?.no_sales_order ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>2</TdFixed>
                <Td>No. Delivery Order</Td>
                <TdFixed>{getConvertedDate(statusData.tgl_delivery_order)}</TdFixed>
                <TdFixed>{statusData?.no_delivery_order ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>3</TdFixed>
                <Td>No. Faktur Penjualan</Td>
                <TdFixed>{getConvertedDate(statusData.tgl_faktur_penjualan)}</TdFixed>
                <TdFixed>{statusData?.no_faktur_penjualan ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>4</TdFixed>
                <Td>No. Surat Jalan</Td>
                <TdFixed>{getConvertedDate(statusData.tgl_surat_jalan)}</TdFixed>
                <TdFixed>{statusData?.no_surat_jalan ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>5</TdFixed>
                <Td>No. Penerimaan Faktur</Td>
                <TdFixed>{getConvertedDate(statusData.tgl_penerimaan_faktur)}</TdFixed>
                <TdFixed>{statusData?.no_penerimaan_faktur ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>6</TdFixed>
                <Td>No. Pembatalan Faktur</Td>
                <TdFixed>{getConvertedDate(statusData.tgl_pembatalan_faktur)}</TdFixed>
                <TdFixed>{statusData?.no_pembatalan_faktur ?? "-"}</TdFixed>
              </Tr>
              <Tr>
                <TdFixed>7</TdFixed>
                <Td>No. Faktur Piutang</Td>
                <TdFixed>{getConvertedDate(statusData.tgl_faktur_piutang)}</TdFixed>
                <TdFixed>{statusData?.no_faktur_piutang ?? "-"}</TdFixed>
              </Tr>
            </TBody>
          </CRUDLayout.Table>
        </>
      )
    }

    return (
      <StatusModal
        show={isStatusModal.show}
        status={isStatusModal.status}
        onHide={() => setIsStatusModal({ show: false, status: "PROGRESS" })}
      >
        {isLoadingStatusData ? (
          <DataStatus loading={isLoadingStatusData} text="Memuat Data" />
        ) : (
          <TableStatus />
        )}
      </StatusModal>
    )
  }

  // MAIN TABLE COMPONENT
  const Table = () => {
    const getStatusApprovalLabel = (status_approval) => {
      switch (status_approval) {
        case "APP":
          return "APPROVED"
        case "REJ":
          return "REJECT"
        case "REV":
          return "REVISI"
        case "VER":
          return "VERIFIED"
        default:
          return "PENDING"
      }
    }
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Tgl. Sales Order</ThFixed>
              <ThFixed>No. Sales Order</ThFixed>
              <Th>Nama Customer</Th>
              <Th>Nama Sales</Th>
              <ThFixed>Tgl. Batas Waktu </ThFixed>
              <ThFixed>Status Approval</ThFixed>
              {/* <ThFixed>Progress Penjualan</ThFixed> */}
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_sales_order}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex flex-row justify-content-start">
                      <ReadButton
                        onClick={() => {
                          history.push(`/sls/transaksi/sales-order/detail/${val.id_sales_order}`)
                        }}
                      />
                      {val.status_approval == "REV" ? (
                        <UpdateButton
                          onClick={() => {
                            history.push(`/sls/transaksi/sales-order/update/${val.id_sales_order}`)
                          }}
                        />
                      ) : (
                        <div />
                      )}
                      {val.mod_status === "EDITABLE" ? (
                        <DeleteButton
                          onClick={() => {
                            // setDeleteData(val.id_sales_order);
                            setDeleteData(val)
                            setIsDeleteData(true)
                          }}
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                  </TdFixed>
                  <TdFixed>{getConvertedDate(val.tgl_sales_order)}</TdFixed>
                  <TdFixed>{val.no_sales_order}</TdFixed>
                  <Td>{val.nama_customer}</Td>
                  <Td>{val.nama_sales}</Td>
                  <TdFixed>{getConvertedDate(val.batas_waktu)}</TdFixed>
                  <TdFixed>{getStatusApprovalLabel(val.status_approval)}</TdFixed>
                </Tr>
              )
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1)
            setDataLength(e.target.value)
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              placeholder="Cari Sales Order"
              value={searchKey}
              onChange={searchData} />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => history.push("/sls/transaksi/sales-order/tambah")} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          history.replace("/sls/transaksi/sales-order", { registerAlertConfig: undefined })
          setShowAlert(false)
        }}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      {isDeleteData && <HapusModal />}
      {isStatusModal.show && <Status />}
      <div style={{ display: "none" }}>
        <PrintSalesOrder id={printSO} dataPrint={dataPrint} ref={refPrint} />
      </div>
    </CRUDLayout>
  )
}

export default TransaksiSalesOrder
