import {
  useState,
  useMemo
} from 'react'
import { 
  IoCheckmarkOutline,
  IoCloseOutline,
  IoPencilOutline 
} from 'react-icons/io5'
import { 
  ButtonGroup 
} from 'react-bootstrap'
import {
  useFormik
} from 'formik'
import * as Yup from 'yup'
import {
  Table,
  Th, 
  Td,
  ActionButton,
  Input
} from 'components'
import {
  RupiahConvert
} from 'utilities'

const TableListAnalisaBOK = ({dataAnalisaBOK, dataAlatBantuOnSite, dataLabourCostOnSite, setDataAnalisaBOK, getGrandTotal}) => {
  const [isEdit, setIsEdit] = useState(false)
  
  const generateUnitPrice = () => {
    const data = getGrandTotal()

    const totalBahanBaku = data.filter(item => item.kode === "hw" || item.kode === "pw")
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0)

    const totalPenunjangProduksi = data.filter(item => item.kode === "fs" || item.kode === "lc" || item.kode === "mp" || item.kode === "bop")
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0)

    const totalFinishing = data.filter(item => item.kode === "fin")
      .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0)

    const totalAlatBantu = () => {
      const getQty = dataAlatBantuOnSite?.qty ? parseFloat(dataAlatBantuOnSite?.qty) : 0
      const getKonst = dataAlatBantuOnSite?.konstanta ? parseFloat(dataAlatBantuOnSite?.konstanta) : 0
      const getPrice = dataAlatBantuOnSite?.unit_price ? parseFloat(dataAlatBantuOnSite?.unit_price) : 0
  
      return parseInt(getQty * getKonst * getPrice)
    }

    const totalLabourCost = () => {
      const subTotal = dataLabourCostOnSite.reduce((prev, current) => {
        const checkQty = current.qty ? parseFloat(current.qty) : 0
        const checkPrice = current.unit_price ? parseFloat(current.unit_price) : 0
        const checkKonst = current.konstanta ? parseFloat(current.konstanta) : 0
        const total = parseInt(checkQty * checkPrice * checkKonst)
  
        return prev + total
      }, 0)
  
      return subTotal
    }

    return totalBahanBaku + totalPenunjangProduksi + totalFinishing + totalAlatBantu() + totalLabourCost()
  }

  const generateTotal = (qty, konst, price) => {
    const getQty = qty ? parseFloat(qty) : 0
    const getKonst = konst ? parseFloat(konst) : 0
    const getPrice = price ? parseFloat(price) : 0

    return parseInt(getQty * getKonst * getPrice)
  }

  const checkInitialPrice = () => {
    if (dataAnalisaBOK[0]?.unit_price) {
      return dataAnalisaBOK[0]?.unit_price ?? 0
    }

    return generateUnitPrice() ?? 0
  }
  
  const FormTable = () => {
    const formInitialValues = {
      qty: dataAnalisaBOK[0]?.qty ?? 0,
      unit_price: checkInitialPrice(),
      konstanta: dataAnalisaBOK[0]?.konstanta ?? 0.07
    }
  
    const formValidationSchema = Yup.object().shape({
      qty: Yup.string().required(),
      unit_price: Yup.string().required(),
      konstanta: Yup.string().required(),
    })
  
    const formSubmitHandler = values => {
      setDataAnalisaBOK([{
        ...dataAnalisaBOK[0],
        ...values
      }])
      setIsEdit(false)
    }

    const {values, errors, touched, setFieldValue, handleSubmit} = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler
    })

    return (
      <tr>
        <Td textCenter>1</Td>
        <Td width={90}>50.000019</Td>
        <Td width={400}>Alat Bantu On-Site</Td>
        <Td width={100}>
          <Input 
            noMargin
            value={values.qty}
            onChange={e => setFieldValue('qty', e.target.value)}
            error={Boolean(errors.unit_price && touched.unit_price)}
          />
        </Td>
        <Td>Lumpsum</Td>
        <Td width={200}> 
          <Input 
            noMargin
            value={RupiahConvert(String(values.unit_price)).withoutLabel}
            onChange={e => {
              const newValue = RupiahConvert(String(e.target.value.length > 0 ? e.target.value : 0)).default
              setFieldValue('unit_price', newValue)
            }}
            error={Boolean(errors.unit_price && touched.unit_price)}
          />
        </Td>
        <Td width={100}>
          <Input 
            noMargin
            value={values.konstanta}
            onChange={e => setFieldValue('konstanta', e.target.value)}
            error={Boolean(errors.unit_price && touched.unit_price)}
          />
        </Td>
        <Td textRight>{RupiahConvert(String(generateTotal(values?.qty, values?.unit_price, values?.konstanta))).withoutLabel}</Td>
      </tr>
    )
  }

  return (
    <div>
      <div className="p-1">
        <b>Biaya Overhead Kantor</b>
      </div>
      <Table>
        <thead className="bg-light">
          <tr>
            <Th width={20}>No</Th>
            <Th width={90}>Kode Item</Th>
            <Th width={400}>Item Overhead</Th>
            <Th>Qty</Th>
            <Th>Unit</Th>
            <Th>Unit Price (Rp)</Th>
            <Th>Konst.</Th>
            <Th>Sub Total Price (Rp)</Th>
          </tr>
        </thead>
        <tbody>
          {isEdit
            ? <FormTable />
            : <tr>
                <Td textCenter>1</Td>
                <Td>{dataAnalisaBOK[0]?.kode_item}</Td>
                <Td>{dataAnalisaBOK[0]?.nama_item}</Td>
                <Td textRight>{dataAnalisaBOK[0]?.qty ? parseFloat(dataAnalisaBOK[0]?.qty) : 0}</Td>
                <Td>{dataAnalisaBOK[0]?.nama_satuan}</Td>
                <Td textRight>{RupiahConvert(String(checkInitialPrice() ?? 0)).withoutLabel}</Td>
                <Td textRight>{dataAnalisaBOK[0]?.konstanta ? parseFloat(dataAnalisaBOK[0]?.konstanta).toPrecision() : 0.03}</Td>
                <Td textRight>{RupiahConvert(String(generateTotal(dataAnalisaBOK[0]?.qty, dataAnalisaBOK[0]?.unit_price, dataAnalisaBOK[0]?.konstanta))).withoutLabel}</Td>
              </tr>
          }
        </tbody>
      </Table>
    </div>
  )
}

export default TableListAnalisaBOK
