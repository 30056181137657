import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Modal, Card, Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Input,
  ActionButton,
  CreateButton,
  DataStatus,
  CreateModalLarge,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Alert,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  BackButton,
  TextArea,
  SelectSearch,
  Approval,
} from "../../../components";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { RegisterSalesOrderApi, TransaksiSalesOrderApi } from "../../../api";
import { RupiahConvert } from "../../../utilities";
import banks from "../../../assets/data/bank.json";
import provinsi from "../../../assets/data/provinsi.json";

const UpdateSalesOrder = ({ setNavbarTitle }) => {
  //  USE HISTORY
  const history = useHistory();
  // GET ID SALES ORDER FROM PARAMS
  let { id_sales_order } = useParams();

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // STATE MODAL TAMBAH CUSTOMER
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE
  const [isSearchingItemModal, setIsSearchingItemModal] = useState(false); // STATE MODAL PENCARIAN ITEM BARANG

  // STATE UNTUK MENYIMPAN ID ITEM BUASO YANG AKAN DICARI STOK BARANGNYA
  const [idItemBuaso, setIdItemBuaso] = useState();
  // STATE UNTUK MENYIMPAN ITEM BARANG YANG DI ORDER
  const [orderItems, setOrderItems] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState({});
  // STATE MENAMPUNG DATA CUSTOMER
  const [dataCustomer, setDataCustomer] = useState([]);
  // STATE MENAMPUNG DATA KARYAWAN / SALES
  const [dataKaryawan, setDataKaryawan] = useState([]);
  // STATE MENAMPUNG NO SALES ORDER
  const [noSalesOrder, setNoSalesOrder] = useState("");
  const [dataSalesOrder, setDataSalesOrder] = useState({});
  // MENYIMPAN DATA DROPDOWN ITEM BARANG
  const [dataItemBarang, setDataItemBarang] = useState([]);
  const [dataProyek, setDataProyek] = useState([]);
  const [kelompok, setKelompok] = useState([]);

  // MENYIMPAN DATA DETAIL CUSTOMER
  const [customerDetail, setCustomerDetail] = useState("");
  // MENYIMPAN DATA INPUT DISKON
  const [inputDiskon, setDiskon] = useState();
  // MENYIMPAN DATA INPUT PPN
  const [inputPpn, setPpn] = useState(0);
  // MENYIMPAN DETAIL DATA ORDER
  const [totalAkhir, setTotalAkhir] = useState({
    total: 0,
    jmlDiskon: 0,
    jmlPpn: 0,
    totalSetelahDiskon: 0,
    totalSetelahPpn: 0,
  });

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [selectItemConfig, setSelectItemConfig] = useState({ isDisabled: true, isLoading: false });

  // GENERATE CUSTOMER DATA DETAIL
  const getCustomerDetail = (id_customer) => {
    if (id_customer) {
      const detail = dataCustomer.find((e) => e.id_customer === id_customer);
      const text = `Nama : ${detail.nama_customer},\nTelepon : ${detail.telepon},\nEmail : ${detail.email}`;
      setCustomerDetail(text);
    }
  };

  // GET CUSTOMER ALAMAT
  const getCustomerAlamat = (id_customer) => {
    if (id_customer) {
      const detail = dataCustomer.find((e) => e.id_customer === id_customer);
      return detail?.alamat ?? "";
    }
  };

  // FUNCTION GET DATA LIST CUSTOMER
  const getDataCustomers = () => {
    RegisterSalesOrderApi.getCustomer()
      .then((customers) => {
        setDataCustomer(customers?.data?.data ?? []);
      })
      .catch((err) => alert(err?.response?.data?.message ?? "Tidak dapat memuat data customer"));
  };

  const getDataItem = (id) => {
    setSelectItemConfig({ ...selectItemConfig, isLoading: true });
    RegisterSalesOrderApi.getItemBarang({ id_kelompok: id })
      .then((barang) => {
        setDataItemBarang(barang.data.data);
      })
      .catch(() => alert("Item Barang Gagal Dimuat !!"))
      .finally(() => {
        setSelectItemConfig({ isDisabled: false, isLoading: false });
      });
  };

  console.log(dataItemBarang);

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    getDataCustomers(); // GET DATA CUSTOMERS LIST

    Axios.all([
      TransaksiSalesOrderApi.getSingle(id_sales_order),
      RegisterSalesOrderApi.getSales(),
      RegisterSalesOrderApi.getKelompok(),
      RegisterSalesOrderApi.getProyek(),
    ])
      .then(
        Axios.spread((data_so, sales, kelompok, pry) => {
          setDataSalesOrder(data_so.data.data);
          setDiskon(data_so.data.data.diskon);
          setPpn(data_so.data.data.ppn);
          setDataKaryawan(sales.data.data);
          setKelompok(kelompok.data.data);
          setOrderItems(data_so.data.data.detail);
          setDataProyek(pry.data.data);
        })
      )
      // .then(setDataItemBarang(dataSalesOrder.detail))
      .catch((err) => alert(err))
      .finally(() => {
        getCustomerDetail(dataSalesOrder.id_customer);
        setIsLoading(false);
      });
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle("Update Sales Order"); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
  }, []);

  // HITUNG TOTAL KETIKA UPDATE STATE ORDER, DISKON, PPN
  useEffect(() => {
    hitungTotal();
  }, [orderItems, inputDiskon, inputPpn]);

  // MODAL MENAMPILKAN STOK BARANG DARI ITEM YANG DIPILIH
  const SearchItemsModal = () => {
    // LOADING STATE KETIKA FETCH DATA ITEM YANG DI SEARCH
    const [isLoadingSearchData, setIsLoadingSearchData] = useState(true);

    // MENYIMPAN HASIL FETCH DATA ITEM YANG DIPILIH
    const [searchItems, setSearchItems] = useState([]);

    // MENYIMPAN HASIL FILTER ITEM BARANG DARI LIST searchItems YANG TIDAK ADA DI dataSalesOrder.detail
    let filterItemBarang = [];

    // FUNCTION UNTUK MENCARI SELURUH DATA ITEM BERDASARKAN ID ITEM BUASO
    const searchSelectedItem = () => {
      RegisterSalesOrderApi.getStokItemBarang(idItemBuaso)
        .then((res) => {
          setSearchItems(res.data.data);
        })
        .finally(() => setIsLoadingSearchData(false));
    };

    useEffect(() => {
      setIsLoadingSearchData(true);
      searchSelectedItem();
    }, [idItemBuaso]);

    // KOMPONEN LIST TABEL ITEM BARANG YANG DICARI
    const TableSearchedItems = () => {
      // SEMBUNYIKAN DATA ITEM BARANG APABILA SUDAH ADA PADA dataSalesOrder.detail
      filterItemBarang = searchItems.filter((item) =>
        dataSalesOrder.detail.every(
          (item2) => !(item2.id_barang == item.id_barang && item2.id_gudang == item.id_gudang)
        )
      );
      return (
        <>
          <Modal.Body>
            <CRUDLayout.Table>
              <THead>
                <Tr>
                  <ThFixed>No</ThFixed>
                  <ThFixed>Kode Item</ThFixed>
                  <Th>Nama Item</Th>
                  <Th>Gudang</Th>
                  <Th>Stok</Th>
                  <Th>Satuan</Th>
                  <Th>Harga Satuan</Th>
                  <ThFixed>Quantity Order</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {filterItemBarang?.map((val, index) => {
                  return (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.kode_barang}</TdFixed>
                      <Td>{val.nama_barang}</Td>
                      <Td>{val.nama_gudang}</Td>
                      <Td textRight>{val.stok}</Td>
                      <Td>{val.nama_satuan}</Td>
                      <Td textRight>
                        {val.harga_terbaru ? RupiahConvert(val.harga_terbaru).detail : "Rp. 0"}
                      </Td>
                      <Td>
                        <input
                          className="form-control form-control-sm"
                          style={{ minWidth: "8rem" }}
                          placeholder="0"
                          type="number"
                          name={`jml_${index}`}
                          max={val.stok}
                          min={0}
                          onChange={(e) => {
                            if (e.target.value > 0 && e.target.value <= parseInt(val.stok)) {
                              filterItemBarang[index].qty_item = e.target.value;
                              filterItemBarang[index].harga_satuan_jual = val.harga_terbaru ?? 0;
                              filterItemBarang[index].diskon_item = 0;
                              filterItemBarang[index].sub_total = String(
                                (val?.harga_terbaru ?? 0) * e.target.value
                              );
                            } else if (e.target.value > parseInt(val.stok)) {
                              e.target.value = parseInt(val.stok);
                              filterItemBarang[index].qty_item = val.stok;
                              filterItemBarang[index].harga_satuan_jual = val.harga_terbaru ?? 0;
                              filterItemBarang[index].diskon_item = 0;
                              filterItemBarang[index].sub_total = String(
                                (val?.harga_terbaru ?? 0) * e.target.value
                              );
                            } else {
                              filterItemBarang[index].qty_item = 0;
                            }
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </TBody>
            </CRUDLayout.Table>
          </Modal.Body>
          <Modal.Footer>
            <CreateButton
              onClick={() => {
                const order = filterItemBarang.filter(
                  (e) => e.hasOwnProperty("qty_item") && e.qty_item > 0
                );

                let temp = [...dataSalesOrder.detail];

                // FUNCTION CEK JIKA ITEM ORDER BARU ADA PADA ORDER ITEM YANG LAMA
                const upsert = (obj) => {
                  temp.forEach((or, orIndex) => {
                    if (or.id_barang === obj.id_barang && or.id_gudang === obj.id_gudang) {
                      // HAPUS ITEM ORDER YANG LAMA
                      temp.splice(orIndex, 1);
                    }
                  });
                  setOrderItems([...temp]);
                };

                order.forEach((obj) => upsert(obj));

                setDataSalesOrder({
                  ...dataSalesOrder,
                  detail: [...temp, ...order],
                });
                setIsSearchingItemModal(false);
              }}
            />
          </Modal.Footer>
        </>
      );
    };

    return (
      <CreateModalLarge
        title="Pencarian Item Barang"
        show={isSearchingItemModal}
        onHide={() => setIsSearchingItemModal(false)}
      >
        {isLoadingSearchData ? (
          <DataStatus loading={isLoadingSearchData} text="Memuat Data" />
        ) : !Array.isArray(searchItems) ? (
          <DataStatus text="Tidak dapat memuat data" />
        ) : searchItems.length > 0 ? (
          <TableSearchedItems />
        ) : (
          <DataStatus text="Data stok barang tidak ditemukan" />
        )}
      </CreateModalLarge>
    );
  };

  // MODAL UPDATE ORDER ITEMS DETAIL
  const UbahModal = () => {
    // INITIAL VARIABLE ID BARANG;
    let id_barang = "";
    let stok_barang = [];
    let limit_stok = 0;

    // FUNGSI GET STOK ITEM BARANG YANG DIUPDATE
    const getStokItemBarang = (id_barang) => {
      RegisterSalesOrderApi.getStokItemBarang(id_barang)
        .then((res) => {
          stok_barang = res.data.data;
        })
        .finally(() => {
          limit_stok = stok_barang.find((e) => e.id_gudang == updateData.id_gudang)?.stok;
        });
    };

    useEffect(() => {
      id_barang = updateData.id_barang;
      getStokItemBarang(id_barang);

      return () => {
        id_barang = "";
        stok_barang = [];
        limit_stok = 0;
      };
    }, [id_barang]);

    const formInitialValues = {
      kode_barang: updateData.kode_barang,
      nama_barang: updateData.nama_barang,
      nama_satuan: updateData.nama_satuan,
      nama_gudang: updateData.nama_gudang,
      qty_item: parseInt(updateData.qty_item),
    };

    const updateFormValidationSchema = Yup.object().shape({
      qty_item: Yup.number().required("Masukkan Qty"),
    });

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandlers = (values) => {
      const finalValues = {
        id_sales_order_detail: updateData.id_sales_order_detail,
        qty_item: values.qty_item,
      };
      TransaksiSalesOrderApi.updateOrderItem(finalValues)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Ubah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <UpdateModal
        show={isUpdateForm}
        onHide={() => setIsUpdateForm(false)}
        title="Item Sales Order"
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={updateFormValidationSchema}
          onSubmit={formSubmitHandlers}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Kode Barang"
                  type="text"
                  name="kode_barang"
                  placeholder="Kode Item"
                  value={values.kode_barang}
                  readOnly={true}
                />
                <Input
                  label="Nama Barang"
                  type="text"
                  name="nama_barang"
                  value={values.nama_barang}
                  readOnly={true}
                />

                <Input
                  label="Satuan"
                  type="text"
                  name="nama_satuan"
                  value={values.nama_satuan}
                  readOnly={true}
                />

                <Input
                  label="Nama Gudang"
                  type="text"
                  name="nama_gudang"
                  value={values.nama_gudang}
                  readOnly={true}
                />

                <Input
                  label="Qty"
                  type="number"
                  name="qty_item"
                  value={values.qty_item}
                  max={limit_stok}
                  onChange={(e) => {
                    let { value } = e.target;
                    if (value > 0 && value <= parseInt(limit_stok)) {
                      values.qty_item = value;
                      setFieldValue("qty_item", value);
                    } else if (value > parseInt(limit_stok)) {
                      e.target.value = parseInt(limit_stok);
                      values.qty_item = value;
                      setFieldValue("qty_item", e.target.value);
                    } else {
                      setFieldValue("qty_item", "");
                    }
                  }}
                  error={errors.qty_item && touched.qty_item && true}
                  errorText={errors.qty_item}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL HAPUS ORDER ITEMS COMPONENT
  const HapusModal = () => {
    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);
      TransaksiSalesOrderApi.deleteOrderItem(deleteData.id_sales_order_detail)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal!(${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        title="Item Sales Order"
        onConfirm={deleteDataHandler}
      >
        <div>Nama Barang : {deleteData.nama_barang}</div>
        <div>Nama Gudang : {deleteData.nama_gudang}</div>
      </DeleteModal>
    );
  };
  const FormCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Sales Order</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataSalesOrder.stakeholder.map(
              (val, index) =>
                index !== 0 &&
                val.status_approval !== "PEN" && (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === "VER" || val.status_approval === "REV"
                          ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                          : val.status_approval === "APP"
                          ? "Pengesah"
                          : "Di Tolak Oleh"
                      }
                      value1={val.nama_karyawan ?? "-"}
                      title2="Catatan"
                      value2={val.catatan ?? "-"}
                    />
                  </Col>
                )
            )}
          </Row>
        </Card.Body>
      </Card>
    );
  };
  // GET TOTAL ORDER
  const getTotal = () => {
    let total = dataSalesOrder.detail?.reduce((sum, { sub_total }) => sum + parseInt(sub_total), 0);
    return parseInt(total);
  };
  // GET TOTAL DISKON
  const getJumlahDiskon = () => {
    let total = getTotal();
    return String(parseInt((total * inputDiskon ?? 0) / 100));
  };
  // GET TOTAL SETELAH DISKON
  const getTotalSetelahDiskon = () => {
    let total = getTotal();
    let jmlDiskon = getJumlahDiskon();
    return String(parseInt(total - jmlDiskon));
  };
  // GET TOTAL PPN
  const getJumlahPpn = () => {
    let totalSetelahDiskon = getTotalSetelahDiskon();
    return String(parseInt((totalSetelahDiskon * inputPpn) / 100));
  };
  // GET TOTAL SETELAH PPN
  const getTotalSetelahPpn = () => {
    let totalSetelahDiskon = parseInt(getTotalSetelahDiskon());
    let jumlahPpn = parseInt(getJumlahPpn());
    return String(parseInt(totalSetelahDiskon + jumlahPpn));
  };

  const hitungTotal = () => {
    let total = 0;
    let jmlDiskon = 0;
    let jmlPpn = 0;
    let totalSetelahDiskon = 0;
    let totalSetelahPpn = 0;

    total = orderItems.reduce((sum, { sub_total }) => sum + parseInt(sub_total), 0);
    jmlDiskon = (total * inputDiskon ?? 0) / 100;
    totalSetelahDiskon = total - jmlDiskon;
    jmlPpn = (totalSetelahDiskon * inputPpn ?? 0) / 100;
    totalSetelahPpn = totalSetelahDiskon + jmlPpn;

    setTotalAkhir({
      total,
      jmlDiskon,
      jmlPpn,
      totalSetelahDiskon,
      totalSetelahPpn,
    });
  };

  // INITIAL VALUES FORM TAMBAH DATA
  const formInitialValuesUpdate = {
    no_sales_order: dataSalesOrder.no_sales_order,
    no_purchase_order: dataSalesOrder.no_purchase_order,
    id_karyawan: dataSalesOrder.id_sales,
    id_customer: dataSalesOrder.id_customer,
    alamat_pengiriman: dataSalesOrder.alamat_pengiriman,
    catatan: dataSalesOrder.catatan,
    tgl_purchase_order: dataSalesOrder.tgl_purchase_order,
    tgl_sales_order: dataSalesOrder.tgl_sales_order,
    batas_waktu: dataSalesOrder.batas_waktu,
    id_proyek: dataSalesOrder.id_proyek,
  };

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    id_karyawan: Yup.string().required("Pilih Sales"),
    id_customer: Yup.string().required("Pilih Customer"),
    alamat_pengiriman: Yup.string().required("Masukkan Alamat Customer"),
    id_proyek: Yup.string().required("Pilih Proyek"),
  });

  // KIRIM UPDATE DATA KE SERVER
  const formSubmitHandlerUpdate = (values, { setSubmitting }) => {
    const finalValues = {
      id_sales_order: dataSalesOrder.id_sales_order,
      diskon: inputDiskon,
      ppn: inputPpn,
      detail: dataSalesOrder.detail,
      ...values,
    };
    // SEND UPDATED DATA TO SERVER
    TransaksiSalesOrderApi.updateSalesOrder(finalValues)
      .then((res) => {
        history.push("/sls/transaksi/sales-order", {
          registerAlertConfig: {
            variant: "primary",
            text: "Ubah data berhasil!",
          },
        });
      })
      .catch((err) => {
        // ERROR ALERT
        setAlertConfig({
          variant: "danger",
          text: `Ubah data gagal! (${err.response.data.message})`,
        });
        setSubmitting(false);
      })
      .finally(() => {
        // CLOSE UPDATE MODAL FORM
        // setIsUpdateForm(false);
        // SHOW ALERT
        setShowAlert(true);
        // FETCH DATA DARI SERVER
        setSubmitting(false);
        // getData();
        // history.goBack();
      });
  };

  return (
    // MAIN CRUD LAYOUT
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : (
        <Card className="pb-3 mt-3">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>{`Update Sales Order}`}</b>
            <BackButton size="sm" onClick={() => history.goBack()} />
          </Card.Header>
          <Card.Body>
            <Formik
              initialValues={formInitialValuesUpdate}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandlerUpdate}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3 col-sm-12">
                      {/* <Input
                        label="Tanggal Purchase Order"
                        type="date"
                        name="tgl_purchase_order"
                        value={values.tgl_purchase_order}
                        onChange={handleChange}
                      /> */}
                      <Input
                        label="Tanggal Sales Order"
                        type="date"
                        name="tgl_sales_order"
                        value={values.tgl_sales_order}
                        onChange={handleChange}
                      />
                      <Input
                        label="Batas Waktu Pengiriman"
                        type="date"
                        name="batas_waktu"
                        value={values.batas_waktu}
                        onChange={handleChange}
                      />

                      <SelectSearch
                        label="Sales"
                        type="text"
                        name="id_proyek"
                        defaultValue={{
                          label: dataSalesOrder?.nama_proyek
                            ? dataSalesOrder.nama_proyek
                            : "Masukkan Sales",
                          value: values.id_proyek,
                        }}
                        onChange={(val) => setFieldValue("id_proyek", val.value)}
                        option={dataProyek.map((val) => {
                          return {
                            value: val.id_proyek,
                            label: val.nama_proyek,
                          };
                        })}
                        error={errors.id_proyek && touched.id_proyek && true}
                        errorText={errors.id_proyek}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      {/* <Input
                        label="No. Purchase Order"
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        name="no_purchase_order"
                        value={values.no_purchase_order}
                        onChange={handleChange}
                        error={errors.no_purchase_order && touched.no_purchase_order && true}
                        errorText={errors.no_purchase_order}
                      /> */}

                      <Input
                        label="No. Sales Order"
                        type="text"
                        name="no_sales_order"
                        readOnly={true}
                        value={values.no_sales_order}
                        onChange={handleChange}
                      />

                      <SelectSearch
                        label="Sales"
                        type="text"
                        name="id_karyawan"
                        defaultValue={{
                          label: dataSalesOrder?.nama_sales
                            ? dataSalesOrder.nama_sales
                            : "Masukkan Sales",
                          value: values.id_karyawan,
                        }}
                        onChange={(val) => setFieldValue("id_karyawan", val.value)}
                        option={dataKaryawan.map((val) => {
                          return {
                            value: val.id_karyawan,
                            label: val.nama_karyawan,
                          };
                        })}
                        error={errors.id_karyawan && touched.id_karyawan && true}
                        errorText={errors.id_karyawan}
                      />

                      <div className="row">
                        <div className="col-md-12">
                          <SelectSearch
                            label="Customer"
                            type="text"
                            name="id_customer"
                            defaultValue={{
                              value: values.id_customer,
                              label: dataSalesOrder.nama_customer
                                ? dataSalesOrder.nama_customer
                                : "Masukan Nama Customer",
                            }}
                            onChange={(val) => {
                              setFieldValue("id_customer", val.value);
                              getCustomerDetail(val.value);
                              setFieldValue("alamat_pengiriman", getCustomerAlamat(val.value));
                            }}
                            option={dataCustomer.map((val) => {
                              return {
                                value: val.id_customer,
                                label: val.nama_customer,
                              };
                            })}
                            error={errors.id_customer && touched.id_customer && true}
                            errorText={errors.id_customer}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5 col-sm-12">
                      <TextArea
                        label="Alamat Pengiriman"
                        placeholder="Masukkan Alamat Pengiriman"
                        name="alamat_pengiriman"
                        value={values.alamat_pengiriman}
                        onChange={handleChange}
                        rows={3}
                        error={errors.alamat_pengiriman && touched.alamat_pengiriman && true}
                        errorText={errors.alamat_pengiriman}
                      />

                      <TextArea
                        label="Catatan"
                        placeholder="Masukkan Catatan"
                        name="catatan"
                        value={values.catatan}
                        onChange={handleChange}
                        rows={3}
                      />
                    </div>
                  </div>

                  <hr className="mb-3" />

                  {/* CARI ITEM BARANG */}
                  <div className=" row">
                    <div className="col-md-4">
                      <SelectSearch
                        label="Pilih Kelompok"
                        name="id_kelompok"
                        placeholder="Pilih kelompok"
                        onChange={(val) => {
                          getDataItem(val.value);
                        }}
                        option={kelompok.map((val) => {
                          return {
                            value: val.id_kelompok,
                            label: val.nama_kelompok,
                          };
                        })} //option harus berupa value dan label
                        defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                      />
                    </div>
                    <div className="col-md-4">
                      <SelectSearch
                        label="Cari Item Barang Jadi"
                        name="id_barang"
                        placeholder={
                          selectItemConfig.isDisabled
                            ? "Pilih Kelompok Terlebih Dahulu"
                            : "Pilih Item Barang Jadi"
                        }
                        onChange={(val) => {
                          setIdItemBuaso(val.value);
                          setIsSearchingItemModal(true);
                        }}
                        option={dataItemBarang.map((val) => {
                          return {
                            value: val.id_item_buaso,
                            label: val.nama_item,
                          };
                        })}
                        defaultValue=""
                        isDisabled={selectItemConfig.isDisabled}
                        loading={selectItemConfig.isLoading}
                      />
                    </div>
                  </div>
                  {/* END SECTION CARI ITEM BARANG */}

                  {!Array.isArray(dataSalesOrder.detail) ? (
                    <DataStatus text="Tidak dapat memuat data" />
                  ) : dataSalesOrder.detail.length > 0 ? (
                    // <Table inputDiskon={inputDiskon} setDiskon={setDiskon} />
                    <>
                      <CRUDLayout.Table>
                        <THead>
                          <Tr className="text-center">
                            <ThFixed>No</ThFixed>
                            <ThFixed>Aksi</ThFixed>
                            <ThFixed>Kode Barang</ThFixed>
                            <Th>Nama Barang</Th>
                            <Th>Gudang</Th>
                            <Th>Qty SO</Th>
                            <Th>Satuan</Th>
                            <Th>Harga Satuan</Th>
                            <Th>Jumlah</Th>
                          </Tr>
                        </THead>
                        <TBody>
                          {dataSalesOrder.detail?.map((order, index) => {
                            // let sub_total = order.qty_item * order.harga_satuan_jual
                            order.sub_total =
                              order.qty_item * (order?.harga_satuan_jual ?? order.harga_terbaru);
                            return (
                              <Tr key={index}>
                                <TdFixed>{index + 1}</TdFixed>
                                <TdFixed>
                                  <div className="d-flex justify-content-center">
                                    <DeleteButton
                                      onClick={() => {
                                        // HAPUS ITEM DENGAN FILTER
                                        const filter = dataSalesOrder.detail.filter(
                                          (e, itemIndex) => itemIndex !== index
                                        );
                                        setDataSalesOrder({
                                          ...dataSalesOrder,
                                          detail: filter,
                                        });

                                        // setDeleteData(order);
                                        // setIsDeleteData(true);
                                        // console.log(deleteData)
                                        // TransaksiSalesOrderApi.deleteOrderItem(order.id_sales_order_detail);
                                        // let tempOrder = dataSalesOrder.detail.filter((e, e_index) => e_index !== index);
                                        // setOrderItems(tempOrder);
                                      }}
                                    />
                                  </div>
                                </TdFixed>
                                <TdFixed>{order.kode_barang}</TdFixed>
                                <Td>{order.nama_barang}</Td>
                                <Td>{order.nama_gudang}</Td>
                                <Td textRight>{parseInt(order.qty_item)}</Td>
                                <Td>{order.nama_satuan}</Td>
                                <Td textRight>
                                  {
                                    RupiahConvert(
                                      parseInt(order?.harga_satuan_jual ?? 0).toString()
                                    ).detail
                                  }
                                </Td>
                                <Td textRight>
                                  {RupiahConvert(String(parseInt(order.sub_total ?? 0))).detail}
                                </Td>
                              </Tr>
                            );
                          })}
                          {dataSalesOrder.detail.length > 0 ? (
                            <>
                              <Tr>
                                <Td colSpan={7} textRight className="py-2 align-middle">
                                  <strong>Total</strong>
                                </Td>
                                <Td />
                                <Td textRight className="align-middle">
                                  <strong>{RupiahConvert(String(getTotal())).detail}</strong>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td colSpan={7} textRight className="align-middle">
                                  <strong>Diskon</strong>
                                </Td>
                                <Td>
                                  <div className="d-flex justify-content-end">
                                    <input
                                      className="form-control form-control-sm"
                                      style={{ maxWidth: "6rem" }}
                                      placeholder="0 %"
                                      type="number"
                                      name="diskon"
                                      value={parseInt(inputDiskon)}
                                      max={99}
                                      min={0}
                                      onChange={(e) => {
                                        let { value } = e.target;
                                        if (e.target.value > 0 && e.target.value <= 99) {
                                          setDiskon(value);
                                        } else if (e.target.value > 99) {
                                          setDiskon(99);
                                          e.target.value = 99;
                                        } else {
                                          setDiskon(0);
                                        }
                                      }}
                                    />
                                  </div>
                                </Td>
                                <Td textRight className="align-middle">
                                  <strong className="text-nowrap">
                                    {RupiahConvert(getJumlahDiskon()).detail}
                                  </strong>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td colSpan={7} textRight className="align-middle">
                                  <strong className="text-nowrap">Total Setelah Diskon</strong>
                                </Td>
                                <Td />
                                <Td textRight className="py-2 align-middle">
                                  <strong className="text-nowrap">
                                    {RupiahConvert(getTotalSetelahDiskon()).detail}
                                  </strong>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td colSpan={7} textRight className="align-middle">
                                  <strong>PPN</strong>
                                </Td>
                                <Td>
                                  <div className="d-flex justify-content-end">
                                    <input
                                      className="form-control form-control-sm"
                                      style={{ maxWidth: "6rem" }}
                                      placeholder="0 %"
                                      type="number"
                                      name="ppn"
                                      value={parseInt(inputPpn)}
                                      max={99}
                                      min={0}
                                      onChange={(e) => {
                                        let { value } = e.target;
                                        if (e.target.value > 0 && e.target.value <= 99) {
                                          setPpn(value);
                                        } else if (e.target.value > 99) {
                                          setPpn(99);
                                          e.target.value = 99;
                                        } else {
                                          setPpn(0);
                                        }
                                      }}
                                    />
                                  </div>
                                </Td>
                                <Td textRight className="align-middle">
                                  <strong className="text-nowrap">
                                    {RupiahConvert(getJumlahPpn()).detail}
                                  </strong>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td colSpan={7} textRight className="py-2 align-middle">
                                  <strong>Total Setelah PPn</strong>
                                </Td>
                                <Td />
                                <Td textRight className="align-middle">
                                  <strong className="text-nowrap">
                                    {RupiahConvert(getTotalSetelahPpn()).detail}
                                  </strong>
                                </Td>
                              </Tr>
                            </>
                          ) : (
                            <div />
                          )}
                        </TBody>
                      </CRUDLayout.Table>
                      <Approval title="Sales Order" data={dataSalesOrder} />
                    </>
                  ) : (
                    <DataStatus text="Daftar item barang kosong" />
                  )}

                  <div className="d-flex justify-content-end">
                    <ActionButton
                      type="submit"
                      variant="success"
                      text="Simpan"
                      className="mt-2 px-4"
                      loading={isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      )}

      {/* MODAL */}
      {/* {isUpdateForm && <UbahModal />} */}
      {isDeleteData && <HapusModal />}
      {isSearchingItemModal && <SearchItemsModal />}
    </CRUDLayout>
  );
};

export default UpdateSalesOrder;
