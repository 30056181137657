import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { IoAdd, IoAddOutline } from 'react-icons/io5';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
} from "components";
import ModalDetailSPK from "./ModalDetailSPK";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { SalesOrderSPKApi } from "api";

const ListSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([{}]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({ show: false, data: {} })

  const getInitialData = () => {
    setIsPageLoading(true);

    SalesOrderSPKApi.getListSPK({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setData(data?.data ?? []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("LIST SPK YANG SIAP DIBUATKAN SALES ORDER SPK");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);


  const PageContent = () => {
    const BarangJadiCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);
      const getKodeSatuan = (brg) => {
        const qty = parseFloat(brg.qty_rab ?? 0)

        if (brg?.nama_satuan?.toLowerCase() === 'kilometer') return `x ${qty} km`
        return brg?.nama_satuan ? `x ${qty} ${brg?.nama_satuan}` : ''
      }

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data?.map((brg, index) => {
              if (isReadMoreClick) {
                return <li key={index} index={index}>{`${brg.nama_item} ${getKodeSatuan(brg)}`}</li>;
              } else {
                return index < 2 && <li key={index} index={index}>{`${brg.nama_item} ${getKodeSatuan(brg)}`}</li>;
              }
            })}
          </ul>
          {data?.length > 2 &&
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>}
        </>
      )
    }

    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Tgl. SPK</ThFixed>
            <ThFixed>No. SPK</ThFixed>
            <ThFixed>No. Penawaran</ThFixed>
            <Th>Customer</Th>
            <Th>Proyek</Th>
            <Th>Item Barang Jadi</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data?.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}
              </TdFixed>
              <Td className="text-center">{DateConvert(new Date(val.tgl_spk)).defaultDMY}</Td>
              <Td className="text-center">{val.no_spk}</Td>
              <Td className="text-center">{val.no_penawaran}</Td>
              <Td>{val.nama_customer ?? "-"}</Td>
              <Td>{val.nama_proyek ?? "-"}</Td>
              <Td>
                {val.barang_jadi && val.barang_jadi?.length > 0
                  ? <BarangJadiCollapse data={val.barang_jadi} />
                  : "-"
                }
              </Td>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  {/* <ReadButton size='sm' className='my-1 mr-1' onClick={() => setModalConfig({ show: true, data: val })} /> */}
                  <ActionButton
                    size='sm'
                    variant='success'
                    className='my-1'
                    onClick={() => history.push(`/transaksi/sales-order-spk/tambah/${val.id_spk}`)}
                  >
                    <IoAddOutline />
                  </ActionButton>
                </div>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    if (!data || data?.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })} />

      {/* content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
      {modalConfig.show && <ModalDetailSPK modalConfig={modalConfig} setModalConfig={setModalConfig} />}
    </CRUDLayout>
  );
};

export default ListSPK;
