import Services from "../../../services";

class RegisterSalesOrderApi {
  getSales() {
    return Services.get("/karyawan");
  }
  getCustomer() {
    return Services.get("/customer");
  }
  getKelompok() {
    return Services.get("/sales_order/kelompok");
  }
  getItemBarang(params) {
    return Services.get("/sales_order/barang_jadi", { params });
  }
  getStokItemBarang(id_barang) {
    return Services.get(`/barang/stok/?id_barang=${id_barang}`);
  }
  getJenisCustomer() {
    return Services.get("/customer/jenis");
  }
  getProyek() {
    return Services.get("/sales_order/dropdown");
  }
  getProvinsi() {
    return Services.get("/customer/provinsi");
  }
  getKabupaten(id_provinsi) {
    return Services.get(`/customer/kabupaten?id_provinsi=${id_provinsi}`);
  }
  getKecamatan(id_kabupaten) {
    return Services.get(`/customer/kecamatan?id_kabupaten=${id_kabupaten}`);
  }
  getDesa(id_kecamatan) {
    return Services.get(`/customer/desa?id_kecamatan=${id_kecamatan}`);
  }
  getBank() {
    return Services.get(`/customer/bank`);
  }
  createCustomer(data) {
    return Services.post("/customer", data);
  }
}

export default new RegisterSalesOrderApi();
