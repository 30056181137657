import React, { useEffect, useState } from 'react';
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  EditorState,
  ContentState,
  convertFromHTML
} from 'draft-js'
import {
  TextArea,
  TextEditor
} from 'components'
import { DateConvert } from 'utilities';

const InfoSection = ({ data }) => {
  const [textEditorState, setTextEditorState] = useState(() => EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p></p>'))))

  const InfoItem = ({ title, value, link, onClick }) => (
    <tr onClick={onClick}>
      <td width={120}>
        <span style={{ fontSize: '12.8px' }}>{title}</span>
      </td>
      <td className={`pl-4 pr-2`}>:</td>
      <td className={link ? "text-primary" : ""} style={{ cursor: link ? "pointer" : "default" }}>
        {value}
      </td>
    </tr>
  );

  useEffect(() => {
    setTextEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data?.lain_lain ?? '<p></p>'))))

  }, [data]);

  return (
    <>
      <Row>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Peluang"
                value={data.tgl_peluang ? DateConvert(new Date(data.tgl_peluang)).detail : "-"} />
              <InfoItem
                title="No. Peluang"
                value={data?.no_peluang ?? "-"} />
              <InfoItem
                title="Peringkat Peluang"
                value={data?.nama_peringkat_peluang ?? "-"} />
              <InfoItem title="Proyek" value={data?.nama_proyek ?? "-"} />
              <InfoItem title="Schedule Kebutuhan Proyek" value={data?.schedule_kebutuhan_proyek ?? "-"} />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Customer"
                value={data?.nama_customer ?? "-"} />
              <InfoItem title="ATT" value={data?.att ?? "-"} />
              <InfoItem
                title="Target Penawaran"
                value={data.tgl_selesai ? DateConvert(new Date(data.tgl_selesai)).detail : "-"}
              />
              <InfoItem
                title="Realisasi Penawaran"
                value={data.realisasi_penawaran ? DateConvert(new Date(data.realisasi_penawaran)).detail : "-"}
              />
              <InfoItem
                title="Link Referensi"
                value={data.link_referensi ?? "-"} />
              <InfoItem title="Segmentasi Pasar" value={data?.nama_segmentasi_pasar ?? "-"} />
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className='mt-1'>
        <Col>
          <TextArea
            disabled
            label='Keterangan Pengiriman'
            onChange={() => { }}
            value={data?.keterangan_pengiriman ?? "-"} />
          <TextArea
            disabled
            label='Lokasi Pengiriman'
            onChange={() => { }}
            value={data?.lokasi_pengiriman ?? "-"} />
        </Col>
        <Col>
          <TextEditor
            readOnly
            label='Informasi Lainnya'
            editorState={textEditorState}
            options={[]}
            editorStyle={{ height: 115 }}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. RAE"
                value={data.tgl_rae ? DateConvert(new Date(data.tgl_rae)).detail : "-"} />
              <InfoItem
                title="No. RAE"
                value={data?.no_rae ?? "-"} />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. RAB"
                value={data.tgl_rab ? DateConvert(new Date(data.tgl_rab)).detail : "-"} />
              <InfoItem
                title="No. RAB"
                value={data?.no_rab ?? "-"} />
            </tbody>
          </table>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. Penawaran"
                value={data.tgl_penawaran ? DateConvert(new Date(data.tgl_penawaran)).detail : "-"} />
              <InfoItem
                title="No. Penawaran"
                value={data?.no_penawaran ?? "-"} />
            </tbody>
          </table>
        </Col>
        <Col>
          <table style={{ fontSize: "14px" }}>
            <tbody>
              <InfoItem
                title="Tgl. SPK"
                value={data.tgl_spk ? DateConvert(new Date(data.tgl_spk)).detail : "-"} />
              <InfoItem
                title="No. SPK"
                value={data?.no_spk ?? "-"} />
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
};

export default InfoSection;
