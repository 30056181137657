import React, { forwardRef, useEffect, useState } from "react";
import {
  CRUDLayout,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  DataStatus,
} from "../../../components";
import { RupiahConvert, DateConvert } from "../../../utilities";
import Logo from "../../../config";
import { Col, Row } from "react-bootstrap";
import TTDBTS from "../../../assets/image/ttdBTS.png";
import TTD2 from "../../../assets/image/TTD2.png";
import TTD3 from "../../../assets/image/TTD3.png";
import { array } from "yup/lib/locale";

const PrintSalesOrder = forwardRef((props, ref) => {
  let id = props.id;
  let data = props.dataPrint;

  // FUNCTION KONVERSI TANGGAL INPUT
  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const getDate = inputDate.split(" ");
      const date = new Date(getDate[0]);
      return `${DateConvert(date).defaultDay}/${DateConvert(date).defaultMonth}/${
        DateConvert(date).defaultYear
      }`;
      // return DateConvert(date).detail
    }
    return "-";
  };

  const getTotal = () => {
    return data?.detail?.reduce((sum, { sub_total }) => sum + parseInt(sub_total), 0);
  };

  const getDiskon = () => {
    let total = getTotal();
    return (total * data.diskon) / 100;
  };

  const getTotalSetelahDiskon = () => {
    let total = getTotal();
    let jmlDiskon = getDiskon();
    return total - jmlDiskon;
  };
  // GET TOTAL PPN
  const getJumlahPpn = () => {
    let totalSetelahDiskon = getTotalSetelahDiskon();
    return String((totalSetelahDiskon * data.ppn) / 100);
  };

  const getTotalSetelahPpn = () => {
    let totalSetelahDiskon = getTotalSetelahDiskon();
    let jmlPpn = parseInt(getJumlahPpn());
    return totalSetelahDiskon + jmlPpn;
  };

  const KopSuratSection = () => (
    <div className="d-flex mx-2">
      <div>
        <img src={Logo.LOGO} width={110} />
      </div>
      <div style={{ marginLeft: 30 }}>
        <p>
          <b>PT. MARDIKA GRIYA PRASTA</b>
          <br />
          Jl. Sekarsari No.30, Kesambi, Denpasar, Bali, Indonesia <br />
          Phone +62(0)361 467-342 <br />
          Fax (0361) 46730 <br />
          Email sales@mgpwoodworks.com <br />
        </p>
      </div>
    </div>
  );

  const TTDSection = () => {
    const TTDItems = ({ title, value, jabatan, src }) => (
      <Col className="text-center">
        <div>{title}</div>
        {/* <div style={{ height: 80 }}></div> */}
        <div>
          <img src={src} height="80" />
        </div>
        <div>
          <u>{value}</u>
        </div>
        <div>{jabatan}</div>
      </Col>
    );

    return (
      <Row className="mt-5 pt-3">
        <>
          <TTDItems
            title="Disiapkan Oleh,"
            src={TTDBTS}
            value="Kadek Ayu Puspandari P"
            jabatan="Staff Marcom"
          />
          <TTDItems
            title="Diperiksa Oleh,"
            src={TTD2}
            value="A.A Ngurah Temaja Putra"
            jabatan="Direktur"
          />
          <TTDItems
            title="Disetujui,"
            src={TTD3}
            value="I Ketut Nukayasa"
            jabatan="Manager Adm & Keu"
          />
        </>
      </Row>
    );
  };

  useEffect(() => {}, [id]);
  return (
    <div className="container my-5 px-5" ref={ref}>
      <KopSuratSection />
      <div className="row pt-5">
        <div className="col">
          <div className="row">
            <div className="col">
              <h6>Tgl. Sales Order</h6>
            </div>
            <div className="col">
              <h6>: {getConvertedDate(data.tgl_sales_order)}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6>Nama Customer</h6>
            </div>
            <div className="col">
              <h6>: {data.nama_customer}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6>Alamat Pengiriman</h6>
            </div>
            <div className="col">
              <h6>: {data.alamat_pengiriman}</h6>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
              <h6>No. Sales Order</h6>
            </div>
            <div className="col">
              <h6 className="text-uppercase">: {data.no_sales_order}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6>Nama Sales</h6>
            </div>
            <div className="col">
              <h6>: {data.nama_sales}</h6>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4"></div>

      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No</ThFixed>
            <ThFixed>Kode Barang</ThFixed>
            <ThFixed>Nama Barang</ThFixed>
            <ThFixed>Qty</ThFixed>
            <ThFixed>Satuan</ThFixed>
            <ThFixed>Harga Satuan</ThFixed>
            <ThFixed>Jumlah</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data?.detail?.length > 0 ? (
            data?.detail?.map((val, index) => {
              // ADD KEY sub_total PADA VAL
              val.sub_total = String(val.harga_satuan_jual * val.qty_item);
              return (
                <Tr key={val.id_sales_order_detail}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kode_barang}</TdFixed>
                  <TdFixed>{val.nama_barang}</TdFixed>
                  <TdFixed>{parseInt(val.qty_item)}</TdFixed>
                  <TdFixed>{val.nama_satuan}</TdFixed>
                  <Td textRight>{RupiahConvert(String(val.harga_satuan_jual)).detail}</Td>
                  <Td textRight>
                    {RupiahConvert(String(val.harga_satuan_jual * val.qty_item)).detail}
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan="7">
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
          {data?.detail?.length > 0 ? (
            <>
              <Tr>
                <Td colSpan={6} textRight>
                  <strong>Total</strong>
                </Td>

                <Td textRight>
                  <strong className="text-nowrap">
                    {RupiahConvert(String(getTotal())).detail}
                  </strong>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={6} textRight>
                  <strong className="text-nowrap">{`Diskon (${parseInt(data.diskon)}%)`}</strong>
                </Td>

                <Td textRight>
                  <strong className="text-nowrap">
                    {RupiahConvert(String(getDiskon())).detail}
                  </strong>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={6} textRight>
                  <strong>Total Setalah Diskon</strong>
                </Td>
                <Td textRight>
                  <strong className="text-nowrap">
                    {RupiahConvert(String(getTotalSetelahDiskon())).detail}
                  </strong>
                </Td>
              </Tr>

              <Tr>
                <Td colSpan={6} textRight>
                  <strong>PPN {`(${parseInt(data.ppn)}%)`}</strong>
                </Td>
                <Td textRight>
                  <strong className="text-nowrap">
                    {RupiahConvert(String(getJumlahPpn())).detail}
                  </strong>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={6} textRight>
                  <strong>Total Setelah PPN</strong>
                </Td>

                <Td textRight>
                  <strong className="text-nowrap">
                    {RupiahConvert(String(getTotalSetelahPpn())).detail}
                  </strong>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan="8">
                  <b>Catatan :</b>
                  <br />
                  <p className="text-justify">{data.catatan}</p>
                </Td>
              </Tr>
            </>
          ) : (
            <></>
          )}
        </TBody>
      </CRUDLayout.Table>
      {!Array.isArray(data.stakeholder) ? (
        "memuat data"
      ) : data.stakeholder.length > 1 ? (
        <TTDSection />
      ) : (
        ""
      )}
    </div>
  );
});

export default PrintSalesOrder;
