import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import Axios from "axios"
import {
  CRUDLayout,
  Input,
  InputSearch,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  Alert,
  Pagination,
  SelectSearch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ReadButton,
  ReadModalLarge,
  NumberFormat,
} from "components"
import { HargaSatuanJualApi } from "api"
import { DateConvert, PageNumber, RupiahConvert } from "utilities"

const HargaSatuanJual = ({ setNavbarTitle }) => {
  const title = "Harga Satuan Jual"

  const [isLoading, setIsLoading] = useState(true)
  const [isFetchingDropdown, setIsFetchingDropdown] = useState(true)
  const [searchKey, setSearchKey] = useState("")

  const [isCreateForm, setIsCreateForm] = useState(false)
  const [isUpdateForm, setIsUpdateForm] = useState(false)
  const [isDeleteData, setIsDeleteData] = useState(false)
  const [isViewData, setIsViewData] = useState(false)

  const [data, setData] = useState([])
  const [dataCount, setDataCount] = useState(0)
  const [page, setPage] = useState(1)
  const [dataLength, setDataLength] = useState(10)
  const [totalPage, setTotalPage] = useState(1)
  const [updateData, setUpdateData] = useState([])
  const [deleteData, setDeleteData] = useState([])
  const [viewData, setViewData] = useState([])
  const [dataItemBahan, setDataItemBahan] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })

  const getData = () => {
    setIsLoading(true)

    Axios.all([HargaSatuanJualApi.getPage({ page, per_page: dataLength, q: searchKey })])
      .then(
        Axios.spread((res) => {
          sortedByDateList(res.data.data)
          setTotalPage(res.data.total_page)
          setDataCount(res.data.data_count)
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false))
  }

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const getDate = inputDate.split(" ")
      const date = new Date(getDate[0])
      return `${DateConvert(date).defaultDay}/${DateConvert(date).defaultMonth}/${DateConvert(date).defaultYear}`
    }
    return "-"
  }

  const sortedByDateList = (listItemBahan) => {
    const sorted = listItemBahan?.sort(function (a, b) {
      return new Date(b.tgl_input) - new Date(a.tgl_input)
    })
    setData(sorted)
  }

  const searchData = (e) => {
    e.preventDefault()
    const key = e.target.value

    setSearchKey(key)
    setPage(1)
    setAlertConfig({ variant: 'primary', text: "Hasil dari pencarian: " + key })
    setShowAlert(key?.length > 0 ? true : false)
  }

  useEffect(() => {
    setNavbarTitle(title)
    getData()

    return () => {
      setIsLoading(false)
    }
  }, [page, dataLength, searchKey])

  useEffect(() => {
    // fetch data bahan
    HargaSatuanJualApi.getBahan()
      .then((res) => setDataItemBahan(res.data.data))
      .catch(({ response }) => window.alert(response.data.message ?? "Gagal memuat data bahan!"))
      .finally(() => setIsFetchingDropdown(false))

    return () => setDataItemBahan([])
  }, [])

  const formValidationSchema = Yup.object().shape({
    id_item_buaso: Yup.string().required("Pilih barang jadi"),
    harga_satuan_jual: Yup.string()
      .required("Masukkan Harga Satuan Jual")
      .typeError("Harga satuan jual tidak valid"),
  })

  const TambahModal = ({ isFetchingDropdown }) => {
    const [namaSatuan, setNamaSatuan] = useState("")

    const formInitialValues = {
      id_item_buaso: "",
      satuan: "",
      harga_satuan_jual: "",
    }

    const formSubmitHandler = (values) => {
      HargaSatuanJualApi.create(values)
        .then((res) => {
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          })
        })
        .catch((err) => {
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          setIsCreateForm(false)
          setShowAlert(true)
          getData()
        })
    }

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Item Barang Jadi"
                  name="id_item_buaso"
                  placeholder="Pilih barang jadi"
                  loading={isFetchingDropdown}
                  onChange={(val) => {
                    setFieldValue("id_item_buaso", val.value)
                    setNamaSatuan(val.nama_satuan)
                  }}
                  option={dataItemBahan.map((val) => {
                    return {
                      value: val.id_item_buaso,
                      label: val.nama_item,
                      nama_satuan: val.nama_satuan,
                    }
                  })} //option harus berupa value dan label
                  defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                  error={errors.id_item_buaso && touched.id_item_buaso && true}
                  errorText={errors.id_item_buaso && touched.id_item_buaso && errors.id_item_buaso}
                />

                <Input
                  label="Satuan Jual"
                  type="text"
                  name="satuan"
                  placeholder="Satuan"
                  readOnly={true}
                  value={namaSatuan}
                  onChange={handleChange}
                  error={errors.satuan && touched.satuan && true}
                  errorText={errors.satuan}
                />

                <NumberFormat
                  label="Harga Satuan Jual"
                  value={values.harga_satuan_jual}
                  defaultValue={values.harga_satuan_jual}
                  prefix="Rp"
                  onChange={(_e, _maskedvalue, floatvalue) =>
                    setFieldValue("harga_satuan_jual", floatvalue)
                  }
                  error={errors.harga_satuan_jual && touched.harga_satuan_jual && true}
                  errorText={errors.harga_satuan_jual}
                />
              </Modal.Body>

              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Tambah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    )
  }

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_item_buaso: updateData.id_item_buaso,
      harga_satuan_jual: "",
    }

    const formSubmitHandler = (values) => {
      HargaSatuanJualApi.create(values)
        .then((res) => {
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          })
        })
        .catch((err) => {
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          setIsUpdateForm(false)
          setShowAlert(true)
          getData()
        })
    }

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Item Barang Jadi"
                  name="id_item_buaso"
                  placeholder="Pilih barang jadi"
                  onChange={(val) => setFieldValue("id_item_buaso", val.value)}
                  isDisabled={true}
                  defaultValue={{
                    value: updateData.id_item_buaso,
                    label: updateData.nama_item,
                  }}
                  error={errors.id_item_buaso && touched.id_item_buaso && true}
                  errorText={errors.id_item_buaso && touched.id_item_buaso && errors.id_item_buaso}
                />

                <Input
                  label="Satuan Jual"
                  type="text"
                  name="satuan"
                  placeholder="Satuan Jual"
                  readOnly={true}
                  value={updateData.nama_satuan}
                  onChange={handleChange}
                  error={errors.satuan && touched.satuan && true}
                  errorText={errors.satuan}
                />

                <Input
                  label="Harga Satuan Jual"
                  type="text"
                  name="update_harga_satuan_jual"
                  placeholder={
                    RupiahConvert(parseInt(updateData.harga_satuan_jual).toString()).detail
                  }
                  style={{ textAlign: "right" }}
                  onChange={(event) => {
                    const { value } = event.target
                    const onlyNumber = value.replace(/[^\d]/g, "")

                    event.target.value = RupiahConvert(parseInt(onlyNumber).toString()).detail
                    values.harga_satuan_jual = onlyNumber
                  }}
                  error={errors.harga_satuan_jual && touched.harga_satuan_jual && true}
                  errorText={errors.harga_satuan_jual}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    )
  }

  const HapusModal = () => {
    const deleteValue = { id_grup_aset: deleteData.id_grup_aset }
    const [btnLoading, setBtnLoading] = useState(false)

    const deleteDataHandler = () => {
      setBtnLoading(true)

      HargaSatuanJualApi.delete(deleteValue)
        .then(() => {
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        })
        .catch((err) => {
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err.response.data.message})`,
          })
        })
        .finally(() => {
          setIsDeleteData(false)
          setShowAlert(true)
          getData()
        })
    }

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
      />
    )
  }

  const ViewModal = () => {
    // MENYIMPAN DATA HISTORY
    const [dataItemHistory, setDataItemHistory] = useState([])
    const [historyPage, setHistoryPage] = useState(1)
    const [totalHistoryPage, setTotalHistoryPage] = useState(1)
    const [dataHistoryLength, setDataHistoryLength] = useState(10)
    const [isLoadingHistory, setIsLoadingHistory] = useState(true)
    const [dataCountHistory, setDataCountHistory] = useState(0)

    const getDataHistoryItem = (id_item_buaso) => {
      HargaSatuanJualApi.getItemHistory(historyPage, dataHistoryLength, id_item_buaso)
        .then((res) => {
          console.log("dataHistory", res.data)
          setDataItemHistory(res.data.data)
          setTotalHistoryPage(res.data.total_page)
          setDataCountHistory(res.data.data_count)
        })
        .catch((err) => alert(err.response.data.message))
        .finally(() => setIsLoadingHistory(false))
    }

    useEffect(() => {
      setIsLoadingHistory(true)
      getDataHistoryItem(viewData.id_item_buaso)
    }, [historyPage, dataHistoryLength])

    // KOMPONEN TABEL HISTORY
    const TableHistory = () => {
      return (
        <>
          <CRUDLayout.Table>
            <THead>
              <Tr>
                <ThFixed>No</ThFixed>
                <Th>Tanggal Update</Th>
                <Th>Harga Satuan Jual</Th>
              </Tr>
            </THead>
            <TBody>
              {dataItemHistory?.map((val, index) => {
                return (
                  <Tr key={val.id_harga_satuan_jual}>
                    <TdFixed>{PageNumber(historyPage, dataHistoryLength, index)}</TdFixed>
                    <TdFixed>{getConvertedDate(val.tgl_input)}</TdFixed>
                    <Td textRight>{RupiahConvert(val.harga_satuan_jual).detail}</Td>
                  </Tr>
                )
              })}
            </TBody>
          </CRUDLayout.Table>
          <Pagination
            dataLength={dataHistoryLength}
            dataNumber={historyPage * dataHistoryLength - dataHistoryLength + 1}
            dataPage={historyPage * dataHistoryLength}
            dataCount={dataCountHistory}
            onDataLengthChange={(e) => {
              setPage(1)
              setDataHistoryLength(e.target.value)
            }}
            currentPage={historyPage}
            totalPage={totalHistoryPage}
            onPaginationChange={({ selected }) => setHistoryPage(selected + 1)}
          />
        </>
      )
    }

    return (
      <ReadModalLarge show={isViewData} onHide={() => setIsViewData(false)} title={title}>
        <Modal.Body>
          <div>
            <div className="row mb-2">
              <div className="col-sm-12">
                <div className="row mb-1">
                  <div className="col-md-2">
                    <h6 style={{ fontSize: "14px" }}>
                      <strong>Nama Item Bahan</strong>
                    </h6>
                  </div>
                  <div className="col-md-10">
                    <h6
                      className="text-capitalize"
                      style={{ fontSize: "14px" }}
                    >{`: ${viewData.nama_item}`}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mb-1">
                  <div className="col-md-2">
                    <h6 style={{ fontSize: "14px" }}>
                      <strong>Kode Item Bahan</strong>
                    </h6>
                  </div>
                  <div className="col-md-10">
                    <h6 style={{ fontSize: "14px" }}>{`: ${viewData.kode_item}`}</h6>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row mb-1">
                  <div className="col-md-2">
                    <h6 style={{ fontSize: "14px" }}>
                      <strong>Satuan</strong>
                    </h6>
                  </div>
                  <div className="col-md-10">
                    <h6 style={{ fontSize: "14px" }}>{`: ${viewData.nama_satuan}`}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isLoadingHistory ? (
            <DataStatus loading={isLoadingHistory} text="Memuat Data" />
          ) : !Array.isArray(dataItemHistory) ? (
            <DataStatus text="Tidak dapat memuat data" />
          ) : dataItemHistory.length > 0 ? (
            <TableHistory />
          ) : (
            <>
              <hr />
              <DataStatus text="Tidak ada data histori" />
            </>
          )}
        </Modal.Body>
      </ReadModalLarge>
    )
  }

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Kode Item Bahan</ThFixed>
              <ThFixed>Tgl. Input</ThFixed>
              <Th>Nama Item Bahan</Th>
              <ThFixed>Satuan</ThFixed>
              <Th>Harga Satuan Jual</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_item_buaso}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ReadButton
                        onClick={() => {
                          setViewData(val)
                          setIsViewData(true)
                        }}
                      />
                      <UpdateButton
                        onClick={() => {
                          setUpdateData(val)
                          setIsUpdateForm(true)
                        }}
                      />
                    </div>
                  </TdFixed>
                  <TdFixed>{val.kode_item}</TdFixed>
                  <TdFixed>{getConvertedDate(val.tgl_input)}</TdFixed>
                  <Td>{val.nama_item}</Td>
                  <TdFixed>{val.nama_satuan}</TdFixed>
                  <Td textRight>
                    {RupiahConvert(parseInt(val.harga_satuan_jual).toString()).detail}
                  </Td>
                </Tr>
              )
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1)
            setDataLength(e.target.value)
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              placeholder="Cari Harga Satuan Jual"
              value={searchKey}
              onChange={searchData}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <TambahModal isFetchingDropdown={isFetchingDropdown} />
      <UbahModal isFetchingDropdown={isFetchingDropdown} />
      <HapusModal />
      {isViewData && <ViewModal />}
    </CRUDLayout>
  )
}

export default HargaSatuanJual
