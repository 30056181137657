import { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Card, Dropdown } from 'react-bootstrap'
import { Alert, BackButton, DataStatus, Approval } from 'components'
import { FakturPenjualanApi } from 'api'
import { InfoSectionDetail, TableSectionDetail } from '../Section'
import PrintFakturPenjualan from './PrintFakturPenjualan'
import { saveAs } from 'file-saver'

const DetailFakturPenjualan = ({ setNavbarTitle }) => {
  const history = useHistory()
  const { id } = useParams()
  const componentRef = useRef()
  const [dataInfo, setDataInfo] = useState({})
  const [dataTable, setDataTable] = useState([])
  const [dataPrelim, setDataPrelim] = useState([])
  const [isPrelim, setIsPrelim] = useState(false)
  const [dataStakeholder, setDataStakeholder] = useState([])
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })

  const mappingDataInfo = (data) => {
    return {
      id_sales_order: data.id_sales_order ?? '-',
      tgl_sales_order: data.tgl_sales_order ?? null,
      no_sales_order: data.no_sales_order ?? '-',
      tgl_faktur_penjualan: data.tgl_faktur ?? null,
      batas_waktu: data.batas_waktu ?? null,
      no_faktur_penjualan: data.no_faktur ?? '-',
      customer: data.nama_customer ?? '-',
      sales: data.nama_sales ?? '-',
      persentase_prelim: data.persentase_prelim
        ? parseFloat(data.persentase_prelim)
        : 0,
      diskon: data.diskon ? parseInt(data.diskon) : 0,
      ppn: data.ppn ? parseInt(data.ppn) : 0,
      uang_muka: parseFloat(data.uang_muka ?? 0),
      nama_proyek: data.nama_proyek ?? '-',
      akumulasi_prelim: data.akumulasi_prelim ?? 0,
      id_faktur_penjualan: data.id_faktur_penjualan
    }
  }

  const mappingDataPrelim = (data) => {
    const prelim = data?.map((item, index) =>
      Object({
        ...item,
        id_item: item.id_item_prelim,
        kode_item: item.kode_item_prelim,
        nama_item: item.nama_item_prelim,
        qty_item: item.qty_analisa,
        id_unit: item.id_satuan_prelim,
        nama_unit: item.nama_satuan_prelim,
        harga: parseFloat(item.unit_price ?? 0),
        qty_durasi: parseFloat(item.qty_durasi ?? 0),
        konstanta: parseFloat(item.konstanta ?? 0),
        order: index + 1
      })
    )

    return prelim
  }

  const mappingDataTable = (data) =>
    data.map((val) => {
      return {
        id_surat_jalan: val.id_surat_jalan ?? null,
        tgl_penerimaan_surat_jalan: val.tgl_penerimaan_surat_jalan ?? null,
        no_penerimaan_surat_jalan: val.no_penerimaan_surat_jalan ?? '-',
        tgl_surat_jalan: val.tgl_surat_jalan ?? null,
        no_surat_jalan: val.no_surat_jalan ?? '-',
        tgl_delivery_order: val.tgl_delivery_order ?? null,
        no_delivery_order: val.no_delivery_order ?? '-',
        nama_item: val.nama_item ?? '-',
        satuan: val.nama_satuan ?? '',
        qty: val.qty ? parseFloat(val.qty) : 0,
        harga: val.harga_satuan_jual ? parseInt(val.harga_satuan_jual) : 0,
        checked: false,
        persentase_progress: val.persentase_progress ?? 0,
        akumulasi_progress: val.akumulasi_progress ?? 0
      }
    })

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    FakturPenjualanApi.getSingleFaktur({ id_faktur_penjualan: id })
      .then(({ data }) => {
        const getData = data.data
        const checkPrelim = getData.prelim ? true : false
        const mapDataInfo = mappingDataInfo(getData ?? {})
        const mapDataTable = mappingDataTable(getData.detail ?? [])
        const mapDataPrelim = mappingDataPrelim(getData.prelim ?? [])
        const getDataStakeholder = data.data

        setDataInfo(mapDataInfo)
        setDataTable(mapDataTable)
        setDataStakeholder(getDataStakeholder)
        setDataPrelim(mapDataPrelim)
        setIsPrelim(checkPrelim)
        setFetchingStatus({
          loading: false,
          success: true
        })
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  const getExportData = (file) => {
    FakturPenjualanApi.export({
      tipe: file,
      id_faktur_penjualan: dataInfo.id_faktur_penjualan
    })
      .then((res) => {
        const link = res?.data?.data
        const parse =
          'https://' +
          link
            .split('/')
            .filter((_val, idx) => idx !== 0)
            .filter((_val, idx) => idx !== 0)
            .join('/')
        saveAs(parse, 'Laporan Penjualan')
      })
      .catch(() => {
        window.alert('Export data gagal')
      })
  }

  useEffect(() => {
    getInitialData()
    setNavbarTitle('Faktur Penjualan')
  }, [])

  return (
    <>
      <Card style={{ minHeight: '80vh' }}>
        <Card.Header className='d-flex justify-content-between align-items-center'>
          <b>Detail Data Faktur Penjualan</b>
          <div className='d-flex'>
            <Dropdown className='mr-1'>
              <Dropdown.Toggle
                variant='warning'
                className='text-white'
                size='md'
              >
                Export
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => getExportData('pdf')}>
                  PDF(.pdf)
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <BackButton size='md' onClick={() => history.goBack()} />
          </div>
        </Card.Header>
        <Card.Body className='pb-5'>
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text='Memuat . . .' />
          ) : fetchingStatus.success ? (
            <>
              <InfoSectionDetail dataInfo={dataInfo} />
              <hr />
              <TableSectionDetail
                dataInfo={dataInfo}
                dataTable={dataTable}
                setDataTable={setDataTable}
                dataPrelim={dataPrelim}
                setDataPrelim={setDataPrelim}
                isPrelim={isPrelim}
              />
            </>
          ) : (
            <DataStatus text='Data gagal dimuat' />
          )}
        </Card.Body>
      </Card>
      {!fetchingStatus.loading && fetchingStatus.success && (
        <Approval title='Faktur Penjualan' data={dataStakeholder} />
      )}
      {!fetchingStatus.loading && fetchingStatus.success && (
        <div style={{ display: 'none' }}>
          <PrintFakturPenjualan
            ref={componentRef}
            dataInfo={dataInfo}
            dataTable={dataTable}
            dataPrelim={dataPrelim}
          />
        </div>
      )}
    </>
  )
}

export default DetailFakturPenjualan
