import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Input,
  DataStatus,
  Alert,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  BackButton,
  TextArea,
  ActionButton,
  Approval
} from "../../../components";
import Axios from "axios";
import { RegisterSalesOrderApi, TransaksiSalesOrderApi } from "../../../api";
import { RupiahConvert } from "../../../utilities";
import { useReactToPrint } from "react-to-print";
import PrintSalesOrder from "./PrintSalesOrder";

const DetailSalesOrder = ({ setNavbarTitle }) => {
  //  USE HISTORY
  const history = useHistory();

  // GET ID SALES ORDER FROM PARAMS
  let { id_sales_order } = useParams();

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE MENAMPUNG DATA CUSTOMER
  const [dataCustomer, setDataCustomer] = useState([]);
  // STATE MENAMPUNG DATA SALES ORDER
  const [dataSalesOrder, setDataSalesOrder] = useState({});
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // GENERATE CUSTOMER DATA DETAIL
  // const getCustomerDetail = (id_customer) => {
  //     if (id_customer) {
  //         const detail = dataCustomer.find(e => e.id_customer === id_customer);
  //         const text = `Nama : ${detail.nama_customer},\nTelepon : ${detail.telepon},\nEmail : ${detail.email}`
  //         setCustomerDetail(text);
  //     }
  // }

  // FUNCTION GET DATA LIST CUSTOMER
  // const getDataCustomers = () => {
  //   RegisterSalesOrderApi.getCustomer()
  //     .then((customers) => {
  //       setDataCustomer(customers.data.data);
  //     })
  //     .catch((err) => alert(err.response.data.message));
  // };

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    // GET DATA CUSTOMERS LIST
    // getDataCustomers();

    Axios.all([TransaksiSalesOrderApi.getSingle(id_sales_order)])
      .then(
        Axios.spread((data_so) => {
          setDataSalesOrder(data_so.data.data);
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const refPrint = useRef();
  // INIT USE REACT TO PRINT
  const reactToPrint = useReactToPrint({
    content: () => refPrint.current,
  });
  // ON CLICK HANDLE PRINT
  const handlePrint = () => {
    reactToPrint();
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle("Detail Sales Order"); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
  }, []);

  // TABLE LIST ITEM YANG DIORDER COMPONENT
  const Table = () => {
    // GET TOTAL ORDER
    const getTotal = () =>
      dataSalesOrder.detail?.reduce((sum, { sub_total }) => sum + parseInt(sub_total), 0);
    // GET TOTAL DISKON
    const getJumlahDiskon = () => {
      let total = getTotal();
      return String((total * dataSalesOrder.diskon) / 100);
    };
    // GET TOTAL SETELAH DISKON
    const getTotalSetelahDiskon = () => {
      let total = getTotal();
      let jmlDiskon = getJumlahDiskon();
      return String(total - jmlDiskon);
    };
    // GET TOTAL PPN
    const getJumlahPpn = () => {
      let totalSetelahDiskon = getTotalSetelahDiskon();
      return String((totalSetelahDiskon * dataSalesOrder.ppn) / 100);
    };
    // GET TOTAL SETELAH PPN
    const getTotalSetelahPpn = () => {
      let totalSetelahDiskon = parseInt(getTotalSetelahDiskon());
      let jumlahPpn = parseInt(getJumlahPpn());
      return String(totalSetelahDiskon + jumlahPpn);
    };

    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Kode Barang</ThFixed>
              <ThFixed>Nama Barang</ThFixed>
              <ThFixed>Gudang</ThFixed>
              <ThFixed>Qty SO</ThFixed>
              <ThFixed>Satuan</ThFixed>
              <ThFixed>Harga Satuan</ThFixed>
              <ThFixed>Jumlah</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataSalesOrder.detail?.map((order, index) => {
              order.sub_total = order.qty_item * order.harga_satuan_jual;

              return (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{order.kode_barang}</TdFixed>
                  <Td>{order.nama_barang}</Td>
                  <Td>{order.nama_gudang}</Td>
                  <Td textRight>{parseInt(order.qty_item)}</Td>
                  <Td>{order.nama_satuan}</Td>
                  <Td textRight>
                    {order.harga_satuan_jual
                      ? RupiahConvert(order.harga_satuan_jual).detail
                      : "Rp. 0"}
                  </Td>
                  <Td textRight>
                    {order.sub_total
                      ? RupiahConvert(String(parseInt(order.sub_total))).detail
                      : "Rp. 0"}
                  </Td>
                </Tr>
              );
            })}
            {dataSalesOrder.detail.length > 0 ? (
              <>
                <Tr>
                  <Td colSpan={7} textRight>
                    <strong>Total</strong>
                  </Td>

                  <Td textRight>
                    <strong className="text-nowrap">
                      {RupiahConvert(String(parseInt(getTotal()))).detail}
                    </strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={7} textRight>
                    <strong>Diskon {`(${parseInt(dataSalesOrder.diskon)}%)`}</strong>
                  </Td>
                  <Td textRight>
                    <strong className="text-nowrap">
                      {RupiahConvert(parseInt(getJumlahDiskon()).toString()).detail}
                    </strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={7} textRight>
                    <strong className="text-nowrap">Total Setelah Diskon</strong>
                  </Td>

                  <Td textRight>
                    <strong className="text-nowrap">
                      {RupiahConvert(parseInt(getTotalSetelahDiskon()).toString()).detail}
                    </strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={7} textRight>
                    <strong>PPN {`(${parseInt(dataSalesOrder.ppn)}%)`}</strong>
                  </Td>
                  <Td textRight>
                    <strong className="text-nowrap">
                      {RupiahConvert(parseInt(getJumlahPpn()).toString()).detail}
                    </strong>
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={7} textRight>
                    <strong>Total Setelah PPN</strong>
                  </Td>

                  <Td textRight>
                    <strong className="text-nowrap">
                      {RupiahConvert(parseInt(getTotalSetelahPpn()).toString()).detail}
                    </strong>
                  </Td>
                </Tr>
              </>
            ) : (
              <div />
            )}
          </TBody>
        </CRUDLayout.Table>
        <Approval title='Sales Order' data={dataSalesOrder} />
      </>
    );
  };
  return (
    // MAIN CRUD LAYOUT
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : (
        <Card className="pb-3 mt-3">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>{`Detail Sales Order`}</b>
            <BackButton size="sm" onClick={() => history.goBack()} />
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div className="col-md-3 col-sm-12">
                <Input
                  label="Tanggal Sales Order"
                  type="date"
                  name="tgl_sales_order"
                  value={dataSalesOrder.tgl_sales_order}
                  readOnly={true}
                />

                <Input
                  label="Proyek"
                  type="text"
                  name="nama_proyek"
                  value={dataSalesOrder.nama_proyek}
                  readOnly={true}
                />

                <Input
                  label="Batas Waktu Pengiriman"
                  type="date"
                  name="batas_waktu"
                  value={dataSalesOrder.batas_waktu}
                  readOnly={true}
                />
              </div>

              <div className="col-md-4 col-sm-12">
                <Input
                  label="No. Sales Order"
                  type="text"
                  name="no_sales_order"
                  readOnly={true}
                  value={dataSalesOrder.no_sales_order}
                />

                <Input
                  label="Sales"
                  type="text"
                  name="sales"
                  readOnly={true}
                  value={dataSalesOrder.nama_sales}
                />

                <div className="row">
                  <div className="col-md-12">
                    <Input
                      label="Customer"
                      type="text"
                      name="customer"
                      readOnly={true}
                      value={dataSalesOrder.nama_customer}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-5 col-sm-12">
                <TextArea
                  label="Alamat Pengiriman"
                  placeholder="Masukkan Alamat Pengiriman"
                  name="alamat_pengiriman"
                  isDisabled={true}
                  value={dataSalesOrder.alamat_pengiriman}
                  rows={3}
                />

                <TextArea
                  label="Catatan"
                  placeholder="Masukkan Catatan"
                  name="catatan"
                  value={dataSalesOrder.catatan}
                  isDisabled={true}
                  rows={3}
                />
              </div>
            </div>

            <hr className="mb-3" />

            {!Array.isArray(dataSalesOrder.detail) ? (
              <DataStatus text="Tidak dapat memuat data" />
            ) : dataSalesOrder.detail.length > 0 ? (
              <>
                <Table />
                <div style={{ display: "none" }}>
                  <PrintSalesOrder id={id_sales_order} dataPrint={dataSalesOrder} ref={refPrint} />
                </div>
              </>
            ) : (
              <DataStatus text="Daftar item barang kosong" />
            )}
            <div className="d-flex justify-content-end mt-2">
              <ActionButton
                className="btn btn-primary text-white"
                size="sm"
                text="Cetak Sales Order"
                onClick={handlePrint}
              />
            </div>
          </Card.Body>
        </Card>
      )}
    </CRUDLayout>
  );
};

export default DetailSalesOrder;
