import React, { useState, useEffect, useRef, useContext, memo, useMemo } from "react"
import { Row, Col, Card, Nav } from "react-bootstrap"
import { useHistory, useParams } from "react-router-dom"
import htmlParser from "html-react-parser"
import Axios from "axios"
import { Alert, ActionButton, BackButton, DataStatus, SelectSearch } from "components"
import { RupiahConvert } from "utilities"
import { AnalisaBarangJadiRABApi } from "api"
import {
  TableListHardwood,
  TableListPlywood,
  TableListTPFS,
  TableListTPLC,
  TableListTPMP,
  TableListTPBOP,
  TableListFNBP,
  TableListFNSC,
  TableListFNLC,
  TableListSummary,
  TableListAnalisaAlatBantuOnSite,
  TableListAnalisaLabourCostOnSite,
  TableListAnalisaBOK
} from "./Table"
import FilesUpload from "./FilesUpload"

const FormAnalisaBarangJadi = ({
  processedData,
  dataBarangJadi,
  setDataBarangJadi,
  alertConfig,
  setAlertConfig,
  modalConfig,
  setModalConfig,
}) => {
  const history = useHistory()
  const id = processedData.id_barang_jadi
  const id_rab_detail = processedData.id_rab_detail
  const componentRef = useRef()
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false)
  const [dataBahanBaku, setDataBahanBaku] = useState({})
  const [dataBahanPenunjang, setDataBahanPenunjang] = useState({})
  const [dataFinishing, setDataFinishing] = useState({})
  const [dataOnSite, setDataOnSite] = useState({})
  const [dataFinishingBarangJadi, setDataFinishingBarangJadi] = useState([])
  const [filesUpload, setFilesUpload] = useState([])
  const [dropdownTipeSisi, setDropdownTipeSisi] = useState([])
  const [dropdownTipeFinishing, setDropdownTipeFinishing] = useState([])
  const [dropdownSpekKayu, setDropdownSpekKayu] = useState([])
  const [dropdownSpekPlywood, setDropdownSpekPlywood] = useState([])
  const [dropdownDeskripsi, setDropdownDeskripsi] = useState([])
  const [dropdownPart, setDropdownPart] = useState([])
  const [dropdownTipeProduksi, setDropdownTipeProduksi] = useState([])
  const [dropdownBentuk, setDropdownBentuk] = useState([])
  const [dropdownUkuran, setDropdownUkuran] = useState([])
  const [dataKelompokTipeBarangJadi, setDataKelompokTipeBarangJadi] = useState([])
  const [dataTipeBarangJadi, setDataTipeBarangJadi] = useState([])
  const [newDataAnalisaBarangJadi, setNewDataAnalisaBarangJadi] = useState([])

  const checkUpdateAndRabDetail = useMemo(() => Boolean(id_rab_detail), [id_rab_detail])

  const filterDataHardwood = data => {
    let finalData = []
    const sorting = data?.sort((a, b) => {
      if (a.urutan_item < b.urutan_item) return -1
      if (a.urutan_item > b.urutan_item) return 1

      return 0
    })

    sorting?.map((val, index) => {
      finalData.push({
        id_barang_jadi: id,
        urutan_item: index + 1,
        id_deskripsi: val.id_deskripsi,
        deskripsi: val.deskripsi,
        is_header: val.is_header,
        qty_raw: parseFloat(val.qty_raw),
        t_raw: parseFloat(val.t_raw),
        w_raw: parseFloat(val.w_raw),
        l_raw: parseFloat(val.l_raw),
        qty_final: parseFloat(val.qty_final),
        t_final: parseFloat(val.t_final),
        w_final: parseFloat(val.w_final),
        l_final: parseFloat(val.l_final),
        unit_price: parseFloat(val.harga_satuan),
        konstanta: parseFloat(val.koefisien),
        id_jenis_kayu: val.id_jenis_kayu,
        nama_jenis_kayu: val.nama_jenis_kayu,
        id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
        id_tipe_sisi: val.id_tipe_sisi,
        nama_tipe_sisi: val.nama_tipe_sisi,
        id_part_kayu: val.id_part_kayu,
        nama_part_kayu: val.nama_part_kayu
      })
    })

    return finalData
  }

  const filterDataPlywood = data => {
    let finalData = []
    const sorting = data?.sort((a, b) => {
      if (a.urutan_item < b.urutan_item) return -1
      if (a.urutan_item > b.urutan_item) return 1

      return 0
    })

    sorting?.map((val, index) => {
      finalData.push({
        id_barang_jadi: id,
        urutan_item: index + 1,
        id_deskripsi: val.id_deskripsi,
        deskripsi: val.deskripsi,
        is_header: val.is_header,
        qty_final: parseFloat(val.qty_final ?? 0),
        t_final: parseFloat(val.t_final ?? 0),
        w_final: parseFloat(val.w_final ?? 0),
        l_final: parseFloat(val.l_final ?? 0),
        qty_raw: parseFloat(val.qty_raw),
        t_raw: parseFloat(val.t_raw),
        w_raw: parseFloat(val.w_raw),
        l_raw: parseFloat(val.l_raw),
        unit_price: parseFloat(val.harga_satuan),
        konstanta: parseFloat(val.koefisien),
        kode_item_bahan: val.kode_item_bahan,
        nama_satuan: val.nama_satuan,
        id_item_buaso: val.id_item_buaso,
        nama_item: val.nama_item,
        id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
        id_tipe_sisi: val.id_tipe_sisi,
        nama_tipe_sisi: val.nama_tipe_sisi,
      })
    })

    return finalData
  }

  // Mapping data yang ada TPnya
  const filterDataTipeBarangJadi = data => {
    const newData = []

    data && data.length > 0 && data.map((val, index) => {
      newData.push({
        id_barang_jadi: id,
        // id_item_buaso: checkUpdateAndRabDetail ? val.id_item_buaso : val.id_item_bahan,
        id_item_buaso: val.id_item_buaso,
        qty: parseFloat(val.qty),
        unit_price: parseFloat(val.harga_satuan),
        konstanta: parseFloat(val.koefisien),
        kode_item_bahan: val.kode_item_bahan,
        nama_item: val.nama_item,
        nama_satuan: val.nama_satuan,
        urutan_item: index + 1,
      })
    })

    return newData

  }

  // Mapping data yang ada FNnya
  const filterDataFinishingBarangJadi = data => {
    const newData = []

    data && data.length > 0 && data.map((val, index) => {
      newData.push({
        id_analisa_barang_jadi_tipe_fs: val.id_analisa_barang_jadi_tipe_fs,
        id_barang_jadi: id,
        // id_item_bahan: checkUpdateAndRabDetail ? val.id_item_buaso : val.id_item_bahan,
        id_item_bahan: val.id_item_buaso,
        id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        qty: parseFloat(val.qty),
        unit_price: parseFloat(val.harga_satuan),
        konstanta: parseFloat(val.koefisien),
        kode_item_bahan: val.kode_item_bahan,
        nama_item: val.nama_item,
        nama_satuan: val.nama_satuan,
        nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
        urutan_item: index + 1,
      })
    })

    return newData

  }

  const filterDataAlatBantuDanBOK = (data, type) => data.map(val => {
    return {
      id_barang_jadi: val.id_barang_jadi,
      id_item_buaso: val.id_item_buaso,
      qty: checkUpdateAndRabDetail ? parseFloat(val.qty) : 0,
      unit_price: checkUpdateAndRabDetail ? parseFloat(val.unit_price) : 0,
      konstanta: checkUpdateAndRabDetail ? parseFloat(val.konstanta) : type === 'BOK' ? 0.07 : 0.03,
      nama_item: val.nama_item,
      nama_satuan: val.nama_satuan,
      kode_item: val.kode_item,
    }
  })

  const filterDataLabourCostOnSite = data => data.map(val => ({
    id_barang_jadi: id,
    id_item_buaso: val.id_item_buaso,
    kode_item: val.kode_item,
    nama_item: val.nama_item,
    qty: val.qty ? parseFloat(val.qty) : 0,
    unit_price: val.unit_price ? parseFloat(val.unit_price) : 0,
    nama_satuan: val.nama_satuan,
    konstanta: val.konstanta ? parseFloat(val.konstanta) : 0
  }))

  const checkIsHavingAnalisaBefore = dataAnalisa => {
    /*
      Cek apakah ada data analisa di context.
      Jika ada gunakan data pada context.
      Jika tidak gunakan data yang di fetch dari API dan simpan kedalam context
    */

    const newDataHardwood = filterDataHardwood(dataAnalisa?.analisa_hardwood ?? [])
    const newDataPlywood = filterDataPlywood(dataAnalisa?.analisa_plywood ?? [])
    const newDataTPFS = filterDataTipeBarangJadi(dataAnalisa?.analisa_fin_tp_fs ?? [])
    const newDataTPLC = filterDataTipeBarangJadi(dataAnalisa?.analisa_fin_tp_lc ?? [])
    const newDataTPMP = filterDataTipeBarangJadi(dataAnalisa?.analisa_fin_tp_mp ?? [])
    const newDataTPBOP = filterDataTipeBarangJadi(dataAnalisa?.analisa_fin_tp_bop ?? [])
    const newDataFNBP = filterDataFinishingBarangJadi(dataAnalisa?.analisa_fin_fn_bp ?? [])
    const newDataFNSC = filterDataFinishingBarangJadi(dataAnalisa?.analisa_fin_fn_sc ?? [])
    const newDataFNLC = filterDataFinishingBarangJadi(dataAnalisa?.analisa_fin_fn_lc ?? [])
    const newDataLabourCost = filterDataLabourCostOnSite(dataAnalisa.analisa_labour_cost_onsite ?? [])
    const newDataAlatBantu = filterDataAlatBantuDanBOK(dataAnalisa?.analisa_alat_bantu_onsite ?? [], "ALAT")
    const newDataBOK = filterDataAlatBantuDanBOK(dataAnalisa?.analisa_biaya_overhead_kantor ?? [], "BOK")
    const finalValues = {
      id_barang_jadi: dataAnalisa.id_barang_jadi,
      kode_item: dataAnalisa.kode_item,
      nama_item: dataAnalisa.nama_item,
      nama_kelompok: dataAnalisa.nama_kelompok,
      nama_satuan: dataAnalisa.nama_satuan,
      link_referensi: dataAnalisa.link_referensi,
      uraian: dataAnalisa.uraian,
      dataHardwood: newDataHardwood,
      dataPlywood: newDataPlywood,
      dataTPFS: newDataTPFS,
      dataTPLC: newDataTPLC,
      dataTPMP: newDataTPMP,
      dataTPBOP: newDataTPBOP,
      dataFNBP: newDataFNBP,
      dataFNSC: newDataFNSC,
      dataFNLC: newDataFNLC,
      dataLabourCostOnSite: newDataLabourCost,
      dataAlatBantuOnSite: newDataAlatBantu,
      dataAnalisaBOK: newDataBOK
    }

    return finalValues
  }

  // const getBahanBaku = () =>
  //   AnalisaBarangJadiRABApi.getSingle({ id_barang_jadi: id, tipe: "baku" })
  //     .then((res) => setDataBahanBaku(res.data.data))

  // const getPenunjangProduksi = () =>
  //   AnalisaBarangJadiRABApi.getSingle({ id_barang_jadi: id, tipe: "penunjang" })
  //     .then((res) => setDataBahanPenunjang(res.data.data))

  // const getFinishing = () =>
  //   AnalisaBarangJadiRABApi.getSingle({ id_barang_jadi: id, tipe: "fin" })
  //     .then((res) => setDataFinishing(res.data.data))

  const getInitialData = () => {
    setIsPageLoading(true)

    Axios.all([
      AnalisaBarangJadiRABApi.getAnalisaSingle({ id_rab_detail }),
      AnalisaBarangJadiRABApi.getDropdownTipeFinishing(),
    ])
      .then(
        Axios.spread((detail, tipeFin) => {
          const data = detail?.data?.data ?? {}
          const dataDetail = checkIsHavingAnalisaBefore(data ?? {})
          setNewDataAnalisaBarangJadi(dataDetail)
          setDropdownTipeFinishing(tipeFin?.data?.data ?? [])
          setFilesUpload(data.gambar ?? [])
        })
      )
      .catch(() => {
        setIsFetchingFailed(false)
      })
      .finally(() => {
        setIsPageLoading(false)
      })
  }

  useEffect(() => {
    getInitialData()

    return () => { }
  }, [])

  const InfoSection = () => {
    const [tabs, setTabs] = useState("informasi") //State untuk menampung tabs yang aktif

    const InfoItem = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>{value}</b>
      </div>
    )

    const InfoItemUraian = ({ title, value }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <div style={{ height: '400px', overflowY: 'auto' }}>{value}</div>
      </div>
    )

    const InfoItemLink = ({ title, value, text }) => (
      <div className="d-flex flex-column mb-3">
        <small>{title}</small>
        <b>
          <a href={value} target="_blank">
            {text}
          </a>
        </b>
      </div>
    )

    const tabsConfig = [
      {
        tab: "informasi",
        label: "Informasi Umum",
        component: () => (
          <Row>
            <Col>
              <InfoItem
                title="Kode Barang Jadi"
                value={newDataAnalisaBarangJadi?.kode_item ? newDataAnalisaBarangJadi?.kode_item : "-"}
              />
              <InfoItem
                title="Nama Barang Jadi"
                value={newDataAnalisaBarangJadi?.nama_item ? newDataAnalisaBarangJadi?.nama_item : "-"}
              />
              <InfoItem
                title="Sumber Barang Jadi"
                value={newDataAnalisaBarangJadi?.nama_kelompok ? newDataAnalisaBarangJadi?.nama_kelompok : "-"}
              />
              <InfoItem
                title="Satuan Jual"
                value={newDataAnalisaBarangJadi?.nama_satuan ? newDataAnalisaBarangJadi?.nama_satuan : "-"}
              />
              {newDataAnalisaBarangJadi?.link_referensi ? (
                <InfoItemLink
                  title="Link Referensi"
                  value={newDataAnalisaBarangJadi?.link_referensi}
                  text="Lihat Referensi"
                />
              ) : (
                <InfoItem title="Link Referensi" value="-" />
              )}
            </Col>
            <Col>
              <InfoItemUraian
                title="Uraian"
                value={newDataAnalisaBarangJadi.uraian ? htmlParser(newDataAnalisaBarangJadi.uraian) : "-"}
              />
            </Col>
          </Row>
        ),
      },
      {
        tab: "gambar",
        label: "Gambar",
        component: () => (
          <FilesUpload
            filesUpload={filesUpload}
            setFilesUpload={setFilesUpload}
            action="detail"
            section="images"
          />
        ),
      },
      {
        tab: "file",
        label: "File",
        component: () => (
          <FilesUpload
            filesUpload={filesUpload}
            setFilesUpload={setFilesUpload}
            action="detail"
            section="documents"
          />
        ),
      },
    ]

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault()
      setTabs(newPage)
    }

    useEffect(() => {
      setTabs(tabsConfig[0].tab)
    }, [])

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    )

    return (
      <>
        <div className="mb-2 d-flex justify-content-between align-items-end">
          <h6 className='font-weight-bold text-uppercase'>{`Detail Data ${newDataAnalisaBarangJadi?.nama_item ?? ''}`}</h6>

        </div>
        <Card className="mb-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
              {tabsConfig.map((val, index) => (
                <TabsNav key={index} tab={val.tab} label={val.label} />
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {tabsConfig.map(({ tab, component: Component }, index) => tab === tabs && <Component key={index} />)}
          </Card.Body>
        </Card>
      </>
    )
  }

  const TableSection = memo(() => {
    const [tabs, setTabs] = useState("bahan_baku") //State untuk menampung tabs yang aktif
    const [analisaVariable, setAnalisaVariable] = useState([]) // menyimpan data analisa variable (BOK Produksi)
    const [dataHardwood, setDataHardwood] = useState(newDataAnalisaBarangJadi?.dataHardwood ?? [])
    const [dataPlywood, setDataPlywood] = useState(newDataAnalisaBarangJadi?.dataPlywood ?? [])
    const [dataTPFS, setDataTPFS] = useState(newDataAnalisaBarangJadi?.dataTPFS ?? [])
    const [dataTPLC, setDataTPLC] = useState(newDataAnalisaBarangJadi?.dataTPLC ?? [])
    const [dataTPMP, setDataTPMP] = useState(newDataAnalisaBarangJadi?.dataTPMP ?? [])
    const [dataTPBOP, setDataTPBOP] = useState(newDataAnalisaBarangJadi?.dataTPBOP ?? [])
    const [dataFNBP, setDataFNBP] = useState(newDataAnalisaBarangJadi?.dataFNBP ?? [])
    const [dataFNSC, setDataFNSC] = useState(newDataAnalisaBarangJadi?.dataFNSC ?? [])
    const [dataFNLC, setDataFNLC] = useState(newDataAnalisaBarangJadi?.dataFNLC ?? [])
    const [dataMPOS, setDataMPOS] = useState([])
    const [dataLCOS, setDataLCOS] = useState([])
    const [dataAlatBantuOnSite, setDataAlatBantuOnSite] = useState(newDataAnalisaBarangJadi?.dataAlatBantuOnSite ?? [])
    const [dataLabourCostOnSite, setDataLabourCostOnSite] = useState(newDataAnalisaBarangJadi?.dataLabourCostOnSite ?? [])
    const [dataDropdownLabourCostOnSite, setDataDropdownLabourCostOnSite] = useState(newDataAnalisaBarangJadi?.dataDropdownLabourCostOnSite ?? {})
    const [dataAnalisaBOK, setDataAnalisaBOK] = useState(newDataAnalisaBarangJadi?.dataAnalisaBOK ?? [])
    const [dataSelectTPFS, setDataSelectTPFS] = useState([])
    const [dataSelectTPLC, setDataSelectTPLC] = useState([])
    const [dataSelectTPMP, setDataSelectTPMP] = useState([])
    const [dataSelectTPBOP, setDataSelectTPBOP] = useState([])
    const [dataSelectFNBP, setDataSelectFNBP] = useState([])
    const [dataSelectFNSC, setDataSelectFNSC] = useState([])
    const [dataSelectFNLC, setDataSelectFNLC] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [selectedDropdownPenunjang, setSelectedDropdownPenunjang] = useState({
      id_barang_jadi_tipe_produksi: '',
      id_barang_jadi_bentuk: '',
      id_barang_jadi_ukuran: '',
      id_jenis_kayu: ''
    })
    const [loading, setLoading] = useState({
      tipe: false,
      finishing: false,
      variable: false
    })
    const [prevFormState, setPrevFormState] = useState({
      // state menyimpan form value input item hardwood & plywood
      hardwood: {},
      plywood: {}
    })

    const getUniqueTipeFinishing = () => {
      // fungsi mengambil id tipe finishing yang unique
      const listBahanBaku = [...dataHardwood, ...dataPlywood]
      let uniqueTipeFinishing = []   // menyimpan obj unique id tipe finishing

      // cari unique id tipe finishing
      listBahanBaku?.map((item) => {
        if (item?.id_finishing_barang_jadi !== '0' && Boolean(!uniqueTipeFinishing.includes(item?.id_finishing_barang_jadi))) {
          uniqueTipeFinishing.push(item?.id_finishing_barang_jadi)
        }
      })

      uniqueTipeFinishing = uniqueTipeFinishing?.map((item) => {
        return dropdownTipeFinishing.find(val => val?.id_finishing_barang_jadi === item)
      })

      return uniqueTipeFinishing
    }

    const getTotalLuasTipeFinishing = () => {
      // fungsi mengambil total luas berdasarkan id tipe finishing yang unique (digunakan sbg konst pada tab 3)
      const uniqueTipeFinishing = getUniqueTipeFinishing()
      const listBahanBaku = [...dataHardwood, ...dataPlywood]
      let totalLuasTipeFinishing = []  // menyimpan total luas tiap tipe finishing

      // hitung total luas tiap tipe finishing
      uniqueTipeFinishing?.map((val) => {
        const totalLuas = listBahanBaku?.filter((item) => item?.id_finishing_barang_jadi === val?.id_finishing_barang_jadi).reduce((acc, { total_luas }) => acc + parseFloat(total_luas ?? 0), 0)
        totalLuasTipeFinishing.push({ ...val, total_luas_tipe_finishing: parseFloat(totalLuas).toFixed(6) })
      })
      return totalLuasTipeFinishing
    }

    const getTotalUnit = () => {
      // fungsi mengambil total unit/volume pada list bahan baku (plywood&hardwood) (digunakan sbg konst pada tab 2)
      const listBahanBaku = [...dataHardwood, ...dataPlywood]
      const totalUnit = listBahanBaku.reduce((total, { total_unit }) => total + parseFloat(total_unit ?? 0), 0)

      return parseFloat(totalUnit).toPrecision(6)
    }


    const fetchTemplatePenunjang = (id_barang_jadi_tipe_produksi, id_barang_jadi_bentuk, id_jenis_kayu, id_barang_jadi_ukuran) => {
      const totalVolume = getTotalUnit()

      if (id_barang_jadi_tipe_produksi && id_barang_jadi_bentuk && id_barang_jadi_ukuran && id_jenis_kayu) {
        setLoading({ ...loading, tipe: true })

        const params = { id_barang_jadi_tipe_produksi, id_barang_jadi_bentuk, id_barang_jadi_ukuran, id_jenis_kayu }
        AnalisaBarangJadiRABApi.getSingleTemplatePenunjang(params)
          .then((val) => {
            const filterData = (data) => {
              const newData = []
              data &&
                data.length > 0 &&
                data.map((val, index) => {
                  newData.push({
                    id_barang_jadi: id,
                    id_item_buaso: val.id_item_bahan,
                    qty: val.qty,
                    unit_price: val.harga_satuan ? parseInt(val.harga_satuan) : 0,
                    konstanta: parseFloat(totalVolume * val.koefisien),
                    kode_item_bahan: val.kode_item_bahan,
                    nama_item: val.nama_item,
                    nama_satuan: val.nama_satuan,
                    urutan_item: index + 1,
                  })
                })
              return newData
            }

            const data = val.data.data
            const newDataTPFS = filterData(data.analisa_fin_tp_fs)
            const newDataTPLC = filterData(data.analisa_fin_tp_lc)
            const newDataTPMP = filterData(data.analisa_fin_tp_mp)
            const newDataTPBOP = filterData(data.analisa_fin_tp_bop)

            setDataTPFS(newDataTPFS)
            setDataTPLC(newDataTPLC)
            setDataTPMP(newDataTPMP)
            setDataTPBOP(newDataTPBOP)
          })
          .catch(() => {
            window.alert("Data Template Penunjang Produksi tidak ditemukan")
            setDataTPFS([])
            setDataTPLC([])
            setDataTPMP([])
            setDataTPBOP([])
          })
          .finally(() => setLoading({ ...loading, tipe: false })
          )
      }
    }

    const getDetailDataFinishing = (id_tipe, is_fetch_master = false) => {
      setLoading({
        ...loading,
        finishing: true,
      })

      let totalLuasTipeFinishing = getTotalLuasTipeFinishing()
      // Jika user klik btn 'get master' / fetch data finishing dari master
      // maka konstanta finishing master dikali dengan total luas tipe finishing
      const getFinalKonst = (koefisien) => {
        const luasTipeFinishing = totalLuasTipeFinishing.find(val => val?.id_finishing_barang_jadi === id_tipe)?.total_luas_tipe_finishing
        return luasTipeFinishing * koefisien
      }

      AnalisaBarangJadiRABApi.getSingleFinishingBarangJadi({ id_finishing_barang_jadi: id_tipe })
        .then((val) => {
          const filterData = (prevLength, data) => {
            const newData = []
            data &&
              data.length > 0 &&
              data.map((val, index) => {
                newData.push({
                  id_barang_jadi: id,
                  id_item_bahan: val.id_item_bahan,
                  id_finishing_barang_jadi: val.id_finishing_barang_jadi,
                  qty: val.qty,
                  unit_price: val.harga_satuan ? parseInt(val.harga_satuan) : 0,
                  konstanta: is_fetch_master ? getFinalKonst(val.koefisien) : val.koefisien,
                  kode_item_bahan: val.kode_item_bahan,
                  nama_item: val.nama_item,
                  nama_satuan: val.nama_satuan,
                  // urutan_item: index + 1,
                  urutan_item: prevLength + index + 1,
                })
              })
            return newData
          }

          const data = val.data.data.detail
          const dataFNBPLength = dataFNBP.length
          const dataFNLCLength = dataFNLC.length
          const dataFNSCLength = dataFNSC.length

          const newDataFNBP = filterData(dataFNBPLength, data.bp)
          const newDataFNLC = filterData(dataFNLCLength, data.lc)
          const newDataFNSC = filterData(dataFNSCLength, data.sc)

          // jika is_fetch_master = true, maka hapus/filter data finishing sebelumnya berdasasarkan id_tipe (replace dengan yang baru)
          if (is_fetch_master) {
            const filteredFNBP = dataFNBP.filter(val => val.id_finishing_barang_jadi !== id_tipe)
            const filteredFNLC = dataFNLC.filter(val => val.id_finishing_barang_jadi !== id_tipe)
            const filteredFNSC = dataFNSC.filter(val => val.id_finishing_barang_jadi !== id_tipe)

            setDataFNBP([...filteredFNBP, ...newDataFNBP])
            setDataFNLC([...filteredFNLC, ...newDataFNLC])
            setDataFNSC([...filteredFNSC, ...newDataFNSC])
          } else {
            setDataFNBP([...newDataFNBP])
            setDataFNLC([...newDataFNLC])
            setDataFNSC([...newDataFNSC])
          }
        })
        .catch(() => {
          window.alert("Gagal memuat data tipe finishing")
          setDataFNBP([])
          setDataFNLC([])
          setDataFNSC([])
        })
        .finally(() =>
          setLoading({
            ...loading,
            finishing: false,
          })
        )
    }

    const getGrandTotal = () => {
      const uniqueTipe = getUniqueTipeFinishing().map(val => val?.id_finishing_barang_jadi)
      let totalHardwood = 0
      let totalPlywood = 0
      let totalTPFS = 0
      let totalTPLC = 0
      let totalTPMP = 0
      let totalTPBOP = 0
      let totalFNBP = 0
      let totalFNSC = 0
      let totalFNLC = 0
      let totalLCOS = 0
      let totalMPOS = 0

      const totalHW = dataHardwood.reduce((prev, val) => {
        const countTotalUnit = parseFloat(
          (parseFloat(val.qty_raw).toFixed(6) *
            parseFloat(val.t_raw).toFixed(6) *
            parseFloat(val.w_raw).toFixed(6) *
            parseFloat(val.l_raw).toFixed(6)) /
          1000000
        ).toFixed(6)

        const totalUnit =
          parseFloat(countTotalUnit) < 0.000001 ? "0.000001" : parseFloat(countTotalUnit)
        const totalPrice = Math.round(
          parseFloat(totalUnit) * parseInt(val.unit_price) * parseFloat(val.konstanta)
        )
        const checkPrice = val.is_header ? 0 : totalPrice
        totalHardwood = parseInt(checkPrice)

        return prev + totalHardwood
      }, 0)

      dataPlywood.map((val) => {
        const total = Math.round(
          parseFloat(val.qty_raw).toFixed(6) *
          parseInt(val.unit_price ?? 0) *
          parseFloat(val.konstanta).toFixed(6)
        )
        totalPlywood = parseInt(totalPlywood) + parseInt(total || 0)
      })

      dataTPFS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
        )
        totalTPFS = parseInt(totalTPFS) + parseInt(total)
      })

      dataTPLC.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
        )
        totalTPLC = parseInt(totalTPLC) + parseInt(total)
      })

      dataTPMP.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
        )
        totalTPMP = parseInt(totalTPMP) + parseInt(total)
      })

      dataTPBOP.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
        )
        totalTPBOP = parseInt(totalTPBOP) + parseInt(total)
      })

      dataFNBP.map((val) => {
        if (val.id_finishing_barang_jadi !== '0' && uniqueTipe.includes(val.id_finishing_barang_jadi)) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
          )
          totalFNBP = parseInt(totalFNBP) + parseInt(total)
        }
      })

      dataFNSC.map((val) => {
        if (val.id_finishing_barang_jadi !== '0' && uniqueTipe.includes(val.id_finishing_barang_jadi)) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
          )
          totalFNSC = parseInt(totalFNSC) + parseInt(total)
        }
      })

      dataFNLC.map((val) => {
        if (val.id_finishing_barang_jadi !== '0' && uniqueTipe.includes(val.id_finishing_barang_jadi)) {
          const total = Math.round(
            parseFloat(val.qty).toFixed(6) *
            parseInt(val.unit_price) *
            parseFloat(val.konstanta).toFixed(6)
          )
          totalFNLC = parseInt(totalFNLC) + parseInt(total)
        }
      })

      dataLCOS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
        )
        totalLCOS = parseInt(totalLCOS) + parseInt(total)
      })

      dataMPOS.map((val) => {
        const total = Math.round(
          parseFloat(val.qty).toFixed(6) *
          parseInt(val.unit_price) *
          parseFloat(val.konstanta).toFixed(6)
        )
        totalMPOS = parseInt(totalMPOS) + parseInt(total)
      })

      const totalFinishing = parseInt(totalFNBP) + parseInt(totalFNSC) + parseInt(totalFNLC)

      const grandTotal =
        parseInt(totalHW) +
        parseInt(totalPlywood) +
        parseInt(totalTPFS) +
        parseInt(totalTPLC) +
        parseInt(totalTPMP) +
        parseInt(totalTPBOP) +
        parseInt(totalFinishing)

      const summaryBiaya = [
        { kode: 'hw', nama_biaya: 'Hardwood', subtotal: parseInt(totalHW) },
        { kode: 'pw', nama_biaya: 'Plywood', subtotal: parseInt(totalPlywood) },
        { kode: 'fs', nama_biaya: 'Factory Supply', subtotal: parseInt(totalTPFS) },
        { kode: 'lc', nama_biaya: 'Labour Cost', subtotal: parseInt(totalTPLC) },
        { kode: 'mp', nama_biaya: 'Machine Process', subtotal: parseInt(totalTPMP) },
        { kode: 'bop', nama_biaya: 'Overhead Pabrik', subtotal: parseInt(totalTPBOP) },
        { kode: 'fin', nama_biaya: 'Finishing', subtotal: parseInt(totalFinishing) },
      ]

      return summaryBiaya
      // return RupiahConvert(grandTotal.toString()).detail
    }

    const getGrandTotalPrice = () => {
      const data = getGrandTotal();

      const totalBahanBaku = data
        .filter(item => item.kode === "hw" || item.kode === "pw")
        .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

      const totalPenunjangProduksi = data
        .filter(item => item.kode === "fs" || item.kode === "lc" || item.kode === "mp" || item.kode === "bop")
        .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

      const totalFinishing = data
        .filter(item => item.kode === "fin")
        .reduce((total, { subtotal }) => total + parseInt(subtotal ?? 0), 0);

      const totalAlatBantu = () => {
        const getQty = dataAlatBantuOnSite[0]?.qty ? parseFloat(dataAlatBantuOnSite[0]?.qty) : 0
        const getKonst = dataAlatBantuOnSite[0]?.konstanta ? parseFloat(dataAlatBantuOnSite[0]?.konstanta) : 0
        const getPrice = dataAlatBantuOnSite[0]?.unit_price ? parseFloat(dataAlatBantuOnSite[0]?.unit_price) : 0

        return parseInt(getQty * getKonst * getPrice)
      }

      const totalLabourCost = () => {
        const subTotal = dataLabourCostOnSite.reduce((prev, current) => {
          const checkQty = current.qty ? parseFloat(current.qty) : 0
          const checkPrice = current.unit_price ? parseFloat(current.unit_price) : 0
          const checkKonst = current.konstanta ? parseFloat(current.konstanta) : 0
          const total = parseInt(checkQty * checkPrice * checkKonst)

          return prev + total
        }, 0)

        return subTotal
      }

      const totalBOK = () => {
        const getQty = dataAnalisaBOK[0]?.qty ? parseFloat(dataAnalisaBOK[0]?.qty) : 0
        const getKonst = dataAnalisaBOK[0]?.konstanta ? parseFloat(dataAnalisaBOK[0]?.konstanta) : 0
        const getPrice = dataAnalisaBOK[0]?.unit_price ? parseFloat(dataAnalisaBOK[0]?.unit_price) : 0

        return parseInt(getQty * getKonst * getPrice)
      }

      return totalBahanBaku + totalPenunjangProduksi + totalFinishing + totalAlatBantu() + totalLabourCost() + totalBOK()
    }

    const submitDataHandler = () => {

    }

    const checkAndGetTemplateLabourCost = (values) => {
      setDataDropdownLabourCostOnSite(values)

      AnalisaBarangJadiRABApi.getTemplateLabourCost({ id_barang_jadi_tipe_produksi: values.id_tipe_produksi, id_barang_jadi_bentuk: values.id_bentuk })
        .then(res => {
          const data = res?.data?.data ?? []
          const mapData = data.map(val => ({
            id_barang_jadi: id,
            id_item_buaso: val.id_item_buaso,
            kode_item: val.kode_item,
            nama_item: val.nama_item,
            qty: parseFloat(val.qty),
            unit_price: parseFloat(val.harga_satuan),
            nama_satuan: val.nama_satuan,
            konstanta: parseFloat(val.konstanta)
          }))

          setDataLabourCostOnSite(mapData)

        })
        .catch(() => {
          setDataLabourCostOnSite([])

        })
    }

    useEffect(() => {
      // getSelectData()
      // filterDataHardwood()
      // filterDataPlywood()
      // filterDataTipeBarangJadi()
      // filterDataFinishingBarangJadi()
    }, [])

    const SelectKelompokTipeBarangJadi = () => {
      const totalVolume = getTotalUnit()
      return (
        <div className="d-flex justify-content-end align-items-start mb-3">
          {/* Total Volume Section */}
          <div className="col-md-2">
            <small className="pl-2 mt-1">Total Volume</small>
            <br />
            <p className="pl-2 mt-1 font-weight-bold">{`${parseFloat(totalVolume).toPrecision()} (m3)`}</p>
          </div>
          {/* Dropdown Analisa Penunjang Section */}
          <div className="row col-md-10">
            <div className="col-md-3 pl-0 pr-1">
              <SelectSearch
                label="Tipe Produksi"
                placeholder="Pilih tipe produksi"
                className="m-1"
                defaultValue={dropdownTipeProduksi
                  ?.filter(item => item.id_barang_jadi_tipe_produksi === selectedDropdownPenunjang.id_barang_jadi_tipe_produksi)
                  ?.map(item => Object({ value: item.id_barang_jadi_tipe_produksi, label: item.nama_barang_jadi_tipe_produksi }))
                  ?? ""}
                option={dropdownTipeProduksi?.map((val) => Object({ value: val.id_barang_jadi_tipe_produksi, label: val.nama_barang_jadi_tipe_produksi }))}
                onChange={(val) => {
                  const id = val.value

                  setSelectedDropdownPenunjang({
                    ...selectedDropdownPenunjang,
                    id_barang_jadi_tipe_produksi: id
                  })

                  fetchTemplatePenunjang(
                    id,
                    selectedDropdownPenunjang.id_barang_jadi_bentuk,
                    selectedDropdownPenunjang.id_barang_jadi_ukuran,
                    selectedDropdownPenunjang.id_jenis_kayu
                  )
                }}
              />
            </div>
            <div className="col-md-3 pl-0  pr-1">
              <SelectSearch
                label="Bentuk"
                placeholder="Pilih bentuk"
                className="m-1"
                defaultValue={dropdownBentuk
                  ?.filter(item => item.id_barang_jadi_bentuk === selectedDropdownPenunjang.id_barang_jadi_bentuk)
                  ?.map(item => Object({ value: item.id_barang_jadi_bentuk, label: item.nama_barang_jadi_bentuk }))
                  ?? ""}
                option={dropdownBentuk?.map((val) => Object({ value: val.id_barang_jadi_bentuk, label: val.nama_barang_jadi_bentuk }))}
                onChange={(val) => {
                  const id = val.value

                  setSelectedDropdownPenunjang({
                    ...selectedDropdownPenunjang,
                    id_barang_jadi_bentuk: id
                  })

                  fetchTemplatePenunjang(
                    selectedDropdownPenunjang.id_barang_jadi_tipe_produksi,
                    id,
                    selectedDropdownPenunjang.id_barang_jadi_ukuran,
                    selectedDropdownPenunjang.id_jenis_kayu
                  )
                }}
              />
            </div>
            <div className="col-md-3 pl-0 pr-1">
              <SelectSearch
                label="Bahan"
                placeholder="Pilih bahan"
                className="m-1"
                defaultValue={dropdownSpekKayu
                  ?.filter(item => item.id_jenis_kayu === selectedDropdownPenunjang.id_jenis_kayu)
                  ?.map(item => Object({ value: item.id_jenis_kayu, label: item.nama_jenis_kayu }))
                  ?? ""
                }
                option={dropdownSpekKayu?.map((val) => Object({ value: val.id_jenis_kayu, label: val.nama_jenis_kayu }))}
                onChange={(val) => {
                  const id = val.value

                  setSelectedDropdownPenunjang({
                    ...selectedDropdownPenunjang,
                    id_jenis_kayu: id
                  })

                  fetchTemplatePenunjang(
                    selectedDropdownPenunjang.id_barang_jadi_tipe_produksi,
                    selectedDropdownPenunjang.id_barang_jadi_bentuk,
                    id,
                    selectedDropdownPenunjang.id_barang_jadi_ukuran
                  )
                }}
              />
            </div>
            <div className="col-md-3 px-0 ">
              <SelectSearch
                label="Ukuran"
                placeholder="Pilih ukuran"
                className="m-1"
                defaultValue={dropdownUkuran
                  ?.filter(item => item.id_barang_jadi_ukuran === selectedDropdownPenunjang.id_barang_jadi_ukuran)
                  ?.map(item => Object({ value: item.id_barang_jadi_ukuran, label: item.nama_barang_jadi_ukuran }))
                  ?? ""}
                option={dropdownUkuran?.map((val) => Object({ value: val.id_barang_jadi_ukuran, label: val.nama_barang_jadi_ukuran }))}
                onChange={(val) => {
                  const id = val.value

                  setSelectedDropdownPenunjang({
                    ...selectedDropdownPenunjang,
                    id_barang_jadi_ukuran: id
                  })

                  fetchTemplatePenunjang(
                    selectedDropdownPenunjang.id_barang_jadi_tipe_produksi,
                    selectedDropdownPenunjang.id_barang_jadi_bentuk,
                    selectedDropdownPenunjang.id_jenis_kayu,
                    id
                  )

                }}
              />
            </div>
          </div>
        </div>
      )
    }

    // Konfigurasi komponen tabs
    const tabsConfig = [
      {
        tab: "bahan_baku",
        label: "Analisa Bahan Baku",
        component: () => (
          <>
            <TableListHardwood
              dataHardwood={dataHardwood}
              setDataHardwood={setDataHardwood}
              dropdownTipeSisi={dropdownTipeSisi}
              dropdownTipeFinishing={dropdownTipeFinishing}
              dropdownSpekKayu={dropdownSpekKayu}
              dropdownDeskripsi={dropdownDeskripsi}
              dropdownPart={dropdownPart}
              prevFormState={prevFormState}
              setPrevFormState={setPrevFormState}
            />
            <TableListPlywood
              dataPlywood={dataPlywood}
              setDataPlywood={setDataPlywood}
              dropdownTipeSisi={dropdownTipeSisi}
              dropdownTipeFinishing={dropdownTipeFinishing}
              dropdownSpekPlywood={dropdownSpekPlywood}
              dropdownDeskripsi={dropdownDeskripsi}
              prevFormState={prevFormState}
              setPrevFormState={setPrevFormState}
            />
          </>
        ),
      },
      {
        tab: "penunjang",
        label: "Analisa Penunjang Produksi",
        component: () => {
          let totalVolume = getTotalUnit()
          return (
            <>
              {loading.tipe ? (
                <div style={{ height: "800px" }}>
                  <DataStatus loading={true} text="Memuat data penunjang produksi" />
                </div>
              ) : (
                <>
                  <TableListTPFS
                    dataSelectTPFS={dataSelectTPFS}
                    dataTPFS={dataTPFS}
                    setDataTPFS={setDataTPFS}
                    defaultKonst={totalVolume}
                  />
                  <TableListTPLC
                    dataSelectTPLC={dataSelectTPLC}
                    dataTPLC={dataTPLC}
                    setDataTPLC={setDataTPLC}
                    defaultKonst={totalVolume}
                  />
                  <TableListTPMP
                    dataSelectTPMP={dataSelectTPMP}
                    dataTPMP={dataTPMP}
                    setDataTPMP={setDataTPMP}
                    defaultKonst={totalVolume}
                  />
                  <TableListTPBOP
                    dataSelectTPBOP={dataSelectTPBOP}
                    dataTPBOP={dataTPBOP}
                    setDataTPBOP={setDataTPBOP}
                    defaultKonst={totalVolume}
                  />
                </>
              )}
            </>
          )
        },
      },
      {
        tab: "finishing",
        label: "Analisa Finishing",
        component: () => {
          let totalLuasTipeFinishing = getTotalLuasTipeFinishing()
          console.log(totalLuasTipeFinishing)
          return (
            <>
              {
                loading.finishing ? <DataStatus loading={loading.finishing} text='Memuat data' /> :
                  totalLuasTipeFinishing.length > 0 ?
                    totalLuasTipeFinishing.map((val) => {
                      return (
                        <div className="mt-2">
                          <div className='d-flex justify-content-between align-items-center'>
                            <h5 className="pl-2 font-weight-bold text-uppercase">{val.nama_finishing_barang_jadi}</h5>
                            <div className="d-flex justify-content-center align-items-start mx-2">
                              <div className="mr-3">
                                <small className="pl-2">{`Total Luas Permukaan`}</small>
                                <br />
                                <span className="pl-2 font-weight-bold">{`${parseFloat(val.total_luas_tipe_finishing).toPrecision()} (m2)`}</span>
                              </div>
                            </div>
                          </div>
                          <TableListFNBP
                            id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                            dataSelectFNBP={dataSelectFNBP}
                            dataFNBP={dataFNBP}
                            setDataFNBP={setDataFNBP}
                            defaultKonst={val.total_luas_tipe_finishing}
                          />
                          <TableListFNSC
                            id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                            dataSelectFNSC={dataSelectFNSC}
                            dataFNSC={dataFNSC}
                            setDataFNSC={setDataFNSC}
                            defaultKonst={val.total_luas_tipe_finishing}
                          />
                          <TableListFNLC
                            id_finishing_barang_jadi={val.id_finishing_barang_jadi}
                            dataSelectFNLC={dataSelectFNLC}
                            dataFNLC={dataFNLC}
                            setDataFNLC={setDataFNLC}
                            defaultKonst={val.total_luas_tipe_finishing}
                          />
                          <hr />
                        </div>)
                    }) : <DataStatus text="Data Tipe Finishing Bahan Baku diperlukan" />
              }
            </>
          )
        },
      },
      {
        tab: "alat_bantu_on_site",
        label: "Analisa Alat Bantu On-Site",
        component: () => (
          <>
            <TableListAnalisaAlatBantuOnSite
              dataSelectTPLC={dataSelectTPLC}
              dataHardwood={dataHardwood}
              dataPlywood={dataPlywood}
              dataAlatBantuOnSite={dataAlatBantuOnSite}
              setDataAlatBantuOnSite={setDataAlatBantuOnSite}
            />
          </>
        )
      },
      {
        tab: "labour_cost_on_site",
        label: "Analisa Labour Cost On-Site",
        component: () => (
          <>
            <TableListAnalisaLabourCostOnSite
              dataHardwood={dataHardwood}
              dataLabourCostOnSite={dataLabourCostOnSite}
              dataDropdownLabourCostOnSite={dataDropdownLabourCostOnSite}
              setDataLabourCostOnSite={setDataLabourCostOnSite}
              setDataDropdownLabourCostOnSite={setDataDropdownLabourCostOnSite}
              idBarangJadi={id}
              checkAndGetTemplateLabourCost={checkAndGetTemplateLabourCost}
            />
          </>
        )
      },
      {
        tab: "biaya_overhead_kantor",
        label: "Analisa Biaya Overhead Kantor",
        component: () => (
          <>
            <TableListAnalisaBOK
              dataAnalisaBOK={dataAnalisaBOK}
              dataAlatBantuOnSite={dataAlatBantuOnSite}
              dataLabourCostOnSite={dataLabourCostOnSite}
              setDataAnalisaBOK={setDataAnalisaBOK}
              getGrandTotal={getGrandTotal}
            />
          </>
        )
      }
      // {
      //   tab: "analisa_bok",
      //   label: "Analisa BOK Produksi",
      //   component: () => {
      //     return (
      //       <div className="mt-2">
      //         <TableListBOKProduksi
      //           analisaVariable={analisaVariable}
      //           setAnalisaVariable={setAnalisaVariable}
      //           getGrandTotal={getGrandTotal} />
      //       </div>
      //     )
      //   },
      // },
    ]

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, newPage) => {
      e.preventDefault()
      setTabs(newPage)
    }

    useEffect(() => {
      setTabs(tabsConfig[0].tab)
    }, [])

    const TabsNav = ({ tab, label }) => (
      <Nav.Item>
        <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
          {label}
        </Nav.Link>
      </Nav.Item>
    )

    return (
      <>
        {/* Card Analisa Barang Jadi */}
        <h6 className='font-weight-bold text-uppercase'>Data Analisa Barang Jadi</h6>
        <Card className="mb-4">
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
              {tabsConfig.map((val, index) => (
                <TabsNav key={index} tab={val.tab} label={val.label} />
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {tabsConfig.map(({ tab, component: Component }, index) => tab === tabs && <Component key={index} />)}
          </Card.Body>
        </Card>

        {/* Card Summary Biaya */}
        <h6 className='font-weight-bold text-uppercase'>Summary Biaya</h6>
        <Card>
          <Card.Body>
            <TableListSummary
              getGrandTotal={getGrandTotal}
              dataAlatBantuOnSite={dataAlatBantuOnSite}
              dataAnalisaBOK={dataAnalisaBOK}
              dataLabourCostOnSite={dataLabourCostOnSite}
            />
          </Card.Body>
        </Card>

        <div className="col d-flex justify-content-end p-0">
          <div className="d-flex flex-column">

          </div>
        </div>
      </>
    )
  })

  if (isPageLoading) {
    return <DataStatus loading={true} text="Memuat data . . ." />
  } else if (isFetchingFailed) {
    return <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
  }

  return (
    <>
      {!isPageLoading && !isFetchingFailed && (
        <InfoSection />
      )}

      {/* Table Section */}
      {!isPageLoading && !isFetchingFailed && (
        <TableSection
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig} />
      )}
    </>
  )
}

export default memo(FormAnalisaBarangJadi)
