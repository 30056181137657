import React, { useState, useEffect } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  IoAddOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
  IoPencilOutline,
  IoTextOutline,
  IoTrashOutline,
} from "react-icons/io5";
import ReactSelect from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ActionButton, Alert } from "components";
import { RupiahConvert } from "utilities";
// import { AnalisaBarangJadiApi } from "api";

const TableListHardwood = ({
  dataHardwood,
  setDataHardwood,
  dropdownTipeSisi,
  dropdownTipeFinishing,
  dropdownSpekKayu,
  dropdownDeskripsi,
  dropdownPart,
  prevFormState,
  setPrevFormState
}) => {
  const { id } = useParams();
  const [isFormHeader, setIsFormHeader] = useState(false);
  const [processedData, setProcessedData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "danger",
  });

  const dataTipeFinishing = dropdownTipeFinishing.map((item) =>
    Object({
      ...item,
      value: item.id_finishing_barang_jadi,
      label: item.nama_finishing_barang_jadi,
    })
  );
  dataTipeFinishing.unshift({ value: "0", label: "Tanpa Finishing" });

  let lastUrutanItem = 1;
  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle",
  };

  const prevState = prevFormState?.hardwood
  const formInitialValues = {
    id_barang_jadi: id,
    is_header: isFormHeader,
    id_deskripsi: prevState?.id_deskripsi ?? "",
    deskripsi: prevState?.deskripsi ?? "",
    id_jenis_kayu: isFormHeader ? null : prevState?.id_jenis_kayu ?? "",
    nama_jenis_kayu: prevState?.nama_jenis_kayu ?? "",
    id_part_kayu: isFormHeader ? null : prevState?.id_part_kayu ?? "",
    nama_part_kayu: prevState?.nama_part_kayu ?? "",
    qty_raw: isFormHeader ? null : prevState?.qty_raw ?? "0",
    t_raw: isFormHeader ? null : prevState?.t_raw ?? "0",
    w_raw: isFormHeader ? null : prevState?.w_raw ?? "0",
    l_raw: isFormHeader ? null : prevState?.l_raw ?? "0",
    qty_final: isFormHeader ? null : prevState?.qty_final ?? "0",
    t_final: isFormHeader ? null : prevState?.t_final ?? "0",
    w_final: isFormHeader ? null : prevState?.w_final ?? "0",
    l_final: isFormHeader ? null : prevState?.l_final ?? "0",
    id_finishing_barang_jadi: isFormHeader ? null : prevState?.id_finishing_barang_jadi ?? "0",
    nama_finishing_barang_jadi: prevState?.nama_finishing_barang_jadi ?? "Tanpa Finishing",
    id_tipe_sisi: isFormHeader ? null : prevState?.id_tipe_sisi ?? "",
    nama_tipe_sisi: prevState?.nama_tipe_sisi ?? "",
    unit_price: isFormHeader ? null : prevState?.unit_price ?? "0",
    konstanta: isFormHeader ? null : prevState?.konstanta ?? "1",
  };

  const formValidationSchema = Yup.object().shape({
    id_deskripsi: Yup.string().required(),
    id_jenis_kayu: isFormHeader ? "" : Yup.string().required(),
    id_part_kayu: isFormHeader ? "" : Yup.string().required(),
    qty_raw: isFormHeader ? "" : Yup.string().required(),
    t_raw: isFormHeader ? "" : Yup.string().required(),
    w_raw: isFormHeader ? "" : Yup.string().required(),
    l_raw: isFormHeader ? "" : Yup.string().required(),
    qty_final: isFormHeader ? "" : Yup.string().required(),
    t_final: isFormHeader ? "" : Yup.string().required(),
    w_final: isFormHeader ? "" : Yup.string().required(),
    l_final: isFormHeader ? "" : Yup.string().required(),
    id_finishing_barang_jadi: isFormHeader ? "" : Yup.string().required(),
    id_tipe_sisi: isFormHeader ? "" : Yup.string().required(),
    unit_price: isFormHeader ? "" : Yup.string().required(),
    konstanta: isFormHeader ? "" : Yup.string().required(),
  });

  const formSubmitHandler = (values) => {
    const finalValues = {
      ...values,
      urutan_item: lastUrutanItem,
    };

    setPrevFormState({ ...prevFormState, hardwood: values })
    setDataHardwood([...dataHardwood, finalValues]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
  });

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit, setValues } = formik;

  // fungsi get harga kubikasi
  // const getHargaKubikasi = (id_jenis_kayu, id_part_kayu, t_raw, w_raw, setFieldValue) => {
  //   if (id_jenis_kayu !== "" && id_part_kayu !== "" && t_raw !== "0" && w_raw !== "0") {
  //     let harga = "0";
  //     AnalisaBarangJadiApi.getHargaKubikasiKayu({
  //       id_jenis_kayu: id_jenis_kayu,
  //       id_part_kayu: id_part_kayu,
  //       t: t_raw,
  //       w: w_raw,
  //     })
  //       .then((res) => {
  //         harga = parseInt(res?.data?.data?.harga_rata_rata_per_kubik ?? 0);
  //       })
  //       .finally(() => setFieldValue("unit_price", harga));
  //   }
  // };

  // fungsi hitung luas(m2)
  const kalkulasiLuas = (values) => {
    const { id_tipe_sisi, qty_final, t_final, w_final, l_final } = values;
    if (t_final && w_final && l_final) {
      const t_final_float = parseFloat(t_final);
      const w_final_float = parseFloat(w_final);
      const l_final_float = parseFloat(l_final);
      const qty_final_float = parseFloat(qty_final);
      switch (id_tipe_sisi) {
        case "2":
          // 4 Sisi
          return ((t_final_float + w_final_float) * 2 * qty_final_float * l_final_float) / 10000;
        case "3":
          // 3 Sisi Opsi 1
          return (
            ((t_final_float + w_final_float + t_final_float) * qty_final_float * l_final_float) /
            10000
          );
        case "4":
          // 3 Sisi Opsi 2
          return (
            ((t_final_float + w_final_float + w_final_float) * qty_final_float * l_final_float) /
            10000
          );
        case "5":
          // 2 Sisi Opsi 1
          return ((t_final_float + w_final_float) * qty_final_float * l_final_float) / 10000;
        case "6":
          // 2 Sisi Opsi 2
          return ((t_final_float + t_final_float) * qty_final_float * l_final_float) / 10000;
        case "7":
          // 2 Sisi Opsi 3
          return ((w_final_float + w_final_float) * qty_final_float * l_final_float) / 10000;
        case "8":
          // 1 Sisi Opsi 1
          return (t_final_float * qty_final_float * l_final_float) / 10000;
        case "9":
          // 1 Sisi Opsi 1
          return (w_final_float * qty_final_float * l_final_float) / 10000;
        default:
          return 0;
      }
    }
  };

  // Hitung unit(m3)
  const formCountTotalUnit = parseFloat(
    (parseFloat(values.qty_raw).toFixed(6) *
      parseFloat(values.t_raw).toFixed(6) *
      parseFloat(values.w_raw).toFixed(6) *
      parseFloat(values.l_raw).toFixed(6)) /
    1000000
  ).toFixed(6);
  const formTotalUnit = parseFloat(formCountTotalUnit) < 0.000001 ? "0.000001" : parseFloat(formCountTotalUnit);

  // Hitung luas(m2)
  let formTotalLuas = kalkulasiLuas(values);

  // Hitung total price
  const formTotalPrice = Math.round(parseFloat(formTotalUnit) * parseInt(values.unit_price) * parseFloat(values.konstanta));

  let subTotalUnit = 0;
  let subTotalPrice = 0;

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, "");
    const convert = newValue.substring(0, 10);

    return convert;
  };

  const SelectSearch = (props) => (
    <ReactSelect
      {...props}
      classNamePrefix={props.error ? "react-select-invalid" : "react-select"}
      menuShouldBlockScroll={true}
      menuPosition="fixed"
      noOptionsMessage={() => "Tidak ada data"}
      styles={{
        control: (base) => ({
          ...base,
          minHeight: 28,
          maxHeight: 31,
          fontSize: 14,
        }),
        valueContainer: (base) => ({
          ...base,
          paddingLeft: 5,
          margin: 0,
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: 0,
          paddingLeft: 5,
          paddingRight: 5,
        }),
        menu: (base) => ({
          ...base,
          fontSize: 13,
        }),
      }}
    />
  );

  const PageModal = () => {
    const ModalDelete = () => {
      const [isDeleting, setIsDeleting] = useState(false);

      const deleteDataHandler = () => {
        setIsDeleting(true);
        setTimeout(() => {
          const newData = dataHardwood.filter(
            (val) => val.urutan_item !== processedData.urutan_item
          );
          setDataHardwood(newData);
          setIsDeleting(false);
          setModalConfig({
            ...modalConfig,
            show: false,
          });
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil dihapus!",
          });
        }, 200);
      };

      useEffect(() => {
        return () => {
          setIsDeleting(false);
        };
      }, []);

      const ModalDeleteText = () => (
        <>
          <h5>
            <span>Hapus data dengan deskripsi: </span>
            <br />
            <b>{processedData.deskripsi}</b>
          </h5>
          <small className="text-danger">Data yang dihapus tidak dapat dikembalikan!</small>
        </>
      );

      const ModalDeleteButton = () => (
        <div className="d-flex justify-content-center mt-3">
          <ActionButton
            variant="outline-secondary"
            className="m-1"
            text="Batal"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            variant="danger"
            className="m-1"
            text="Hapus Data"
            loading={isDeleting}
            onClick={deleteDataHandler}
          />
        </div>
      );

      return (
        <>
          <Modal.Body className="text-center">
            <ModalDeleteText />
            <ModalDeleteButton />
          </Modal.Body>
        </>
      );
    };

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <span className="text-danger">Hapus Hardwood</span>
        </Modal.Header>
        <ModalDelete />
      </Modal>
    );
  };

  const ListDataTable = ({
    index,
    val,
    totalUnit,
    totalPrice,
    setProcessedData,
    setModalConfig,
  }) => {
    return (
      <tr key={index}>
        <td className="px-1" style={tableStyling}>
          {index + 1}
        </td>
        <td className="px-1" style={tableStyling}>
          {val.is_header ? (
            <b>{val.deskripsi ? val.deskripsi : "-"}</b>
          ) : val.deskripsi ? (
            val.deskripsi
          ) : (
            "-"
          )}
        </td>
        <td className="px-1 text-nowrap" style={tableStyling}>
          {val.nama_jenis_kayu}
        </td>
        <td className="px-1 text-nowrap" style={tableStyling}>
          {val.nama_part_kayu}
        </td>
        <td className="px-1 text-nowrap" style={tableStyling}>
          {val.id_finishing_barang_jadi === "0"
            ? "Tanpa Finishing"
            : val.nama_finishing_barang_jadi}
        </td>
        <td className="px-1 text-nowrap" style={tableStyling}>
          {val.nama_tipe_sisi}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.qty_final ? parseFloat(val.qty_final).toPrecision() : ""}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.t_final ? parseFloat(val.t_final).toPrecision() : ""}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.w_final ? parseFloat(val.w_final).toPrecision() : ""}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.l_final ? parseFloat(val.l_final).toPrecision() : ""}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.qty_raw ? parseFloat(val.qty_raw).toPrecision() : ""}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.t_raw ? parseFloat(val.t_raw).toPrecision() : ""}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.w_raw ? parseFloat(val.w_raw).toPrecision() : ""}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.l_raw ? parseFloat(val.l_raw).toPrecision() : ""}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.total_luas ? parseFloat(val.total_luas).toPrecision() : ""}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.is_header ? "" : parseFloat(totalUnit).toPrecision()}
        </td>
        <td className="text-right text-nowrap" style={tableStyling}>
          {val.unit_price ? RupiahConvert(val.unit_price.toString()).detail : ""}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.konstanta ? parseFloat(val.konstanta).toPrecision() : ""}
        </td>
        <td className="text-right text-nowrap" style={tableStyling}>
          {val.is_header ? "" : RupiahConvert(totalPrice.toString()).detail}
        </td>
      </tr>
    );
  };

  const EditDataTable = ({ index, val, dataHardwood, setDataHardwood }) => {
    const formEditInitialValues = {
      id_barang_jadi: val.id_barang_jadi,
      // deskripsi: val.deskripsi,
      id_deskripsi: val.id_deskripsi,
      deskripsi: val.deskripsi,
      is_header: val.is_header,
      id_jenis_kayu: val.is_header ? null : val.id_jenis_kayu,
      nama_jenis_kayu: val.nama_jenis_kayu,
      id_part_kayu: val.is_header ? null : val.id_part_kayu,
      nama_part_kayu: val.nama_part_kayu,
      qty_raw: val.is_header ? null : val.qty_raw,
      t_raw: val.is_header ? null : val.t_raw,
      w_raw: val.is_header ? null : val.w_raw,
      l_raw: val.is_header ? null : val.l_raw,
      qty_final: val.is_header ? null : val.qty_final,
      t_final: val.is_header ? null : val.t_final,
      w_final: val.is_header ? null : val.w_final,
      l_final: val.is_header ? null : val.l_final,
      unit_price: val.is_header ? null : val.unit_price,
      konstanta: val.is_header ? null : val.konstanta,
      id_finishing_barang_jadi: val.is_header ? null : val.id_finishing_barang_jadi,
      nama_finishing_barang_jadi: val.nama_finishing_barang_jadi,
      id_tipe_sisi: val.is_header ? null : val.id_tipe_sisi,
      nama_tipe_sisi: val.is_header ? null : val.nama_tipe_sisi,
      urutan_item: val.urutan_item,
    };

    const formEditValidationSchema = Yup.object().shape({
      // id_kayu: val.is_header ? "" : Yup.string().required(),
      deskripsi: Yup.string().required(),
      id_jenis_kayu: val.is_header ? "" : Yup.string().required(),
      qty_raw: val.is_header ? "" : Yup.string().required(),
      t_raw: val.is_header ? "" : Yup.string().required(),
      w_raw: val.is_header ? "" : Yup.string().required(),
      l_raw: val.is_header ? "" : Yup.string().required(),
      qty_final: val.is_header ? "" : Yup.string().required(),
      t_final: val.is_header ? "" : Yup.string().required(),
      w_final: val.is_header ? "" : Yup.string().required(),
      l_final: val.is_header ? "" : Yup.string().required(),
      id_finishing_barang_jadi: val.is_header ? "" : Yup.string().required(),
      id_tipe_sisi: val.is_header ? "" : Yup.string().required(),
      unit_price: val.is_header ? "" : Yup.string().required(),
      konstanta: val.is_header ? "" : Yup.string().required(),
    });

    const formEditSubmitHandler = (values) => {
      const finalValue = dataHardwood.map((data) => {
        if (values.urutan_item.toString() === data.urutan_item.toString()) {
          return values;
        } else {
          return data;
        }
      });

      setDataHardwood(finalValue);
      setEditedData({});
      setAlertConfig({
        show: true,
        variant: "primary",
        text: "Data berhasil diubah!",
      });
    };

    const editFormik = useFormik({
      enableReinitialize: true,
      initialValues: formEditInitialValues,
      validationSchema: formEditValidationSchema,
      onSubmit: formEditSubmitHandler,
    });

    const { values, errors, touched, setFieldValue, handleChange, handleSubmit, setValues } =
      editFormik;

    // hitung luas(m2)
    const formtotalLuas = kalkulasiLuas(values);

    const formCountTotalUnit = parseFloat(
      (parseFloat(values.qty_raw).toFixed(6) *
        parseFloat(values.t_raw).toFixed(6) *
        parseFloat(values.w_raw).toFixed(6) *
        parseFloat(values.l_raw).toFixed(6)) /
      1000000
    ).toFixed(6);
    const formTotalUnit = parseFloat(formCountTotalUnit) < 0.000001 ? "0.000001" : parseFloat(formCountTotalUnit);
    const formTotalPrice = Math.round(parseFloat(formTotalUnit) * parseInt(values.unit_price) * parseFloat(values.konstanta)
    );

    return (
      <tr key={index}>
        <td className="px-1" style={tableStyling}>
          {index + 1}
        </td>
        <td style={{ ...tableStyling, width: "185px" }}>
          <SelectSearch
            placeholder="Pilih Deskripsi"
            value={values.id_deskripsi ? { value: values.id_deskripsi, label: values.deskripsi } : ""}
            options={dropdownDeskripsi.map((val) => Object({ ...val, value: val.id_deskripsi_bahan_baku, label: val.nama_deskripsi_bahan_baku }))}
            onChange={(val) => {
              setFieldValue("id_deskripsi", val.value);
              setFieldValue("deskripsi", val.label);
            }}
            error={errors.id_deskripsi && touched.id_deskripsi && true}
          />
        </td>
        <td style={{ ...tableStyling, width: "185px" }}>
          <SelectSearch
            placeholder="Pilih kayu"
            isDisabled={val.is_header ? true : false}
            value={
              values.id_jenis_kayu
                ? { value: values.id_jenis_kayu, label: values.nama_jenis_kayu }
                : ""
            }
            options={dropdownSpekKayu.map((val) => Object({ ...val, value: val.id_jenis_kayu, label: val.nama_jenis_kayu }))}
            onChange={(val) => {
              setFieldValue("id_jenis_kayu", val.value);
              setFieldValue("nama_jenis_kayu", val.label);
              // getHargaKubikasi(val.value, values.id_part_kayu, values.t_raw, values.w_raw, setFieldValue);
            }}
            error={errors.id_jenis_kayu && touched.id_jenis_kayu && true}
          />
        </td>
        <td style={{ ...tableStyling, minWidth: "150px", width: "170px" }}>
          <SelectSearch
            placeholder="Pilih part kayu"
            isDisabled={val.is_header ? true : false}
            value={values.id_part_kayu ? { value: values.id_part_kayu, label: values.nama_part_kayu } : ""}
            options={dropdownPart.map((val) => Object({ ...val, value: val.id_part_kayu, label: val.nama_part_kayu }))}
            onChange={(val) => {
              setFieldValue("id_part_kayu", val.value);
              setFieldValue("nama_part_kayu", val.label);
              // getHargaKubikasi(values.id_jenis_kayu, val.value, values.t_raw, values.w_raw, setFieldValue);
            }}
            error={errors.id_part_kayu && touched.id_part_kayu && true}
          />
        </td>
        <td style={{ ...tableStyling, minWidth: "110px", width: "135px" }}>
          <SelectSearch
            placeholder="Tipe Finishing"
            isDisabled={isFormHeader ? true : false}
            value={
              values.id_finishing_barang_jadi
                ? {
                  value: values.id_finishing_barang_jadi,
                  label: values.nama_finishing_barang_jadi,
                }
                : ""
            }
            options={dataTipeFinishing}
            onChange={(val) => {
              setFieldValue("id_finishing_barang_jadi", val.value);
              setFieldValue("nama_finishing_barang_jadi", val.label);
            }}
            error={errors.id_finishing_barang_jadi && touched.id_finishing_barang_jadi && true}
          />
        </td>
        <td style={{ ...tableStyling, minWidth: "110px", width: "135px" }}>
          <SelectSearch
            placeholder="Tipe Sisi"
            isDisabled={isFormHeader ? true : false}
            value={
              values.id_tipe_sisi
                ? { value: values.id_tipe_sisi, label: values.nama_tipe_sisi }
                : ""
            }
            options={dropdownTipeSisi.map((item) =>
              Object({ ...item, value: item.id_tipe_sisi, label: item.nama_tipe_sisi })
            )}
            onChange={(val) => {
              setFieldValue("id_tipe_sisi", val.value);
              setFieldValue("nama_tipe_sisi", val.label);
            }}
            error={errors.id_tipe_sisi && touched.id_tipe_sisi && true}
          />
        </td>
        <td style={{ ...tableStyling, width: "50px" }}>
          <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.qty_final}</Tooltip>}>
            <input
              name="qty_final"
              className={`form-control form-control-sm ${errors.qty_final && touched.qty_final ? "is-invalid" : ""
                }`}
              value={values.qty_final}
              onChange={(e) => {
                const value = e.target.value;
                const convert = decimalConvert(value);
                setValues({ ...values, qty_final: convert, qty_raw: convert });
              }}
              readOnly={val.is_header ? true : false}
            />
          </OverlayTrigger>
        </td>
        <td style={tableStyling}>
          <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.t_final}</Tooltip>}>
            <input
              name="t_final"
              className={`form-control form-control-sm ${errors.t_final && touched.t_final ? "is-invalid" : ""
                }`}
              value={values.t_final}
              onChange={(e) => {
                const value = e.target.value;
                const convert = decimalConvert(value);
                const valueRaw = parseInt(value) + 1;
                const totalRaw = decimalConvert(valueRaw);
                setValues({ ...values, t_final: convert, t_raw: totalRaw });
                //  getHargaKubikasi(values.id_jenis_kayu, values.id_part_kayu, totalRaw, values.w_raw, setFieldValue);
              }}
              readOnly={val.is_header ? true : false}
              style={{ width: "50px" }}
            />
          </OverlayTrigger>
        </td>
        <td style={tableStyling}>
          <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.w_final}</Tooltip>}>
            <input
              name="w_final"
              className={`form-control form-control-sm ${errors.w_final && touched.w_final ? "is-invalid" : ""
                }`}
              value={values.w_final}
              onChange={(e) => {
                const value = e.target.value;
                const convert = decimalConvert(value);
                const valueRaw = parseInt(value) + 1;
                const totalRaw = decimalConvert(valueRaw);
                setValues({ ...values, w_final: convert, w_raw: totalRaw });
                // getHargaKubikasi(values.id_jenis_kayu, values.id_part_kayu, values.t_raw, totalRaw, setFieldValue);
              }}
              readOnly={val.is_header ? true : false}
              style={{ width: "50px" }}
            />
          </OverlayTrigger>
        </td>
        <td style={tableStyling}>
          <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.l_final}</Tooltip>}>
            <input
              name="l_final"
              className={`form-control form-control-sm ${errors.l_final && touched.l_final ? "is-invalid" : ""
                }`}
              value={values.l_final}
              onChange={(e) => {
                const value = e.target.value;
                const convert = decimalConvert(value);
                setValues({ ...values, l_final: convert, l_raw: convert });
              }}
              readOnly={val.is_header ? true : false}
              style={{ width: "50px" }}
            />
          </OverlayTrigger>
        </td>
        <td style={{ ...tableStyling, width: "50px" }}>
          <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.qty_raw}</Tooltip>}>
            <input
              name="qty_raw"
              className={`form-control form-control-sm ${errors.qty_raw && touched.qty_raw ? "is-invalid" : ""
                }`}
              value={values.qty_raw}
              onChange={(e) => {
                const value = e.target.value;
                const convert = decimalConvert(value);
                setFieldValue("qty_raw", convert);
              }}
              readOnly={val.is_header ? true : false}
              style={{ width: "100%" }}
            />
          </OverlayTrigger>
        </td>
        <td style={tableStyling}>
          <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.t_raw}</Tooltip>}>
            <input
              name="t_raw"
              className={`form-control form-control-sm ${errors.t_raw && touched.t_raw ? "is-invalid" : ""
                }`}
              value={values.t_raw}
              onChange={(e) => {
                const value = e.target.value;
                const convert = decimalConvert(value);
                setFieldValue("t_raw", convert);
                // getHargaKubikasi(values.id_jenis_kayu, values.id_part_kayu, convert, values.w_raw, setFieldValue);
              }}
              readOnly={val.is_header ? true : false}
              style={{ width: "50px" }}
            />
          </OverlayTrigger>
        </td>
        <td style={tableStyling}>
          <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.w_raw}</Tooltip>}>
            <input
              name="w_raw"
              className={`form-control form-control-sm ${errors.w_raw && touched.w_raw ? "is-invalid" : ""
                }`}
              value={values.w_raw}
              onChange={(e) => {
                const value = e.target.value;
                const convert = decimalConvert(value);
                setFieldValue("w_raw", convert);
                // getHargaKubikasi(values.id_jenis_kayu, values.id_part_kayu, values.t_raw, convert, setFieldValue);
              }}
              readOnly={val.is_header ? true : false}
              style={{ width: "50px" }}
            />
          </OverlayTrigger>
        </td>
        <td style={tableStyling}>
          <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.l_raw}</Tooltip>}>
            <input
              name="l_raw"
              className={`form-control form-control-sm ${errors.l_raw && touched.l_raw ? "is-invalid" : ""
                }`}
              value={values.l_raw}
              onChange={(e) => {
                const value = e.target.value;
                const convert = decimalConvert(value);
                setFieldValue("l_raw", convert);
              }}
              readOnly={val.is_header ? true : false}
              style={{ width: "50px" }}
            />
          </OverlayTrigger>
        </td>
        <td className="text-right" style={tableStyling}>
          {val.is_header ? "" : formtotalLuas}
        </td>
        <td className="text-right" style={tableStyling}>
          {val.is_header ? "" : formTotalUnit}
        </td>
        <td style={tableStyling}>
          <OverlayTrigger
            trigger="focus"
            overlay={
              <Tooltip>
                {values.unit_price ? RupiahConvert(values.unit_price.toString()).detail : ""}
              </Tooltip>
            }
          >
            <input
              type="text"
              name="unit_price"
              placeholder="Masukan unit price"
              className={`form-control form-control-sm ${errors.unit_price ? "is-invalid" : ""}`}
              value={values.unit_price ? RupiahConvert(values.unit_price.toString()).detail : ""}
              onChange={(e) => {
                const value = e.target.value;
                const convert = RupiahConvert(value.toString()).default;
                convert ? setFieldValue("unit_price", convert) : setFieldValue("unit_price", "");
              }}
              readOnly={val.is_header ? true : false}
            />
          </OverlayTrigger>
        </td>
        <td style={tableStyling}>
          <OverlayTrigger trigger="focus" overlay={<Tooltip>{values.konstanta}</Tooltip>}>
            <input
              name="konstanta"
              placeholder="Masukan konstanta"
              className={`form-control form-control-sm ${errors.konstanta && touched.konstanta ? "is-invalid" : ""
                }`}
              value={values.konstanta}
              onChange={(e) => {
                const value = e.target.value;
                const convert = decimalConvert(value);
                setFieldValue("konstanta", convert);
              }}
              readOnly={val.is_header ? true : false}
              style={{ width: "50px" }}
            />
          </OverlayTrigger>
        </td>
        <td className="text-right text-nowrap" style={tableStyling}>
          {val.is_header ? "" : RupiahConvert(formTotalPrice.toString()).detail}
        </td>
        <td style={tableStyling}>
          <div className="d-flex justify-content-around align-items-center btn-group px-1">
            <ActionButton
              size="sm"
              variant="outline-success"
              text={<IoCheckmarkOutline />}
              onClick={handleSubmit}
            />
            <ActionButton
              size="sm"
              variant="outline-danger"
              text={<IoCloseOutline />}
              onClick={() => setEditedData({})}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="p-2 mt-3">
        <b>List Hardwood</b>
      </div>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      <div className="table-responsive">
        <table className="table table-bordered bg-white table-sm">
          <thead className="text-center">
            <tr>
              <th rowSpan={3} className="align-middle" style={tableStyling}>
                No.
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Deskripsi
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Spesifikasi Kayu
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Part Kayu
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Tipe Finishing
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Tipe Sisi
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Qty Final
              </th>
              <th colSpan={3} className="align-middle" style={tableStyling}>
                Final (cm)
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Qty Raw
              </th>
              <th colSpan={3} className="align-middle" style={tableStyling}>
                Raw (cm)
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Luas (m2)
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Volume (m3)
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Unit Price (Rp)
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Konst.
              </th>
              <th rowSpan={2} className="align-middle" style={tableStyling}>
                Sub Total Price (Rp)
              </th>
            </tr>
            <tr>
              <th style={tableStyling}>T</th>
              <th style={tableStyling}>W</th>
              <th style={tableStyling}>L</th>
              <th style={tableStyling}>T</th>
              <th style={tableStyling}>W</th>
              <th style={tableStyling}>L</th>
            </tr>
          </thead>
          <tbody>
            {/* INPUT */}
            {dataHardwood &&
              dataHardwood.length > 0 &&
              dataHardwood.map((val, index) => {
                const lastItem = parseInt(val.urutan_item) ? parseInt(val.urutan_item) + 1 : 1;

                // store totalLuas ke dalam obj prop total_luas
                const totalLuas = kalkulasiLuas(val);
                dataHardwood[index].total_luas = totalLuas;

                const countTotalUnit = parseFloat(
                  (parseFloat(val.qty_raw).toFixed(6) *
                    parseFloat(val.t_raw).toFixed(6) *
                    parseFloat(val.w_raw).toFixed(6) *
                    parseFloat(val.l_raw).toFixed(6)) /
                  1000000
                ).toFixed(6);
                const totalUnit = parseFloat(countTotalUnit) < parseFloat(0.000001) ? parseFloat(0.000001) : parseFloat(countTotalUnit);

                // store totalUnit ke dalam obj prop total_unit
                dataHardwood[index].total_unit = totalUnit;
                const totalPrice = Math.round(parseFloat(totalUnit) * parseInt(val.unit_price) * parseFloat(val.konstanta));
                const checkUnit = val.is_header ? 0 : totalUnit;
                const checkPrice = val.is_header ? 0 : totalPrice;
                subTotalUnit = parseFloat(parseFloat(subTotalUnit) + parseFloat(checkUnit)).toPrecision();
                subTotalPrice = parseInt(parseInt(subTotalPrice) + parseInt(checkPrice));
                lastUrutanItem = lastItem;

                return editedData === val ? (
                  <EditDataTable
                    index={index}
                    val={val}
                    dataHardwood={dataHardwood}
                    setDataHardwood={setDataHardwood}
                  />
                ) : (
                  <ListDataTable
                    index={index}
                    val={val}
                    totalUnit={totalUnit}
                    totalPrice={totalPrice}
                    editedData={editedData}
                    setEditedData={setEditedData}
                    setProcessedData={setProcessedData}
                    setModalConfig={setModalConfig}
                  />
                );
              })}
            <tr>
              <td colSpan={14} className="text-right p-2" style={tableStyling}>
                <b>Total Luas dan Volume : </b>
              </td>
              <td className="text-right " style={tableStyling}>
                <b>
                  {parseFloat(dataHardwood.reduce((subTotalLuas, { total_luas }) => parseFloat(subTotalLuas) + parseFloat(total_luas ?? 0), 0)).toPrecision(6)}
                </b>
              </td>
              <td className="text-right pl-2" style={tableStyling}>
                <b>{parseFloat(subTotalUnit).toPrecision(6)}</b>
              </td>
              <td colSpan={2} className="text-right p-2" style={tableStyling}>
                <b>Total Price : </b>
              </td>
              <td className="text-right text-nowrap" style={tableStyling}>
                <b>{RupiahConvert(subTotalPrice.toString()).detail}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <PageModal />
    </>
  );
};

export default TableListHardwood;
