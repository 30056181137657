import { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import {
  Alert,
  BackButton,
  DataStatus,
  ActionButton,
  Approval
} from 'components'
import { FakturPenjualanApi } from 'api'
import {
  InfoSection,
  FormSection,
  TableSection,
  TableHistory
} from '../Section'

const UbahFakturPenjualan = ({ setNavbarTitle }) => {
  const { id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const { id_sales_order, status_approval } = location?.state
  const [dataInfo, setDataInfo] = useState({})
  const [dataTable, setDataTable] = useState([])
  const [dataPrelim, setDataPrelim] = useState([])
  const [dataHistory, setDataHistory] = useState([])
  const [isPrelim, setIsPrelim] = useState(false)
  const [dataStakeholder, setDataStakeholder] = useState([])
  const [formRendered, setFormRendered] = useState(0)
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })

  const mappingDataInfo = (data) => {
    return {
      id_faktur_penjualan: data.id_faktur_penjualan ?? null,
      tgl_faktur_penjualan: data.tgl_faktur ?? null,
      no_faktur_penjualan: data.no_faktur ?? '-',
      id_sales_order: data.id_sales_order ?? null,
      tgl_sales_order: data.tgl_sales_order ?? null,
      batas_waktu: data.batas_waktu ?? null,
      no_sales_order: data.no_sales_order ?? '-',
      customer: data.nama_customer ?? '-',
      sales: data.nama_sales ?? '-',
      persentase_prelim: data.persentase_prelim
        ? parseFloat(data.persentase_prelim)
        : 0,
      diskon: data.diskon ? parseInt(data.diskon) : 0,
      ppn: data.ppn ? parseInt(data.ppn) : 0,
      nama_proyek: data.nama_proyek ?? '-',
      uang_muka: parseFloat(data.uang_muka ?? 0),
      akumulasi_prelim: data.akumulasi_prelim ?? 0
    }
  }

  const mappingDataPrelim = (data) => {
    const prelim = data?.map((item, index) =>
      Object({
        ...item,
        id_item: item.id_item_prelim,
        kode_item: item.kode_item_prelim,
        nama_item: item.nama_item_prelim,
        qty_item: item.qty_analisa,
        id_unit: item.id_satuan_prelim,
        nama_unit: item.nama_satuan_prelim,
        harga: parseFloat(item.unit_price ?? 0),
        qty_durasi: parseFloat(item.qty_durasi ?? 0),
        konstanta: parseFloat(item.konstanta ?? 0),
        order: index + 1
      })
    )

    return prelim
  }

  const mappingDataTable = (data) =>
    data.map((val) => {
      return {
        id_surat_jalan: val.id_surat_jalan ?? null,
        id_surat_jalan_detail: val.id_surat_jalan_detail ?? null,
        tgl_penerimaan_surat_jalan: val.tgl_penerimaan_surat_jalan ?? null,
        no_penerimaan_surat_jalan: val.no_penerimaan_surat_jalan ?? '-',
        tgl_surat_jalan: val.tgl_surat_jalan ?? null,
        no_surat_jalan: val.no_surat_jalan ?? '-',
        no_delivery_order: val.no_delivery_order ?? '-',
        nama_item: val.nama_item ?? '-',
        satuan: val.nama_satuan ?? '',
        qty: val.qty ? parseFloat(val.qty) : 0,
        harga: val.harga_satuan_jual ? parseInt(val.harga_satuan_jual) : 0,
        checked: false,
        instalasi: val.instalasi,
        persentase_progress: parseFloat(
          val.akumulasi_progress ?? 0
        ).toPrecision(),
        akumulasi_progress: parseFloat(
          val.akumulasi_progress ?? 0
        ).toPrecision()
      }
    })

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    axios
      .all([
        FakturPenjualanApi.getSingleFaktur({ id_faktur_penjualan: id }),
        FakturPenjualanApi.getHistoryFaktur({ id_sales_order })
      ])
      .then(
        axios.spread((resSingle, resHistory) => {
          const getData = resSingle?.data?.data
          const getHistory = resHistory?.data?.data ?? []

          const checkPrelim = getData.prelim ? true : false

          const mapDataInfo = mappingDataInfo(getData ?? {})
          const mapDataTable = mappingDataTable(getData.detail ?? [])
          const mapDataPrelim = mappingDataPrelim(getData.prelim ?? [])

          setIsPrelim(checkPrelim)
          setDataInfo(mapDataInfo)
          setDataTable(mapDataTable)
          setDataStakeholder(getData)
          setDataPrelim(mapDataPrelim)
          setDataHistory(getHistory)
          setFetchingStatus({
            loading: false,
            success: true
          })
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({ loading: false, success: false })
      })
  }

  const formInitialValues = {
    id_sales_order: dataInfo.id_sales_order,
    tanggal: dataInfo.tgl_faktur_penjualan,
    nomor: dataInfo.no_faktur_penjualan,
    persentase_prelim: dataInfo.persentase_prelim,
    diskon: dataInfo.diskon,
    ppn: dataInfo.ppn,
    uang_muka: dataInfo.uang_muka,
    akumulasi_prelim: dataInfo.akumulasi_prelim
  }

  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required('Pilih tanggal'),
    nomor: Yup.string().required('Pilih tanggal untuk menentukan nomor')
  })

  const formSubmitHandler = (values, { setSubmitting }) => {
    const detail =
      dataTable
        ?.filter((data) => Boolean(data.checked))
        .map(
          ({ id_surat_jalan, id_surat_jalan_detail, persentase_progress }) => ({
            id_surat_jalan,
            id_surat_jalan_detail,
            persentase_progress
          })
        ) ?? []

    const finalValues = {
      id_faktur_penjualan: id,
      id_sales_order: values.id_sales_order,
      tgl_faktur: values.tanggal,
      persentase_prelim: values.persentase_prelim,
      ppn: values.ppn,
      diskon: values.diskon,
      detail
    }

    FakturPenjualanApi.update(finalValues)
      .then(() => {
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil diubah!'
        })
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal diubah!'
        })
      })
      .finally(() => setSubmitting(false))
  }

  useEffect(() => {
    getInitialData()
    setNavbarTitle('Faktur Penjualan')
  }, [])

  return (
    <>
      <Card style={{ minHeight: '80vh' }}>
        <Card.Header className='d-flex justify-content-between align-items-center'>
          <b>Ubah Data Faktur Penjualan</b>
          <BackButton size='md' onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body className='pb-5'>
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text='Memuat . . .' />
          ) : fetchingStatus.success ? (
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <InfoSection dataInfo={dataInfo} />
                  <hr className='mb-1' />
                  <FormSection
                    formik={formik}
                    formRendered={formRendered}
                    fetchingStatus={fetchingStatus}
                    setFormRendered={setFormRendered}
                  />
                  <hr className='mt-1' />
                  <TableSection
                    formik={formik}
                    dataTable={dataTable}
                    setDataTable={setDataTable}
                    dataPrelim={dataPrelim}
                    setDataPrelim={setDataPrelim}
                    dataHistory={dataHistory}
                    isPrelim={isPrelim}
                    statusApproval={status_approval}
                  />
                  <hr />
                  <TableHistory dataHistory={dataHistory} />
                  <div className='d-flex justify-content-end'>
                    <ActionButton
                      type='submit'
                      variant='success'
                      text='Ubah Faktur Penjualan'
                      loading={formik.isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          ) : (
            <DataStatus text='Data gagal dimuat' />
          )}
        </Card.Body>
      </Card>
      {!fetchingStatus.loading && fetchingStatus.success && (
        <Approval title='Faktur Penjualan' data={dataStakeholder} />
      )}
    </>
  )
}

export default UbahFakturPenjualan
