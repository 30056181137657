import React, { useState, useEffect } from 'react'
import { Card, Modal } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Axios from 'axios'
import { Alert, BackButton, ActionButton, DataStatus } from 'components'
import { Form, InfoSection, TableBarangJadi, ModalPrelim } from '../Section'
import { SalesOrderSPKApi } from 'api'
import AnalisaBarangJadiSOSPK from '../AnalisaBarangJadi'

const TambahSOSPK = ({ setNavbarTitle }) => {
  const history = useHistory()
  const { id_spk } = useParams()
  const id_unit_produksi = sessionStorage.getItem('id_unit_produksi')
  const [dataSPK, setDataSPK] = useState({})
  const [dataKaryawan, setDataKaryawan] = useState({})
  const [dataBarangJadi, setDataBarangJadi] = useState([])
  const [dataPrelim, setDataPrelim] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isFetchingFailed, setIsFetchingFailed] = useState(false)
  const [processedData, setProcessedData] = useState({})
  const [modalPrelim, setModalPrelim] = useState({ show: false })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: ''
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })

  const getInitialData = () => {
    setIsFetchingFailed(false)
    setIsPageLoading(true)

    Axios.all([
      SalesOrderSPKApi.getSingleSPK({ id_spk, id_unit_produksi }),
      SalesOrderSPKApi.getKaryawan()
    ])
      .then(
        Axios.spread((resSingle, resKaryawan) => {
          const data = resSingle?.data?.data ?? {}
          const karyawan = resKaryawan?.data?.data?.map((item) =>
            Object({
              ...item,
              value: item.id_karyawan,
              label: item.nama_karyawan
            })
          )
          const barangJadi = data?.detail_barang_jadi?.map((item) =>
            Object({
              ...item,
              satuan: item.nama_satuan,
              qty: parseFloat(item.qty_rab),
              harga_satuan: item.rounded ?? 0
            })
          )
          const prelim = data?.prelim?.map((item, index) =>
            Object({
              ...item,
              id_item: item.id_item_prelim,
              kode_item: item.kode_item_prelim,
              nama_item: item.nama_item_prelim,
              qty_item: item.qty_analisa,
              id_unit: item.id_satuan_prelim,
              nama_unit: item.nama_satuan_prelim,
              harga: parseFloat(item.unit_price ?? 0),
              qty_durasi: parseFloat(item.qty_durasi ?? 0),
              konstanta: parseFloat(item.konstanta ?? 0),
              order: index + 1
            })
          )

          setDataSPK(data)
          setDataKaryawan(karyawan)
          setDataPrelim(prelim)
          setDataBarangJadi(barangJadi)
        })
      )
      .catch(() => {
        setIsFetchingFailed(true)
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!'
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const isValidGudang = () => {
    const checkData = dataBarangJadi?.map((item) =>
      item.id_gudang ? true : false
    )
    const isValid = checkData.every((item) => item === true)
    if (!isValid) {
      setAlertConfig({
        show: true,
        variant: 'danger',
        text: 'Gudang pada Item Barang Jadi harus diisi!'
      })
    }
    return isValid
  }

  const formInitialValues = {
    id_spk: id_spk,
    id_rab: dataSPK?.id_rab,
    tgl_sales_order_spk: '',
    no_sales_order_spk: '',
    tgl_batas_waktu: '',
    id_sales: '',
    diskon: parseFloat(dataSPK?.diskon ?? 0),
    ppn: parseFloat(dataSPK?.ppn ?? 0),
    uang_muka: parseFloat(dataSPK?.uang_muka ?? 0)
  }

  const formValidationSchema = Yup.object().shape({
    tgl_sales_order_spk: Yup.string().required('Pilih tanggal Sales Order SPK'),
    no_sales_order_spk: Yup.string().required(
      'Pilih tanggal untuk menentukan nomor Sales Order SPK'
    ),
    tgl_batas_waktu: Yup.string().required('Pilih tanggal Batas Waktu'),
    id_sales: Yup.string().required('Pilih Sales')
  })
  const formSubmitHandler = (values, { setSubmitting }) => {
    const mapBarangJadi = dataBarangJadi?.map((item) =>
      Object({
        id_item_buaso: item.id_item_buaso,
        id_item_atribut: item.id_item_atribut,
        id_gudang: item.id_gudang,
        qty_sales_order_spk: item.qty_rab,
        harga_rate: parseFloat(item.harga_satuan)
      })
    )

    const finalValues = {
      ...values,
      id_karyawan: values.id_sales,
      id_rab: values.id_rab,
      detail: mapBarangJadi
    }

    SalesOrderSPKApi.create(finalValues)
      .then(() =>
        history.push('/transaksi/sales-order-spk', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Data berhasil ditambah!'
          }
        })
      )
      .catch(() => {
        setSubmitting(false)
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal ditambah!'
        })
      })
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler
  })

  useEffect(() => {
    setNavbarTitle('Sales Order SPK')
    getInitialData()
  }, [])

  const ModalSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiSOSPK
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      )
    }

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'analisa' ? 'xl' : 'md'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false
          })
        }
      >
        <Modal.Header closeButton className='py-2'>
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    )
  }

  return (
    <Card>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        <b>Tambah Data Sales Order SPK</b>
        <div className='d-flex align-items-center justify-content-top'>
          <BackButton onClick={() => history.goBack()} />
        </div>
      </Card.Header>
      <Card.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
        />
        {isPageLoading ? (
          <DataStatus loading={true} text='Memuat data . . .' />
        ) : isFetchingFailed ? (
          <DataStatus text='Data gagal dimuat, tidak dapat menambah data!' />
        ) : (
          <>
            <InfoSection data={dataSPK} />
            <hr />
            <Form type='CREATE' formik={formik} dataKaryawan={dataKaryawan} />
            <TableBarangJadi
              dataBarangJadi={dataBarangJadi}
              setDataBarangJadi={setDataBarangJadi}
              setModalPrelim={setModalPrelim}
              setProcessedData={setProcessedData}
              setModalConfig={setModalConfig}
              dataPrelim={dataPrelim}
              diskon={formik.values.diskon}
              ppn={formik.values.ppn}
              uangMuka={formik.values.uang_muka}
              setFieldValue={formik.setFieldValue}
            />
            <div className='mt-4 d-flex justify-content-end align-items-center'>
              <ActionButton
                text='Simpan'
                loading={formik.isSubmitting}
                onClick={() => isValidGudang() && formik.handleSubmit()}
              />
            </div>

            <ModalSection
              processedData={processedData}
              dataBarangJadi={dataBarangJadi}
              setDataBarangJadi={setDataBarangJadi}
              alertConfig={alertConfig}
              setAlertConfig={setAlertConfig}
              modalConfig={modalConfig}
              setModalConfig={setModalConfig}
            />

            {modalPrelim.show && (
              <ModalPrelim
                show={modalPrelim.show}
                onHide={() => setModalPrelim({ show: false })}
                dataPrelim={dataPrelim}
                onSubmit={(val) => {
                  setDataPrelim(val)
                  setModalPrelim({ show: false })
                }}
              />
            )}
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default TambahSOSPK
