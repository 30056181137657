import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import {
  Th,
  Tr,
  Td,
  TBody,
  THead,
  ThFixed,
  TdFixed,
  DataStatus,
  DatePicker,
  Pagination,
  CRUDLayout,
  SelectSearch,
  ActionButton,
} from "components";
import Axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { saveAs } from "file-saver";
import { DateConvert, TableNumber, RupiahConvert } from "utilities";
import { LaporanFakturPenjualanApi } from "api";

const LaporanFakturPenjualan = ({ setNavbarTitle }) => {
  //STATE DATA
  const [dataLaporanFakturPenjualan, setDataLaporanFakturPenjualan] = useState([]);

  const [tanggalMulai, setTanggalMulai] = useState(undefined);
  const [tanggalSelesai, setTanggalSelesai] = useState(undefined);
  const [dataCustomer, setDataCustomer] = useState(undefined);
  const [namaBarang, setNamaBarang] = useState(undefined);
  const [dataProyek, setDataProyek] = useState(undefined);

  const [dropdownProyek, setDropdownProyek] = useState([]);
  const [dropdownBarangJadi, setDropdownBarangJadi] = useState([]);
  const [dropdownCustomer, setDropdownCustomer] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const checkInput = Boolean(
    tanggalMulai || tanggalSelesai || dataCustomer || namaBarang || dataProyek
  );
  //MAPING DATA DROPDOWN
  const mapProyek = (data) =>
    data.map((val) => {
      return {
        label: val.nama_proyek,
        value: val.nama_proyek,
      };
    });
  const mapBarangJadi = (data) =>
    data.map((val) => {
      return {
        label: val.nama_item,
        value: val.nama_item,
      };
    });
  const mapCustomer = (data) =>
    data.map((val) => {
      return {
        label: val.nama_customer,
        value: val.nama_customer,
      };
    });
  //CONFIG
  const [fetchConfig, setFetchConfig] = useState({
    loading: true,
    status: false,
  });
  const [paginationConfig, setPaginationConfig] = useState({
    currentPage: 1,
    dataLength: 10,
    dataCount: 0,
    totalPage: 1,
  });
  //REQUEST DATA SERVER
  const getDropdown = () => {
    setDropdownLoading(true);
    Axios.all([
      LaporanFakturPenjualanApi.getDropdown({ tipe: "proyek" }),
      LaporanFakturPenjualanApi.getDropdown({ tipe: "barang_jadi" }),
      LaporanFakturPenjualanApi.getDropdown({ tipe: "customer" }),
    ])
      .then(
        Axios.spread((pro, bar, cus) => {
          const dataProyek = mapProyek(pro?.data?.data ?? []);
          const dataBarangJadi = mapBarangJadi(bar?.data?.data ?? []);
          const dataCustomer = mapCustomer(cus?.data?.data ?? []);

          setDropdownProyek(dataProyek);
          setDropdownBarangJadi(dataBarangJadi);
          setDropdownCustomer(dataCustomer);

          setDropdownLoading(false);
        })
      )
      .catch(() => <DataStatus text="Data gagal dimuat..." />)
      .finally(() => setDropdownLoading(false));
  };
  //DATA TABLE
  const getInitialData = () => {
    setFetchConfig({ ...fetchConfig, loading: true });
    LaporanFakturPenjualanApi.getPage({
      tanggal_start: tanggalMulai,
      tanggal_end: tanggalSelesai,
      nama_customer: dataCustomer,
      nama_barang: namaBarang,
      nama_proyek: dataProyek,
      page: paginationConfig.currentPage,
      per_page: paginationConfig.dataLength,
    })
      .then((res) => {
        setDataLaporanFakturPenjualan(res?.data?.data ?? []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: res?.data?.data_count ?? 0,
          totalPage: res?.data?.total_page ?? 1,
        });
        setFetchConfig({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setFetchConfig({
          loading: false,
          success: false,
        });
      });
  };
  //HANDLE CLICK DATA
  const getData = () => {
    setShowTable(true);
    getInitialData();
  };

  const getExportData = (tipe) => {
    LaporanFakturPenjualanApi.getExport({
      tipe: tipe,
      tanggal_start: tanggalMulai,
      tanggal_end: tanggalSelesai,
      nama_customer: dataCustomer,
      nama_barang: namaBarang,
      nama_proyek: dataProyek,
      page: paginationConfig.currentPage,
      per_page: paginationConfig.dataLength,
    })
      .then((res) => {
        const link = res?.data?.data;
        const parse =
          "https://" +
          link
            .split("/")
            .filter((_val, idx) => idx !== 0)
            .filter((_val, idx) => idx !== 0)
            .join("/");
        saveAs(parse, "Laporan Faktur Penjualan");
      })
      .catch(() => {
        window.alert("Export data gagal");
      });
  };
  //USE EFFECT
  useEffect(() => {
    getDropdown();
    setNavbarTitle("Laporan Faktur Penjualan");
  }, []);

  useEffect(() => {
    checkInput && getInitialData();
  }, [setNavbarTitle, paginationConfig.currentPage, paginationConfig.dataLength]);
  //TABLE
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <Th>Tgl.Faktur</Th>
              <Th>No.Faktur Penjualan</Th>
              <Th>No. Sales Order</Th>
              <Th>No. Surat Jalan</Th>
              <Th>No. Delivery Order</Th>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th>Sales</Th>
              <Th>Description (Barang Jadi)</Th>
              <Th>Qty.</Th>
              <Th>Harga Satuan</Th>
              <Th>Diskon</Th>
              <Th>PPN</Th>
              <Th>Jumlah</Th>
            </Tr>
          </THead>
          <TBody>
            {dataLaporanFakturPenjualan.length === 0 ? (
              <Tr>
                <Td colSpan={13}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            ) : (
              dataLaporanFakturPenjualan.map((val, index) => {
                const subTotal = val.harga_satuan_jual * val.qty;
                const totalDiskon = subTotal - (val.diskon / 100) * subTotal;
                const totalPPN = totalDiskon + (val.ppn / 100) * totalDiskon;
                return (
                  <Tr key={index}>
                    <TdFixed textCenter>
                      {TableNumber(
                        paginationConfig.currentPage,
                        paginationConfig.dataLength,
                        index
                      )}
                    </TdFixed>
                    <Td>{val.tgl_faktur ?? "-"}</Td>
                    <Td>{val.no_faktur ?? "-"}</Td>
                    <Td>{val.no_sales_order ?? "-"}</Td>
                    <Td>{val.no_surat_jalan ?? "-"}</Td>
                    <Td>{val.no_delivery_order ?? "-"}</Td>
                    <Td>{val.nama_customer ?? "-"}</Td>
                    <Td>{val.nama_proyek ?? "-"}</Td>
                    <Td>{val.nama_sales ?? "-"}</Td>
                    <Td>{val.nama_item ?? "-"}</Td>
                    <Td>{val.qty ?? "-"}</Td>
                    <Td>{RupiahConvert(val.harga_satuan_jual.toString()).detail ?? "-"}</Td>
                    <Td>{val.diskon ? parseFloat(val.diskon).toPrecision() + " %" : "-"}</Td>
                    <Td>{val.ppn ? parseFloat(val.ppn).toPrecision() + " %" : "-"}</Td>
                    <Td>{RupiahConvert(totalPPN.toString()).detail ?? "-"}</Td>
                  </Tr>
                );
              })
            )}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.currentPage * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.currentPage * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.currentPage}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({ ...paginationConfig, currentPage: selected + 1 })
          }
          onDataLengthChange={(e) => {
            setPaginationConfig({
              ...paginationConfig,
              currentPage: 1,
              dataLength: e.target.value,
            });
          }}
        />
      </>
    );
  };
  //STYLE
  const stylePeriode = {
    marginRight: "-10px",
    marginLeft: "20px",
    marginTop: "28px",
    fontSize: "15px",
  };
  const styleFilter = {
    marginLeft: "15px",
    marginRight: "5px",
    marginTop: "28px",
    fontSize: "15px",
  };
  const styleSubmitBtn = { paddingTop: "21px", marginLeft: "auto", marginRight: "22px" };
  const styleSD = { marginLeft: "6px", marginRight: "6px", marginTop: "28px", fontSize: "15px" };

  return (
    <div>
      <Card className="p-2 mb-3">
        <Row>
          <span style={stylePeriode}>Periode:</span>
          <Col md={3} className="d-flex">
            <DatePicker
              type="date"
              label="Tgl. Mulai"
              calendarIcon="Calendar"
              name="tanggalMulai"
              placeholderText="Masukkan tanggal..."
              autoComplete="off"
              defaultValue={tanggalMulai}
              onChange={(date) => {
                const value = DateConvert(new Date(date)).default;
                setTanggalMulai(value);
              }}
              selected={tanggalMulai ? new Date(tanggalMulai) : ""}
            />
            <span style={styleSD}>s/d</span>
            <DatePicker
              type="date"
              label="Tgl. Selesai"
              name="tanggalSelesai"
              placeholderText="Masukkan tanggal..."
              autoComplete="off"
              defaultValue={tanggalSelesai}
              onChange={(date) => {
                const value = DateConvert(new Date(date)).default;
                setTanggalSelesai(value);
              }}
              selected={tanggalSelesai ? new Date(tanggalSelesai) : ""}
            />
          </Col>
          <span style={styleFilter}>Filter By:</span>
          <div style={{ width: "150px" }}>
            <SelectSearch
              label="Customer"
              type="select"
              placeholder="Pilih salah satu..."
              name="id_customer"
              defaulValue={
                dropdownCustomer.nama_customer
                  ? dropdownCustomer.find((val) => dropdownCustomer.nama_customer === val.value)
                  : ""
              }
              option={dropdownCustomer}
              onChange={(e) => {
                setDataCustomer(e.value);
              }}
              loading={dropdownLoading}
            />
          </div>
          <div className="ml-2" style={{ width: "150px" }}>
            <SelectSearch
              label="Barang Jadi"
              type="select"
              placeholder="Pilih salah satu..."
              name="id_item_atribut"
              defaultValue={
                dropdownBarangJadi.nama_item
                  ? dropdownBarangJadi.find((val) => dropdownBarangJadi.nama_item === val.value)
                  : ""
              }
              option={dropdownBarangJadi}
              onChange={(e) => {
                setNamaBarang(e.value);
              }}
              loading={dropdownLoading}
            />
          </div>
          <div className="ml-2" style={{ width: "150px" }}>
            <SelectSearch
              label="Proyek"
              type="select"
              placeholder="Pilih salah satu..."
              name="id_proyek"
              option={dropdownProyek}
              defaultValue={
                dropdownProyek.nama_proyek
                  ? dropdownProyek.find((val) => dropdownProyek.val.nama_proyek === val.value)
                  : ""
              }
              onChange={(val) => setDataProyek(val.value)}
              loading={dropdownLoading}
            />
          </div>
          <div style={styleSubmitBtn}>
            <ActionButton type="submit" text="Lihat Data" onClick={getData} />
          </div>
        </Row>
      </Card>
      {showTable === true ? (
        fetchConfig.loading === true ? (
          <DataStatus loading={true} text="Memuat Data..." />
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-end mb-3">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Faktur Penjualan</b>
              </span>
              <div>
                <ActionButton
                  className="text-right"
                  variant="outline-danger"
                  text="Export PDF"
                  onClick={() => getExportData("pdf")}
                />
                <ActionButton
                  className="text-right ml-2"
                  variant="outline-success"
                  text="Export Excel"
                  onClick={() => getExportData("excel")}
                />
              </div>
            </div>
            <Table />
          </>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default LaporanFakturPenjualan;
