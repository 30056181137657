import {
  useState,
  useMemo,
} from 'react'
import {
  IoCheckmarkOutline,
  IoCloseOutline,
  IoPencilOutline
} from 'react-icons/io5'
import {
  ButtonGroup
} from 'react-bootstrap'
import {
  useFormik
} from 'formik'
import * as Yup from 'yup'
import {
  Table,
  Th,
  Td,
  ActionButton,
  Input
} from 'components'
import {
  RupiahConvert
} from 'utilities'

const TableListAnalisaAlatBantuOnSite = ({ dataAlatBantuOnSite, dataHardwood, dataPlywood, setDataAlatBantuOnSite }) => {
  const [isEdit, setIsEdit] = useState(false)

  const generateUnitPrice = useMemo(() => {
    const totalHW = dataHardwood.reduce((prev, val) => {
      const countTotalUnit = parseFloat(
        (parseFloat(val.qty_raw).toFixed(6) *
          parseFloat(val.t_raw).toFixed(6) *
          parseFloat(val.w_raw).toFixed(6) *
          parseFloat(val.l_raw).toFixed(6)) /
        1000000
      ).toFixed(6)

      const totalUnit = parseFloat(countTotalUnit) < 0.000001 ? "0.000001" : parseFloat(countTotalUnit)
      const totalPrice = Math.round(parseFloat(totalUnit) * parseInt(val.unit_price) * parseFloat(val.konstanta))
      const checkPrice = val.is_header ? 0 : totalPrice
      const totalHardwood = parseInt(checkPrice)

      return prev + totalHardwood
    }, 0)

    const totalPW = dataPlywood.reduce((prev, current) => {
      const total = Math.round(parseFloat(current.qty_raw).toFixed(6) * parseInt(current.unit_price ?? 0) * parseFloat(current.konstanta).toFixed(6))
      return parseInt(prev) + parseInt(total || 0)
    }, 0)

    return totalHW + totalPW
  }, [dataHardwood, dataPlywood])

  const generateTotal = (qty, konst, price) => {
    const getQty = qty ? parseFloat(qty) : 0
    const getKonst = konst ? parseFloat(konst) : 0
    const getPrice = price ? parseFloat(price) : 0

    return parseInt(getQty * getKonst * getPrice)
  }

  const checkInitialPrice = useMemo(() => {
    if (dataAlatBantuOnSite[0]?.unit_price) {
      return dataAlatBantuOnSite[0]?.unit_price ?? 0
    }

    return generateUnitPrice ?? 0
  }, [dataAlatBantuOnSite])

  const FormTable = () => {
    const formInitialValues = {
      qty: dataAlatBantuOnSite[0]?.qty ?? 0,
      unit_price: checkInitialPrice,
      konstanta: dataAlatBantuOnSite[0]?.konstanta ?? 0.03
    }

    const formValidationSchema = Yup.object().shape({
      qty: Yup.string().required(),
      unit_price: Yup.string().required(),
      konstanta: Yup.string().required(),
    })

    const formSubmitHandler = values => {
      setDataAlatBantuOnSite([{
        ...dataAlatBantuOnSite[0],
        ...values
      }])
      setIsEdit(false)
    }

    const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler
    })

    return (
      <tr>
        <Td textCenter>1</Td>
        <Td width={90}>50.000019</Td>
        <Td width={400}>Alat Bantu On-Site</Td>
        <Td width={100}>
          <Input
            noMargin
            value={values.qty}
            onChange={e => setFieldValue('qty', e.target.value)}
            error={Boolean(errors.unit_price && touched.unit_price)}
          />
        </Td>
        <Td>Lumpsum</Td>
        <Td width={200}>
          <Input
            noMargin
            value={RupiahConvert(String(values.unit_price)).withoutLabel}
            onChange={e => {
              const newValue = RupiahConvert(String(e.target.value.length > 0 ? e.target.value : 0)).default
              setFieldValue('unit_price', newValue)
            }}
            error={Boolean(errors.unit_price && touched.unit_price)}
          />
        </Td>
        <Td width={100}>
          <Input
            noMargin
            value={values.konstanta}
            onChange={e => setFieldValue('konstanta', e.target.value)}
            error={Boolean(errors.unit_price && touched.unit_price)}
          />
        </Td>
        <Td textRight>{RupiahConvert(String(generateTotal(values?.qty, values?.unit_price, values?.konstanta))).withoutLabel}</Td>
      </tr>
    )
  }

  return (
    <div>
      <div className="p-1">
        <b>Alat bantu On-Site</b>
      </div>
      <Table>
        <thead className="bg-light">
          <tr>
            <Th width={20}>No</Th>
            <Th width={90}>Kode Item</Th>
            <Th width={400}>Item Labour Cost</Th>
            <Th>Qty</Th>
            <Th>Unit</Th>
            <Th>Unit Price (Rp)</Th>
            <Th>Konst.</Th>
            <Th>Sub Total Price (Rp)</Th>
          </tr>
        </thead>
        <tbody>
          {isEdit
            ? <FormTable />
            : <tr>
              <Td textCenter>1</Td>
              <Td>{dataAlatBantuOnSite[0]?.kode_item}</Td>
              <Td>{dataAlatBantuOnSite[0]?.nama_item}</Td>
              <Td textRight>{dataAlatBantuOnSite[0]?.qty ? parseFloat(dataAlatBantuOnSite[0]?.qty) : 0}</Td>
              <Td>{dataAlatBantuOnSite[0]?.nama_satuan}</Td>
              <Td textRight>{RupiahConvert(String(checkInitialPrice ?? 0))?.withoutLabel}</Td>
              <Td textRight>{dataAlatBantuOnSite[0]?.konstanta ? parseFloat(dataAlatBantuOnSite[0]?.konstanta).toPrecision() : 0.03}</Td>
              <Td textRight>{RupiahConvert(String(generateTotal(dataAlatBantuOnSite[0]?.qty, dataAlatBantuOnSite[0]?.unit_price, dataAlatBantuOnSite[0]?.konstanta))).withoutLabel}</Td>
            </tr>
          }
        </tbody>
      </Table>
    </div>
  )
}

export default TableListAnalisaAlatBantuOnSite
