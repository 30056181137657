import { useState, useEffect, useContext, useMemo } from "react";
import { Modal } from "react-bootstrap";
import Axios from "axios";
import { ActionButton, DataStatus } from "components";
import { AnalisaBarangJadiRABApi } from "api";
import TablePrelim from "./TablePrelim";
import { RupiahConvert } from "utilities";

const ModalPrelim = ({ show, onHide, onSubmit, dataPrelim: dataPrelimParent }) => {
  const [dataKelompokPrelim, setDataKelompokPrelim] = useState([]);
  const [dataPrelim, setDataPrelim] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });

  const filterDataPrelim = (data) =>
    data.map((val, index) => {
      return {
        id_kelompok: val.id_kelompok,
        id_item: val.id_item_buaso,
        kode_item: val.kode_item,
        nama_item: val.nama_item,
        qty_item: val.qty_analisa,
        id_unit: val.id_satuan,
        nama_unit: val.nama_satuan,
        harga: val.unit_price,
        qty_durasi: val.qty_durasi,
        konstanta: val.konstanta,
        order: index + 1,
      };
    });

  const getInitialData = async () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    return await Axios.all([AnalisaBarangJadiRABApi.getKelompokPrelim()])
      .then(
        Axios.spread((kelompokPrelim) => {
          const rawKelompokPrelim = kelompokPrelim?.data?.data;
          setDataPrelim(dataPrelimParent ?? []);
          setDataKelompokPrelim(rawKelompokPrelim ?? []);
          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const getGrandTotal = useMemo(
    () =>
      dataPrelim.reduce((prev, current) => {
        const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
        const newHarga = current.harga ? parseFloat(current.harga) : 0;
        const newQtyDurasi = current.qty_durasi ? parseFloat(current.qty_durasi) : 0;
        const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
        const subTotal = newQtyItem * newHarga;
        // const total = subTotal * newQtyDurasi * newKonts;
        const total = subTotal * newKonts;

        return prev + total;
      }, 0),
    [dataPrelim]
  );

  useEffect(() => {
    getInitialData();
  }, [show]);

  return (
    <Modal size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <b className="text-primary">Analisa Prelim</b>
      </Modal.Header>
      <Modal.Body>
        {fetchingStatus.loading ? (
          <DataStatus loading text="Memuat data . . ." />
        ) : fetchingStatus.success ? (
          <>
            {dataKelompokPrelim.map((val, index) => (
              <TablePrelim
                key={index}
                dataKelompokPrelim={val}
                dataPrelim={dataPrelim}
                setDataPrelim={setDataPrelim}
              />
            ))}

            {/* Grand Total Section */}
            <div className="text-right p-2 border" style={{ fontSize: 14 }}>
              <b>Grand Total : </b>
              <b className="ml-3">{RupiahConvert(String(getGrandTotal)).detail}</b>
            </div>
            <hr />
          </>
        ) : (
          <DataStatus text="Data gagal dimuat!" />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalPrelim;
