import Services from '../../../services'

class HargaSatuanJualApi {
    getPage(params) {
        return Services.get('/harga_satuan_jual/terbaru/page', { params })
    }
    getItemHistory(page = 1, dataLength = 10, id_item_buaso) {
        return Services.get(`harga_satuan_jual/page?id_item_buaso=${id_item_buaso}&page=${page}&per_page=${dataLength}`)
    }
    getBahan() {
        return Services.get('/barang')
    }
    create(data) {
        return Services.post("/harga_satuan_jual", data)
    }
}

export default new HargaSatuanJualApi()