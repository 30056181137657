import { useHistory } from 'react-router-dom'
import {
  Table,
  Th,
  Td,
  ThFixed,
  DataStatus,
  TdFixed,
  ReadButton
} from 'components'
import { RupiahConvert, DateConvert } from 'utilities'

const TableHistory = ({ dataHistory = [] }) => {
  const history = useHistory()

  return (
    <>
      <div className='p-1'>
        <b>History Faktur Penjualan</b>
      </div>

      <Table>
        <thead className='bg-light'>
          <tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Tgl.Faktur Penjualan</ThFixed>
            <ThFixed>No. Faktur Penjualan</ThFixed>
            <ThFixed>No. Surat Jalan</ThFixed>
            <Th width={300}>Item Barang</Th>
            <Th width={100}>Total Akhir</Th>
            <Th width={150}>Prelim Disertakan Dalam Faktur (%)</Th>
            <Th>Progress Instalasi</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {dataHistory?.length > 0 ? (
            <>
              {dataHistory?.map((val, index) =>
                val?.detail?.map((vald, indexd) => {
                  const isFirstIndex = Boolean(indexd === 0)
                  const totalAkhir = val?.detail?.reduce(
                    (acc, { jumlah }) => acc + parseInt(jumlah || 0),
                    0
                  )
                  return (
                    <tr key={`${index}${indexd}`}>
                      {isFirstIndex && (
                        <TdFixed rowSpan={val?.detail.length}>
                          {index + 1}
                        </TdFixed>
                      )}
                      {isFirstIndex && (
                        <Td rowSpan={val?.detail.length}>
                          {val?.tgl_faktur
                            ? DateConvert(new Date(val.tgl_faktur)).defaultDMY
                            : '-'}
                        </Td>
                      )}
                      {isFirstIndex && (
                        <Td rowSpan={val?.detail.length}>{val.no_faktur}</Td>
                      )}
                      <Td>{vald.no_surat_jalan}</Td>
                      <Td>{vald.nama_item}</Td>
                      {isFirstIndex && (
                        <Td rowSpan={val?.detail.length} className='text-right'>
                          {RupiahConvert(String(parseInt(totalAkhir))).detail}
                        </Td>
                      )}
                      {isFirstIndex && (
                        <Td rowSpan={val?.detail.length} className='text-right'>
                          {parseFloat(val.persentase_prelim ?? 0)}%
                        </Td>
                      )}
                      <Td textRight>
                        {parseInt(vald.akumulasi_progress ?? 0)} %
                      </Td>
                      {isFirstIndex && (
                        <TdFixed rowSpan={val?.detail.length}>
                          <ReadButton
                            onClick={() =>
                              history.push(
                                '/sls/transaksi/faktur-penjualan/detail/' +
                                  val.id_faktur_penjualan,
                                {
                                  status_approval: val.status_approval ?? 'PEN'
                                }
                              )
                            }
                          />
                        </TdFixed>
                      )}
                    </tr>
                  )
                })
              )}
            </>
          ) : (
            <tr>
              <td colSpan={9}>
                <DataStatus text='Tidak ada data' />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}

export default TableHistory
