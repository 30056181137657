import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Modal } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  CreateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from "components";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { SalesOrderSPKApi } from "api";

const SalesOrderSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    SalesOrderSPKApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setData(data?.data ?? []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data?.data_count,
          totalPage: data?.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const dismissAlert = () => {
    let state = { ...history.location.state };
    delete state.alert;
    history.replace({ ...history.location, state });

    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("Sales Order SPK");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const PageContent = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : "";
      const convert = data?.substring(0, 3);

      if (convert === "APP") return "APPROVED";
      if (convert === "VER") return "VERIFIED";
      if (convert === "REV") return "REVISI";
      if (convert === "REJ") return "REJECT";

      return "PENDING";
    };

    const BarangJadiCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      const getKodeSatuan = (brg) => {
        const qty = parseFloat(brg.qty_sales_order_spk ?? 0);

        if (brg?.nama_satuan?.toLowerCase() === "kilometer") return `x ${qty} km`;
        return brg?.kode_satuan ? `x ${qty} ${brg?.kode_satuan}` : "";
      };

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data?.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>{`${brg.nama_barang} ${getKodeSatuan(brg)}`}</li>
                );
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>{`${brg.nama_barang} ${getKodeSatuan(brg)}`}</li>
                  )
                );
              }
            })}
          </ul>
          {data?.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };

    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Tgl. Sales Order SPK</ThFixed>
            <ThFixed>No. Sales Order SPK</ThFixed>
            <ThFixed>No. SPK</ThFixed>
            <Th>Customer</Th>
            <Th>Proyek</Th>
            <Th>Item Barang Jadi</Th>
            <ThFixed>Status Approval</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data?.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}
              </TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-start">
                  <ReadButton
                    size="sm"
                    className="mr-1"
                    onClick={() =>
                      history.push(`/transaksi/sales-order-spk/detail/${val.id_sales_order_spk}`)
                    }
                  />
                  {val.status_approval === "REV" && (
                    <UpdateButton
                      size="sm"
                      onClick={() =>
                        history.push(`/transaksi/sales-order-spk/ubah/${val.id_sales_order_spk}`)
                      }
                    />
                  )}
                </div>
              </TdFixed>
              <Td className="text-center">
                {val.tgl_sales_order_spk
                  ? DateConvert(new Date(val.tgl_sales_order_spk)).defaultDMY
                  : ""}
              </Td>
              <Td className="text-center">{val.no_sales_order_spk ?? ""}</Td>
              <Td className="text-center">{val.no_spk ?? ""}</Td>
              <Td>{val.nama_customer ?? "-"}</Td>
              <Td>{val.nama_proyek ?? "-"}</Td>
              <Td>
                {val.nama_barang && val.nama_barang?.length > 0 ? (
                  <BarangJadiCollapse data={val.nama_barang} />
                ) : (
                  "-"
                )}
              </Td>
              <Td>{checkStatus(val.status_approval)}</Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    if (!data || data?.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          {/* <ActionButton
            text="Tambah Data"
            onClick={() => history.push("/transaksi/sales-order-spk/list-spk")} /> */}

          <CreateButton onClick={() => history.push("/transaksi/sales-order-spk/list-spk")} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {/* content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
    </CRUDLayout>
  );
};

export default SalesOrderSPK;
