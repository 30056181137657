import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import Axios from 'axios'
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  CreateButton,
  ApprovalStatusButton
} from 'components'
import { DateConvert, TableNumber } from 'utilities'
import { FakturPenjualanApi } from 'api'
import ModalFilterFakturPenjualan from './ModalFilterFakturPenjualan'

const ListFakturPenjualan = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataFakturPenjualan, setDataFakturPenjualan] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0'
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: ''
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })
  const [modalConfig, setModalConfig] = useState(false)
  const initialFilter = {
    isFilter: false,
    tgl_mulai_faktur_penjualan: undefined,
    tgl_selesai_faktur_penjualan: undefined,
    tgl_mulai_sales_order: undefined,
    tgl_selesai_sales_order: undefined,
    tgl_mulai_batas_waktu: undefined,
    tgl_selesai_batas_waktu: undefined,
    id_customer: undefined,
    id_sales: undefined,
    id_proyek: undefined,
    status_approval: undefined
  }
  const [filterConfig, setFilterConfig] = useState(initialFilter)
  const [dropdown, setDropdown] = useState({
    customer: [{ value: undefined, label: 'Semua' }],
    sales: [{ value: undefined, label: 'Semua' }],
    proyek: [{ value: undefined, label: 'Semua' }],
    approval: [
      { value: undefined, label: 'Semua' },
      { value: 'PEN', label: 'PENDING' },
      { value: 'REV', label: 'REVISION' },
      { value: 'VER', label: 'VERIFIED' },
      { value: 'APP', label: 'APPROVED' },
      { value: 'REJ', label: 'REJECTED' }
    ]
  })

  // Reset filter handler
  const handleResetFilter = () => {
    setModalConfig(false)
    setFilterConfig(initialFilter)
  }

  const getInitialData = () => {
    setIsPageLoading(true)

    let query = {
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      key: searchConfig.key
    }

    if (filterConfig.isFilter) {
      const filterQuery = { ...filterConfig }
      delete filterQuery.isFilter

      query = Object.assign(query, filterQuery)
    }

    Axios.all([
      FakturPenjualanApi.get(query),
      FakturPenjualanApi.getDropdownFilter({ tipe: 'customer' }),
      FakturPenjualanApi.getDropdownFilter({ tipe: 'proyek' }),
      FakturPenjualanApi.getDropdownFilter({ tipe: 'sales' })
    ])
      .then(
        Axios.spread((fakturPenjualan, resCustomer, resProyek, resSales) => {
          const { data } = fakturPenjualan
          const { data: dataCustomer } = resCustomer?.data
          const { data: dataProyek } = resProyek?.data
          const { data: dataSales } = resSales?.data

          const customer = dropdown?.customer?.concat(
            dataCustomer?.map((item) => ({
              value: item.id_customer,
              label: item.nama_customer
            })) ?? []
          )
          const proyek = dropdown?.proyek?.concat(
            dataProyek?.map((item) => ({
              value: item.id_proyek,
              label: item.nama_proyek
            })) ?? []
          )
          const sales = dropdown?.sales?.concat(
            dataSales?.map((item) => ({
              value: item.id_karyawan,
              label: item.nama_karyawan
            })) ?? []
          )

          setDataFakturPenjualan(data.data)
          setDropdown((prev) => ({ ...prev, customer, proyek, sales }))
          setPaginationConfig({
            ...paginationConfig,
            dataCount: data.data_count,
            totalPage: data.total_page
          })
        })
      )
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!'
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant
        })
      }
    }
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value
    })
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0'
    })
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key
    })
  }

  useEffect(() => {
    setNavbarTitle('Faktur Penjualan')
    getInitialData()
    checkAlert()

    return () => {
      // setDropdown({});
      setIsPageLoading(false)
    }
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    filterConfig
  ])

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Faktur Penjualan</ThFixed>
            <ThFixed>Informasi Sales Order</ThFixed>
            <Th>Customer</Th>
            <Th>Sales</Th>
            <Th>Proyek</Th>
            <ThFixed>Tgl. Selesai</ThFixed>
            <ThFixed>Status Approval</ThFixed>
          </tr>
        </thead>
        <tbody>
          {dataFakturPenjualan.map((val, index) => (
            <tr key={index}>
              <Td>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </Td>
              <Td>
                <div className='d-flex'>
                  <ReadButton
                    onClick={() =>
                      history.push(
                        '/sls/transaksi/faktur-penjualan/detail/' +
                          val.id_faktur_penjualan,
                        { status_approval: val.status_approval }
                      )
                    }
                  />
                  {(val.status_approval === 'PEN' ||
                    val.status_approval === 'REV' ||
                    !val.status_approval) && (
                    <UpdateButton
                      onClick={() =>
                        history.push(
                          `/sls/transaksi/faktur-penjualan/ubah/${val.id_faktur_penjualan}`,
                          {
                            id_sales_order: val.id_sales_order,
                            status_approval: val.status_approval
                          }
                        )
                      }
                    />
                  )}
                </div>
              </Td>
              <Td>
                <div>
                  {val.tgl_faktur
                    ? DateConvert(new Date(val.tgl_faktur)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_faktur ?? '-'}</div>
              </Td>
              <Td>
                <div>
                  {val.tgl_sales_order
                    ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_sales_order ?? '-'}</div>
              </Td>
              <Td width={200}>{val.nama_customer ?? '-'}</Td>
              <Td width={200}>{val.nama_sales ?? '-'}</Td>
              <Td width={200}>{val.nama_proyek ?? '-'}</Td>
              <Td>
                {val.batas_waktu
                  ? DateConvert(new Date(val.batas_waktu)).defaultDMY
                  : '-'}
              </Td>
              <Td>
                <ApprovalStatusButton status={val.status_approval} />
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    )

    if (!dataFakturPenjualan || dataFakturPenjualan.length < 1) {
      return <DataStatus text='Tidak ada data' />
    }

    return (
      <>
        <div className='mb-1'>
          <b>List Data Faktur Penjualan</b>
        </div>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className='d-flex justify-content-start align-items-center mb-3'>
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>

            <ActionButton
              className='mx-2 mb-2 p1 '
              size='md'
              text={`Filter : ${filterConfig.isFilter ? 'ON' : 'OFF'}`}
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push('/sls/transaksi/faktur-penjualan/sales-order')
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text='Memuat data . . .' />
      ) : (
        <PageContent />
      )}

      {/* Modal Filter */}
      {modalConfig && (
        <ModalFilterFakturPenjualan
          dropdown={dropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          filterConfig={filterConfig}
          setFilterConfig={setFilterConfig}
          handleResetFilter={handleResetFilter}
        />
      )}
    </CRUDLayout>
  )
}

export default ListFakturPenjualan
