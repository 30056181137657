import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import {
  Th,
  Tr,
  Td,
  TBody,
  THead,
  ThFixed,
  TdFixed,
  DataStatus,
  DatePicker,
  Pagination,
  CRUDLayout,
  SelectSearch,
  ActionButton,
} from "components";
import Axios from "axios";
import { saveAs } from "file-saver";
import { LaporanPenjualanApi } from "api";
import { DateConvert, TableNumber, RupiahConvert } from "utilities";

const BarangJadiCollapse = ({ data }) => {
  const [isReadMoreClick, setisReadMoreClick] = useState(false);

  return (
    <>
      <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
        {data?.map((brg, index) => {
          if (isReadMoreClick) {
            return (
              <li key={index} index={index}>{`${brg.nama_barang} (${parseFloat(
                brg.qty_transaksi ?? 0
              )} ${brg.nama_satuan ?? ""})`}</li>
            );
          } else {
            return (
              index < 2 && (
                <li key={index} index={index}>{`${brg.nama_barang} (${parseFloat(
                  brg.qty_transaksi ?? 0
                )} ${brg.nama_satuan ?? ""})`}</li>
              )
            );
          }
        })}
      </ul>
      {data?.length > 2 && (
        <div
          className="text-primary"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => setisReadMoreClick((prev) => !prev)}
        >
          {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
        </div>
      )}
    </>
  );
};

const LaporanModulPenjualan = ({ setNavbarTitle }) => {
  //STATE DATA
  const [dataLaporanPenjualan, setDataLaporanPenjualan] = useState([]);

  const [tanggalMulai, setTanggalMulai] = useState(undefined);
  const [tanggalSelesai, setTanggalSelesai] = useState(undefined);
  const [customer, setCustomer] = useState({
    value: "",
    label: "",
  });
  const [barangJadi, setBarangJadi] = useState({
    value: "",
    label: "",
  });
  const [proyek, setProyek] = useState({
    value: "",
    label: "",
  });

  const [dropdownProyek, setDropdownProyek] = useState([]);
  const [dropdownBarangJadi, setDropdownBarangJadi] = useState([]);
  const [dropdownCustomer, setDropdownCustomer] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState(true);

  const [showTable, setShowTable] = useState(false);
  const checkInput = Boolean(tanggalMulai || tanggalSelesai || customer || barangJadi || proyek);

  //MAPING DATA DROPDOWN
  const mapProyek = (data) =>
    data.map((val) => {
      return {
        value: val.id_proyek,
        label: val.nama_proyek,
      };
    });
  const mapBarangJadi = (data) =>
    data.map((val) => {
      return {
        value: val.id_item_buaso,
        label: val.nama_item,
      };
    });
  const mapCustomer = (data) =>
    data.map((val) => {
      return {
        value: val.id_customer,
        label: val.nama_customer,
      };
    });

  //CONFIG
  const [fetchConfig, setFetchConfig] = useState({
    loading: true,
    status: false,
  });

  //REQUEST DATA SERVER
  const getDropdown = () => {
    setDropdownLoading(true);
    Axios.all([
      LaporanPenjualanApi.getDropdown({ tipe: "proyek" }),
      LaporanPenjualanApi.getDropdown({ tipe: "barang_jadi" }),
      LaporanPenjualanApi.getDropdown({ tipe: "customer" }),
    ])
      .then(
        Axios.spread((pro, bar, cus) => {
          const dataProyek = mapProyek(pro?.data?.data ?? []);
          const dataBarangJadi = mapBarangJadi(bar?.data?.data ?? []);
          const dataCustomer = mapCustomer(cus?.data?.data ?? []);

          setDropdownProyek(dataProyek);
          setDropdownBarangJadi(dataBarangJadi);
          setDropdownCustomer(dataCustomer);

          setDropdownLoading(false);
        })
      )
      .catch(() => <DataStatus text="Data gagal dimuat..." />)
      .finally(() => setDropdownLoading(false));
  };

  // DATA TABLE
  const getInitialData = () => {
    setFetchConfig({ ...fetchConfig, loading: true });
    LaporanPenjualanApi.getPage({
      tanggal_start: tanggalMulai,
      tanggal_end: tanggalSelesai,
      id_customer: customer.value,
      id_barang: barangJadi.value,
      id_proyek: proyek.value,
    })
      .then((res) => {
        setDataLaporanPenjualan(res?.data?.data ?? []);
        setFetchConfig({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setFetchConfig({
          loading: false,
          success: false,
        });
      });
  };

  // HANDLE CLICK DATA
  const getData = () => {
    setShowTable(true);
    getInitialData();
  };

  // HANDLE EXPORT DATA
  const getExportData = (file) => {
    LaporanPenjualanApi.getExport({
      tipe: file,
      tanggal_start: tanggalMulai,
      tanggal_end: tanggalSelesai,
      id_customer: customer.value,
      id_barang: barangJadi.value,
      id_proyek: proyek.value,
    })
      .then((res) => {
        const link = res?.data?.data;
        const parse =
          "https://" +
          link
            .split("/")
            .filter((_val, idx) => idx !== 0)
            .filter((_val, idx) => idx !== 0)
            .join("/");
        saveAs(parse, "Laporan Penjualan");
      })
      .catch(() => {
        window.alert("Export data gagal");
      });
  };

  // CALCULATE ROW ITEM BARANG JADI
  const calcItemBarangJadi = (barang_jadi = [], prelim = [], diskon, pajak) => {
    const subtotal = barang_jadi?.reduce(
      (subtotal, { harga_jual, qty_transaksi }) =>
        subtotal + Math.round(parseInt(harga_jual || 0) * parseFloat(qty_transaksi || 0)),
      0
    );
    const total_prelim = prelim?.reduce(
      (subtotal, { unit_price, qty_analisa, qty_durasi, konstanta }) =>
        subtotal +
        Math.round(
          parseInt(unit_price || 0) *
            parseFloat(qty_analisa || 0) *
            parseFloat(qty_durasi || 0) *
            parseFloat(konstanta || 0)
        ),
      0
    );
    const total = parseFloat(subtotal || 0) + parseFloat(total_prelim || 0);
    const total_setelah_diskon = parseInt(
      parseFloat(total) - parseFloat(total * parseFloat(diskon / 100) || 0)
    );
    const total_setelah_pajak = parseInt(
      parseFloat(total_setelah_diskon || 0) +
        parseFloat(total_setelah_diskon * parseFloat(pajak / 100) || 0)
    );

    return {
      subtotal,
      total_prelim,
      total,
      total_setelah_diskon,
      total_setelah_pajak,
    };
  };

  //USE EFFECT
  useEffect(() => {
    getDropdown();
    setNavbarTitle("Laporan Penjualan");
  }, []);

  useEffect(() => {
    checkInput && getInitialData();
  }, [setNavbarTitle]);

  //TABLE
  const Table = () => {
    return (
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>No.SO</ThFixed>
            <ThFixed>Tgl.SO</ThFixed>
            <Th>Customer</Th>
            <Th>Proyek</Th>
            <Th>Sales</Th>
            <Th>Description (Barang Jadi)</Th>
            <Th>Sub Total</Th>
            <Th>Prelim</Th>
            <Th>Total</Th>
            <Th>Diskon</Th>
            <Th>Total Setelah Diskon</Th>
            <Th>Pajak</Th>
            <Th>Total Setelah Pajak</Th>
            <Th>Grand Total</Th>
          </Tr>
        </THead>
        <TBody>
          {dataLaporanPenjualan.length === 0 ? (
            <Tr>
              <Td colSpan={15}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          ) : (
            dataLaporanPenjualan.map((val, index) => {
              const customStyle = { minWidth: 150 };
              const calcItemBJ = calcItemBarangJadi(
                val.barang_jadi ?? [],
                val.detail_prelim ?? [],
                val.diskon,
                val.ppn
              );

              return (
                <Tr key={index}>
                  <TdFixed textCenter>{index + 1}</TdFixed>
                  <Td>{val.no_sales_order ?? "-"}</Td>
                  <Td>
                    {val.tgl_sales_order
                      ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY
                      : "-"}
                  </Td>
                  <Td style={{ minWidth: 200 }}>{val.nama_customer ?? "-"}</Td>
                  <Td style={{ minWidth: 200 }}>{val.nama_proyek ?? "-"}</Td>
                  <Td style={{ minWidth: 200 }}>{val.nama_sales ?? "-"}</Td>
                  <Td style={{ minWidth: 200 }}>
                    <BarangJadiCollapse data={val.barang_jadi} />
                  </Td>
                  {/* Subtotal */}
                  <Td textRight style={customStyle}>
                    {RupiahConvert((calcItemBJ.subtotal ?? 0).toString()).detail ?? "-"}
                  </Td>
                  {/* Prelim */}
                  <Td textRight style={customStyle}>
                    {RupiahConvert((calcItemBJ.total_prelim ?? 0).toString()).detail ?? "-"}
                  </Td>
                  {/* Total */}
                  <Td textRight style={customStyle}>
                    {RupiahConvert((calcItemBJ.total ?? 0).toString()).detail ?? "-"}
                  </Td>
                  {/* Diskon */}
                  <Td>{`${parseFloat(val.diskon ?? 0)}%`}</Td>
                  {/* Total Setelah Diskon */}
                  <Td textRight style={customStyle}>
                    {RupiahConvert((calcItemBJ.total_setelah_diskon ?? 0).toString()).detail ?? "-"}
                  </Td>
                  {/* Pajak */}
                  <Td>{`${parseFloat(val.ppn ?? 0)}%`}</Td>
                  {/* Total Setelah Pajak */}
                  <Td textRight style={customStyle}>
                    {RupiahConvert((calcItemBJ.total_setelah_pajak ?? 0).toString()).detail ?? "-"}
                  </Td>
                  {/* Grand Total */}
                  <Td textRight style={customStyle}>
                    {RupiahConvert((calcItemBJ.total_setelah_pajak ?? 0).toString()).detail ?? "-"}
                  </Td>
                </Tr>
              );
            })
          )}
        </TBody>
      </CRUDLayout.Table>
    );
  };

  //STYLE
  const stylePeriode = {
    marginRight: "-10px",
    marginLeft: "20px",
    marginTop: "28px",
    fontSize: "15px",
  };
  const styleFilter = {
    marginLeft: "15px",
    marginRight: "5px",
    marginTop: "28px",
    fontSize: "15px",
  };
  const styleSubmitBtn = { paddingTop: "21px", marginLeft: "auto", marginRight: "22px" };
  const styleSD = { marginLeft: "6px", marginRight: "6px", marginTop: "28px", fontSize: "15px" };

  return (
    <div>
      <Card className="p-2 mb-3">
        <Row>
          <span style={stylePeriode}>Periode:</span>
          <Col md={3} className="d-flex">
            <DatePicker
              type="date"
              label="Tgl. Mulai"
              calendarIcon="Calendar"
              name="tanggalMulai"
              placeholderText="Masukkan tanggal..."
              autoComplete="off"
              defaultValue={tanggalMulai}
              onChange={(date) => {
                const value = DateConvert(new Date(date)).default;
                setTanggalMulai(value);
              }}
              selected={tanggalMulai ? new Date(tanggalMulai) : ""}
            />
            <span style={styleSD}>s/d</span>
            <DatePicker
              type="date"
              label="Tgl. Selesai"
              name="tanggalSelesai"
              placeholderText="Masukkan tanggal..."
              autoComplete="off"
              defaultValue={tanggalSelesai}
              onChange={(date) => {
                const value = DateConvert(new Date(date)).default;
                setTanggalSelesai(value);
              }}
              selected={tanggalSelesai ? new Date(tanggalSelesai) : ""}
            />
          </Col>
          <span style={styleFilter}>Filter By:</span>
          <div style={{ width: "150px" }}>
            <SelectSearch
              label="Customer"
              type="select"
              placeholder="Pilih salah satu..."
              name="id_customer"
              option={dropdownCustomer}
              onChange={(e) => setCustomer(e)}
              loading={dropdownLoading}
            />
          </div>
          <div className="ml-2" style={{ width: "150px" }}>
            <SelectSearch
              label="Barang Jadi"
              type="select"
              placeholder="Pilih salah satu..."
              name="id_item_atribut"
              option={dropdownBarangJadi}
              onChange={(e) => setBarangJadi(e)}
              loading={dropdownLoading}
            />
          </div>
          <div className="ml-2" style={{ width: "150px" }}>
            <SelectSearch
              label="Proyek"
              type="select"
              placeholder="Pilih salah satu..."
              name="id_proyek"
              option={dropdownProyek}
              onChange={(e) => setProyek(e)}
              loading={dropdownLoading}
            />
          </div>
          <div style={styleSubmitBtn}>
            <ActionButton type="submit" text="Lihat Data" onClick={getData} />
          </div>
        </Row>
      </Card>

      {showTable === true ? (
        fetchConfig.loading === true ? (
          <DataStatus loading={true} text="Memuat Data..." />
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-end mb-3">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Penjualan</b>
              </span>
              <div>
                <ActionButton
                  className="text-right"
                  variant="outline-danger"
                  text="Export PDF"
                  onClick={() => getExportData("pdf")}
                />
                <ActionButton
                  className="text-right ml-2"
                  variant="outline-success"
                  text="Export Excel"
                  onClick={() => getExportData("excel")}
                />
              </div>
            </div>
            <Table />
          </>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default LaporanModulPenjualan;
