import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { DatePicker, SelectSearch } from 'components'
import { DateConvert } from 'utilities'

const ModalFilterSOFP = ({ dropdown, modalConfig, setModalConfig, filterConfig, setFilterConfig, handleResetFilter }) => {
  const [dataFilter, setDataFilter] = useState({ ...filterConfig })
  const {
    tgl_mulai_sales_order,
    tgl_selesai_sales_order,
    id_customer,
    id_sales,
    id_proyek,
    tgl_mulai_batas_waktu,
    tgl_selesai_batas_waktu,
  } = dataFilter

  const onChangeTglSO = (dates) => {
    const [start, end] = dates

    const tgl_mulai_sales_order = start ? DateConvert(start).default : null
    const tgl_selesai_sales_order = end ? DateConvert(end).default : null

    setDataFilter(prev => ({ ...prev, tgl_mulai_sales_order, tgl_selesai_sales_order }))
  }

  const onChangeTglSelesai = (dates) => {
    const [start, end] = dates

    const tgl_mulai_batas_waktu = start ? DateConvert(start).default : null
    const tgl_selesai_batas_waktu = end ? DateConvert(end).default : null

    setDataFilter(prev => ({ ...prev, tgl_mulai_batas_waktu, tgl_selesai_batas_waktu }))
  }

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig(false)}
    >
      <Modal.Header closeButton>
        <b>Filter Data</b>
      </Modal.Header>
      <Modal.Body>
        <DatePicker
          selectsRange
          label="Tgl. Sales Order"
          placeholderText="Pilih tanggal Sales Order"
          startDate={tgl_mulai_sales_order ? new Date(tgl_mulai_sales_order) : ''}
          endDate={tgl_selesai_sales_order ? new Date(tgl_selesai_sales_order) : ''}
          onChange={(dates) => onChangeTglSO(dates)}
          monthsShown={2}
        />
        <SelectSearch
          label="Customer"
          placeholder="Pilih customer"
          defaultValue={dropdown?.customer.find(item => item.value === id_customer)}
          option={dropdown?.customer}
          onChange={(e) => setDataFilter(prev => ({ ...prev, id_customer: e.value }))}
        />
        <SelectSearch
          label="Sales"
          placeholder="Pilih sales"
          defaultValue={dropdown?.sales?.find(item => item.value === id_sales)}
          option={dropdown?.sales}
          onChange={(e) => setDataFilter(prev => ({ ...prev, id_sales: e.value }))}
        />
        <SelectSearch
          label="Proyek"
          placeholder="Pilih proyek"
          defaultValue={dropdown?.proyek?.find(item => item.value === id_proyek)}
          option={dropdown?.proyek}
          onChange={(e) => setDataFilter(prev => ({ ...prev, id_proyek: e.value }))}
        />
        <DatePicker
          selectsRange
          label="Tgl. Selesai"
          placeholderText="Pilih tanggal Selesai"
          startDate={tgl_mulai_batas_waktu ? new Date(tgl_mulai_batas_waktu) : ''}
          endDate={tgl_selesai_batas_waktu ? new Date(tgl_selesai_batas_waktu) : ''}
          onChange={(dates) => onChangeTglSelesai(dates)}
          monthsShown={2}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={handleResetFilter}
        >
          RESET
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setFilterConfig({ ...dataFilter, isFilter: true, })
            setModalConfig(false)
          }}
        >
          FILTER
        </Button>
      </Modal.Footer>
    </Modal >
  )
}

export default ModalFilterSOFP