import Services from '../../../services'

class TransaksiSalesOrderApi {
    getPage(params) {
        return Services.get('/sales_order/page', { params })
    }
    getPage2(page, dataLength) {
        return Services.get(`/faktur_penjualan/sales_order?page=${page}&per_page=${dataLength}`)
    }
    getSingle(id_sales_order) {
        return Services.get(`sales_order/single?id_sales_order=${id_sales_order}`)
    }
    getNomorSalesOrder(tanggal) {
        return Services.get(`/sales_order/no_baru?tanggal=${tanggal}`)
    }
    getStatusSalesOrder(no_sales_order) {
        return Services.get(`status_sales_order/single?no_sales_order=${no_sales_order}`)
    }
    // UPDATE QTY ITEM BARANG PADA SALES ORDER
    updateOrderItem(data) {
        return Services.put('/sales_order/detail', data)
    }
    // TAMBAH BARANG BARU PADA SALES ORDER
    storeOrderItem(data) {
        return Services.post('/sales_order/details', data)
    }
    // UPDATE DATA MASTER SALES ORDER
    updateSalesOrder(data) {
        return Services.put('/sales_order', data)
    }
    // DELETE ITEM BARANG PADA SALES ORDER
    deleteOrderItem(id_sales_order_detail) {
        return Services.put('/sales_order/detail', { id_sales_order_detail, qty_item: 0 })
    }
    // DELETE SALES ORDER
    deleteSalesOrder(id_sales_order) {
        return Services.post('/sales_order/delete', { id_sales_order })
    }
    createSalesOrder(data) {
        return Services.post("/sales_order", data)
    }
    search(key) {
        return Services.get(`sales_order/page/?q=${key}`)
    }
}

export default new TransaksiSalesOrderApi()